import { create } from 'zustand';
import { detectUserCountry, getRegionFromPath } from '@/services/countryService';
import useBasketStore from './BasketStore';

interface RegionState {
    chosenRegion: string | null;
    detectedRegion: string | null;
    urlRegion: string | null;
    showRegionModal: boolean;
    acceptedRegion: string | null;
    setChosenRegion: (region: string) => void;
    setDetectedRegion: (region: string) => void;
    setShowRegionModal: (show: boolean) => void;
    getUrlRegion: (path: string) => void;
    detectRegion: () => Promise<void>;
    setAcceptedRegion: (region: string | null) => void;
}

const useRegionStore = create<RegionState>((set) => ({
    chosenRegion: null,
    detectedRegion: null,
    urlRegion: null,
    showRegionModal: false,
    acceptedRegion: null,
    setChosenRegion: (region) => {
        set((state) => {
            // Only clear basket if the region is actually changing
            if (state.chosenRegion !== null && state.chosenRegion !== region) {
                const basketStore = useBasketStore.getState();
                basketStore.basketItems.forEach(item => basketStore.removeItemFromBasket(item.id));
            }
            return { chosenRegion: region };
        });
    },
    setDetectedRegion: (region) => set({ detectedRegion: region }),
    setShowRegionModal: (show) => set({ showRegionModal: show }),
    getUrlRegion: (path) => set({ urlRegion: getRegionFromPath(path) }),
    setAcceptedRegion: (region) => set({ acceptedRegion: region }),
    detectRegion: async () => {
        try {
            const region = await detectUserCountry();
            set((state) => ({
                detectedRegion: region,
                chosenRegion: state.chosenRegion || region
            }));
        } catch (error) {
            console.error('Failed to detect region:', error);
            set((state) => ({
                detectedRegion: 'gb',
                chosenRegion: state.chosenRegion || 'gb'
            }));
        }
    },
}));

export default useRegionStore; 