import { CookieConsent } from '@/context/CookieConsentStore'

declare global {
    interface Window {
        gtag?: (...args: any[]) => void
        fbq?: (...args: any[]) => void
        dataLayer?: any[]
        clarity?: (...args: any[]) => void
    }
}

export const initializeAnalytics = (consent: CookieConsent) => {
    // Microsoft Clarity
    if (consent.analytics && !window.clarity) {
        console.log('[Microsoft Clarity] Initializing')
        const CLARITY_PROJECT_ID = process.env.REACT_APP_CLARITY_PROJECT_ID

        const script = document.createElement('script')
        script.id = 'clarity-script'
        script.innerHTML = `
            (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "${CLARITY_PROJECT_ID}");
        `
        document.head.appendChild(script)
        console.log('[Microsoft Clarity] Initialized')
    } else if (consent.analytics) {
        console.log('[Microsoft Clarity] Already initialized')
    } else {
        console.log('[Microsoft Clarity] Not initializing - analytics consent not granted')
    }

    // Google Analytics
    if (consent.analytics && !window.gtag) {
        console.log('[Google Analytics] Initializing GA4')
        const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID

        // Initialize Google Analytics (GA4)
        const script = document.createElement('script')
        script.id = 'ga4-script'
        script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`
        script.async = true
        document.head.appendChild(script)

        window.gtag = function () {
            // @ts-ignore
            window.dataLayer = window.dataLayer || []
            // @ts-ignore
            window.dataLayer.push(arguments)
        }

        window.gtag('js', new Date())
        window.gtag('config', GA_MEASUREMENT_ID, {
            anonymize_ip: true,
            cookie_flags: 'SameSite=None;Secure'
        })
        console.log('[Google Analytics] GA4 initialized')
    } else if (consent.analytics) {
        console.log('[Google Analytics] Already initialized')
    } else {
        console.log('[Google Analytics] Not initializing - analytics consent not granted')
    }

    // Meta Pixel
    if (consent.marketing && !window.fbq) {
        // Initialize Meta Pixel only if not already initialized
        const META_PIXEL_ID = process.env.REACT_APP_META_PIXEL_ID
        console.log('[Meta Pixel] Initializing with ID:', META_PIXEL_ID)
        const script = document.createElement('script')
        script.id = 'fb-pixel-init'
        script.innerHTML = `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '${META_PIXEL_ID}');
            fbq('track', 'PageView');
            console.log('[Meta Pixel] Initialization complete');
        `
        document.head.appendChild(script)

        // Add Meta Pixel noscript element if it doesn't exist
        if (!document.querySelector('noscript img[src*="facebook.com/tr"]')) {
            const noscript = document.createElement('noscript')
            noscript.innerHTML = `
                <img height="1" width="1" style="display:none"
                src="https://www.facebook.com/tr?id=${META_PIXEL_ID}&ev=PageView&noscript=1"/>
            `
            document.body.appendChild(noscript)
            console.log('[Meta Pixel] Noscript element added')
        }
    } else if (consent.marketing) {
        console.log('[Meta Pixel] Already initialized')
    } else {
        console.log('[Meta Pixel] Not initializing - marketing consent not granted')
    }
}

export const updateAnalyticsConsent = (consent: CookieConsent) => {
    // Update Microsoft Clarity consent
    if (window.clarity) {
        if (!consent.analytics) {
            console.log('[Microsoft Clarity] Revoking consent and removing Clarity')
            removeMicrosoftClarity()
        }
    } else if (consent.analytics) {
        console.log('[Microsoft Clarity] Reinitializing after consent granted')
        initializeAnalytics(consent)
    }

    // Update Google Analytics consent
    if (window.gtag) {
        if (!consent.analytics) {
            console.log('[Google Analytics] Revoking consent and removing GA4')
            window.gtag('consent', 'update', {
                analytics_storage: 'denied',
                ad_storage: 'denied'
            })
            removeGoogleAnalytics()
        } else {
            console.log('[Google Analytics] Granting consent')
            window.gtag('consent', 'update', {
                analytics_storage: 'granted',
                ad_storage: consent.marketing ? 'granted' : 'denied'
            })
        }
    } else if (consent.analytics) {
        console.log('[Google Analytics] Reinitializing after consent granted')
        initializeAnalytics(consent)
    }

    // Update Meta Pixel consent
    if (window.fbq) {
        if (!consent.marketing) {
            console.log('[Meta Pixel] Revoking consent and removing pixel')
            window.fbq('consent', 'revoke')
            removeMetaPixel()
        } else {
            console.log('[Meta Pixel] Granting consent')
            window.fbq('consent', 'grant')
        }
    } else if (consent.marketing) {
        console.log('[Meta Pixel] Reinitializing after consent granted')
        initializeAnalytics(consent)
    }
}

// Function to clean up tracking scripts when consent is withdrawn
export const removeTrackingScripts = () => {
    removeGoogleAnalytics()
    removeMetaPixel()
    removeMicrosoftClarity()
}

// Helper function to remove Microsoft Clarity
const removeMicrosoftClarity = () => {
    console.log('[Microsoft Clarity] Removing Clarity elements')

    // Remove Clarity script
    const clarityScript = document.querySelector('script[src*="clarity.ms/tag"]')
    if (clarityScript) {
        clarityScript.remove()
        console.log('[Microsoft Clarity] Removed clarity.ms script')
    }

    // Remove initialization script
    const clarityInitScript = document.querySelector('script[id="clarity-script"]')
    if (clarityInitScript) {
        clarityInitScript.remove()
        console.log('[Microsoft Clarity] Removed initialization script')
    }

    // Clear global variable
    delete window.clarity
    console.log('[Microsoft Clarity] Cleared global variable')
}

// Helper function to remove Google Analytics
const removeGoogleAnalytics = () => {
    console.log('[Google Analytics] Removing GA4 elements')

    // Remove GA4 script
    const gaScript = document.querySelector('script[src*="googletagmanager.com/gtag/js"]')
    if (gaScript) {
        gaScript.remove()
        console.log('[Google Analytics] Removed gtag.js script')
    }

    // Remove initialization script
    const gaInitScript = document.querySelector('script[id="ga4-script"]')
    if (gaInitScript) {
        gaInitScript.remove()
        console.log('[Google Analytics] Removed initialization script')
    }

    // Clear global variables
    delete window.gtag
    delete window.dataLayer
    console.log('[Google Analytics] Cleared global variables')
}

// Helper function to remove Meta Pixel
const removeMetaPixel = () => {
    console.log('[Meta Pixel] Removing pixel elements')
    // Remove Meta Pixel scripts
    const fbScript = document.querySelector('script[src*="connect.facebook.net/en_US/fbevents.js"]')
    if (fbScript) {
        fbScript.remove()
        console.log('[Meta Pixel] Removed fbevents.js script')
    }

    // Remove initialization script
    const fbInitScript = document.querySelector('script[id="fb-pixel-init"]')
    if (fbInitScript) {
        fbInitScript.remove()
        console.log('[Meta Pixel] Removed initialization script')
    }

    // Remove Meta Pixel noscript
    const fbNoscript = document.querySelector('noscript img[src*="facebook.com/tr"]')
    if (fbNoscript) {
        fbNoscript.parentElement?.remove()
        console.log('[Meta Pixel] Removed noscript element')
    }

    // Clear global variables
    delete window.fbq
    console.log('[Meta Pixel] Cleared global variables')
} 