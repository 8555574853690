export interface BlogPostMetadata {
    id: string;
    slug: string;
    title: string;
    excerpt: string;
    coverImage: string;
    publishedAt: string;
    author: {
        name: string;
        avatar?: string;
    };
    category: string;
    tags: string[];
    seo: {
        metaTitle: string;
        metaDescription: string;
        keywords: string[];
    };
}

export const blogPosts: BlogPostMetadata[] = [
    {
        id: "why-kibu-sustainable",
        slug: "why-kibu-headphones-most-sustainable-childrens-headphones",
        title: "Why Kibu Headphones are the Most Sustainable Children's Headphones",
        excerpt: "Kibu's innovative design, eco-friendly materials, and commitment to repairability make them the most sustainable children's headphones available today.",
        coverImage: "https://d1tg5gdu842vo5.cloudfront.net/site-assets/images/headphones-in-context/sustainable_thumbnail.jpg",
        publishedAt: "2025-02-28",
        author: {
            name: "Kibu Team"
        },
        category: "Sustainability",
        tags: ["sustainability", "eco-friendly", "recycling", "children's headphones", "circular economy"],
        seo: {
            metaTitle: "Why Kibu Headphones are the Most Sustainable Children's Headphones | Kibu Blog",
            metaDescription: "Learn how Kibu's eco-friendly materials, repairable design, and commitment to recycling make them the most sustainable children's headphones available.",
            keywords: ["sustainable headphones", "eco-friendly headphones", "recyclable headphones", "children's headphones", "circular economy", "green technology"]
        }
    },
    {
        id: "introducing-kibu",
        slug: "introducing-kibu-next-generation-childrens-headphones",
        title: "Introducing The Next Generation of Children's Headphones",
        excerpt: "Discover how Kibu is revolutionising children's headphones with buildable, repairable designs that promote sustainability and learning.",
        coverImage: "https://d1tg5gdu842vo5.cloudfront.net/site-assets/images/headphones-in-context/boy_listening_blue.jpg",
        publishedAt: "2025-02-27",
        author: {
            name: "Kibu Team"
        },
        category: "Product",
        tags: ["innovation", "sustainability", "DIY", "children's headphones", "STEM"],
        seo: {
            metaTitle: "Introducing Kibu: The Next Generation of Children's Headphones | Kibu Blog",
            metaDescription: "Discover Kibu's innovative buildable and repairable headphones for children that promote sustainability, safety, and STEM learning through hands-on experience.",
            keywords: ["children's headphones", "buildable headphones", "sustainable headphones", "DIY headphones", "volume-limited headphones", "STEM learning"]
        }
    },
    {
        id: "building-kibu-diy",
        slug: "building-your-own-kibu-headphones-diy-journey",
        title: "Building Your Own Kibu Headphones: A DIY Journey Towards Creativity and Sustainability",
        excerpt: "Building your own Kibu headphones combines hands-on STEM learning with sustainable practices, creating an engaging educational experience for children and parents alike.",
        coverImage: "https://d1tg5gdu842vo5.cloudfront.net/site-assets/images/headphones-in-context/boy_building.jpg",
        publishedAt: "2025-02-28",
        author: {
            name: "Kibu Team"
        },
        category: "DIY & Education",
        tags: ["DIY", "STEM education", "sustainability", "children's headphones", "hands-on learning"],
        seo: {
            metaTitle: "Building Your Own Kibu Headphones: A DIY Journey | Kibu Blog",
            metaDescription: "Learn how building Kibu headphones offers a unique STEM learning experience while promoting sustainability and creativity in children's technology.",
            keywords: ["DIY headphones", "STEM education", "sustainable technology", "children's headphones", "hands-on learning", "educational DIY", "buildable headphones"]
        }
    }
];

export const getPostBySlug = (slug: string): BlogPostMetadata | undefined => {
    return blogPosts.find(post => post.slug === slug);
};

export const getAllPosts = (): BlogPostMetadata[] => {
    return [...blogPosts].sort((a, b) =>
        new Date(b.publishedAt).getTime() - new Date(a.publishedAt).getTime()
    );
};

export const getPostsByCategory = (category: string): BlogPostMetadata[] => {
    return getAllPosts().filter(post =>
        post.category.toLowerCase() === category.toLowerCase()
    );
};

export const getPostsByTag = (tag: string): BlogPostMetadata[] => {
    return getAllPosts().filter(post =>
        post.tags.some(t => t.toLowerCase() === tag.toLowerCase())
    );
};

export const getAllCategories = (): string[] => {
    const categories = new Set(blogPosts.map(post => post.category));
    return Array.from(categories);
};

export const getAllTags = (): string[] => {
    const tags = new Set(blogPosts.flatMap(post => post.tags));
    return Array.from(tags);
}; 