import React, { useState } from 'react'
import useCookieConsentStore from '@/context/CookieConsentStore'
import { X, Settings } from 'lucide-react'

const CookieBanner: React.FC = () => {
    const { consent, setConsent } = useCookieConsentStore()
    const [showPreferences, setShowPreferences] = useState(false)

    if (consent.hasConsented && !showPreferences) {
        return null
    }

    const handleAcceptAll = () => {
        setConsent({
            necessary: true,
            analytics: true,
            marketing: true,
            preferences: true,
        })
        setShowPreferences(false)
    }

    const handleRejectAll = () => {
        setConsent({
            necessary: true,
            analytics: false,
            marketing: false,
            preferences: false,
        })
        setShowPreferences(false)
    }

    const handleSavePreferences = () => {
        setConsent(consent)
        setShowPreferences(false)
    }

    return (
        <div className="fixed bottom-0 left-0 right-0 z-50 bg-white shadow-lg">
            <div className="container mx-auto p-4 max-w-6xl">
                {!showPreferences ? (
                    <div className="flex flex-col md:flex-row items-start md:items-center justify-between gap-4">
                        <div className="flex-1">
                            <p className="text-sm text-gray-600">
                                We use cookies to enhance your browsing experience, serve personalized content, and analyze our traffic.
                                By clicking "Accept All", you consent to our use of cookies.
                            </p>
                        </div>
                        <div className="flex flex-col sm:flex-row gap-2 w-full md:w-auto">
                            <button
                                onClick={() => setShowPreferences(true)}
                                className="px-4 py-2 text-sm rounded-lg hover:bg-gray-200"
                            >
                                Preferences
                            </button>
                            <button
                                onClick={handleRejectAll}
                                className="px-4 py-2 text-sm rounded-lg hover:bg-gray-200"
                            >
                                Reject All
                            </button>

                            <button
                                onClick={handleAcceptAll}
                                className="px-4 py-2 text-sm bg-orange text-white rounded-lg hover:bg-orange/90"
                            >
                                Accept All
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="space-y-4">
                        <div className="flex items-center justify-between">
                            <h3 className="text-lg font-semibold">Cookie Preferences</h3>

                            <div className="flex items-center gap-2">
                                <button
                                    onClick={handleRejectAll}
                                    className="px-4 py-2 text-sm rounded-lg hover:bg-gray-50"
                                >
                                    Reject All
                                </button>
                                <button
                                    onClick={handleRejectAll}
                                    className="px-4 py-2 text-sm rounded-lg hover:bg-gray-50"
                                >
                                    Accept All
                                </button>
                                <button
                                    onClick={() => setShowPreferences(false)}
                                    className="p-1 hover:bg-gray-100 rounded-full"
                                >
                                    <X className="w-5 h-5" />
                                </button>
                            </div>
                        </div>
                        <div className="space-y-4">
                            <div className="flex items-center justify-between py-2">
                                <div>
                                    <h4 className="font-medium">Necessary Cookies</h4>
                                    <p className="text-sm text-gray-500">Required for the website to function properly</p>
                                </div>
                                <input
                                    type="checkbox"
                                    checked={consent.necessary}
                                    disabled
                                    className="h-4 w-4 rounded border-gray-300"
                                />
                            </div>
                            <div className="flex items-center justify-between py-2">
                                <div>
                                    <h4 className="font-medium">Analytics Cookies</h4>
                                    <p className="text-sm text-gray-500">Help us understand how visitors interact with our website</p>
                                </div>
                                <input
                                    type="checkbox"
                                    checked={consent.analytics}
                                    onChange={(e) => setConsent({ analytics: e.target.checked })}
                                    className="h-4 w-4 rounded border-gray-300"
                                />
                            </div>
                            <div className="flex items-center justify-between py-2">
                                <div>
                                    <h4 className="font-medium">Marketing Cookies</h4>
                                    <p className="text-sm text-gray-500">Used to deliver personalized advertisements</p>
                                </div>
                                <input
                                    type="checkbox"
                                    checked={consent.marketing}
                                    onChange={(e) => setConsent({ marketing: e.target.checked })}
                                    className="h-4 w-4 rounded border-gray-300"
                                />
                            </div>
                            <div className="flex items-center justify-between py-2">
                                <div>
                                    <h4 className="font-medium">Preference Cookies</h4>
                                    <p className="text-sm text-gray-500">Remember your settings and preferences</p>
                                </div>
                                <input
                                    type="checkbox"
                                    checked={consent.preferences}
                                    onChange={(e) => setConsent({ preferences: e.target.checked })}
                                    className="h-4 w-4 rounded border-gray-300"
                                />
                            </div>
                        </div>
                        <div className="flex justify-end gap-2 pt-4">
                            <button
                                onClick={handleSavePreferences}
                                className="px-4 py-2 text-sm bg-orange text-white rounded-lg hover:bg-orange/90"
                            >
                                Save Preferences
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default CookieBanner 