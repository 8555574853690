import { create } from 'zustand'
import { CountryDetails, fetchCountryDetails, fetchCountries, CountriesResponse, CountryInfo } from '@/services/api'

// Define supported regions and their info
export const REGION_INFO = {
    gb: {
        name: 'United Kingdom',
        flag: '🇬🇧',
    },
    us: {
        name: 'United States',
        flag: '🇺🇸',
    },
    de: {
        name: 'Germany',
        flag: '🇩🇪',
    },
    fr: {
        name: 'France',
        flag: '🇫🇷',
    },
    it: {
        name: 'Italy',
        flag: '🇮🇹',
    },
    au: {
        name: 'Australia',
        flag: '🇦🇺',
    },
    ca: {
        name: 'Canada',
        flag: '🇨🇦',
    },
} as const;

export type SupportedRegion = keyof typeof REGION_INFO;

interface CountryDetailsState {
    countryDetails: CountryDetails | null;
    countries: CountriesResponse | null;
    isLoading: boolean;
    error: string | null;
    fetchDetails: (countryCode: string) => Promise<void>;
    fetchAllCountries: () => Promise<void>;
    isRegionSupported: (region: string | null) => boolean;
    getRegionInfo: (region: string | null) => CountryInfo | null;
}

const useCountryDetailsStore = create<CountryDetailsState>((set, get) => ({
    countryDetails: null,
    countries: null,
    isLoading: false,
    error: null,
    fetchDetails: async (countryCode: string) => {
        try {
            set({ isLoading: true, error: null });
            const details = await fetchCountryDetails(countryCode);
            set({ countryDetails: details, isLoading: false });
        } catch (error) {
            set({
                error: error instanceof Error ? error.message : 'Failed to fetch country details',
                isLoading: false
            });
        }
    },
    fetchAllCountries: async () => {
        try {
            set({ isLoading: true, error: null });
            const countries = await fetchCountries();
            set({ countries, isLoading: false });
        } catch (error) {
            set({
                error: error instanceof Error ? error.message : 'Failed to fetch countries',
                isLoading: false
            });
        }
    },
    isRegionSupported: (region: string | null) => {
        if (!region) return false;
        const { countries } = get();
        if (!countries) return false;
        const lowercaseRegion = region.toLowerCase();

        // Check if it's a direct country or exists in any group
        return Object.entries(countries).some(([_, value]) => {
            if ('type' in value && value.type === 'group') {
                return lowercaseRegion in value.countries;
            }
            return lowercaseRegion === _.toLowerCase();
        });
    },
    getRegionInfo: (region: string | null) => {
        if (!region) return null;
        const { countries } = get();
        if (!countries) return null;
        const lowercaseRegion = region.toLowerCase();

        // First check if it's a direct country
        const directCountry = countries[lowercaseRegion];
        if (directCountry && !('type' in directCountry)) {
            return directCountry;
        }

        // Then check in groups
        for (const [_, value] of Object.entries(countries)) {
            if ('type' in value && value.type === 'group') {
                const groupCountry = value.countries[lowercaseRegion];
                if (groupCountry) {
                    return groupCountry;
                }
            }
        }

        return null;
    },
}));

export default useCountryDetailsStore; 