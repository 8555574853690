import { create } from 'zustand'

interface IDisplay {
	configuratorFrontOn: boolean
	'z-index'?: number
}

interface IComponentVariantId {
	$oid: string
}

export interface IMaterialInformation {
	materialId: string
	kibu_name: string
	finish: string
	hex_code: string
}

export interface IComponent {
	componentVariantId: IComponentVariantId
	position?: string
	materialOptions?: string[]
	defaultMaterial?: string
	display?: IDisplay[]
	name: string
	componentType: string
}

export interface ISaleLimit {
	type: string
	maxQuantity?: number
	period?: number
	unit?: string
	startDate?: string
	endDate?: string
}

export interface IColourway {
	_id: string
	productId: string
	name: string
	description: string
	stripeProductId: string | null
	isAvailableForSale: boolean
	saleLimit?: ISaleLimit | null
	title: string
	createdAt: string
	price?: IPrice
}

export interface IPrice {
	price_id: string
	unit_amount: number
	currency: string
}

export interface IPartsModifications {
	[key: string]: {
		type: string
		modifiedPartId: string[]
		maxLength?: number
		description?: string
	};
};

export interface IProduct {
	_id: string
	productName: string
	productTitle: string
	description: string
	productCatalogueId: string
	optionTitle?: string
	components?: ICustomComponent[]
	price?: {
		price_id: string
		unit_amount: number
		currency: string
	}
	availableOptions?: IOption[]
	componentModifications?: {
		engravingText?: {
			type: string
			maxLength: number
			description: string
			componentType: string
			processType: string
		}
	}
	partsModifications?: {
		[key: string]: {
			type: string
			modifiedPartId: string[]
			maxLength?: number
			description?: string
		}
	}
	productDescription?: {
		mobile: string
		desktop: string
	}
	averageCatalogueRating?: number
	totalCatalogueReviews?: number
}

export interface IOption {
	productCatalogueId: string
	limit: number
	order: number
	title: string
	required?: boolean
	options: IProduct[]
	info?: IInfoDetails
}

export interface ICustomComponent {
	componentVariantId: { $oid: string }
	componentType: string
	name: string
	position: string | null
	chosenMaterial: { $oid: string }
	display?: Array<{
		configuratorFrontOn: boolean
		'z-index'?: number
	}>
	materialOptions?: string[]
	defaultMaterial?: string
}

interface IProductDataStore {
	product: IProduct | null
	options: IOption[]
	materialInformation: IMaterialInformation[]
	setProductData: (product: IProduct, addons: IOption[], materialInfo: IMaterialInformation[]) => void
}

export interface IInfoDetails {
	title: string,
	text: string
}

const useProductDataStore = create<IProductDataStore>((set) => ({
	product: null,
	options: [],
	materialInformation: [],
	setProductData: (product, options, materialInfo) => set({ product, options, materialInformation: materialInfo }),
}))

export default useProductDataStore
