import React, { useState, useMemo, useEffect } from 'react';
import { Search, ChevronDown, ChevronRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import useRegionStore from '@/context/RegionStore';
import useCountryDetailsStore from '@/context/CountryDetailsStore';
import { CountryInfo, CountryGroup } from '@/services/api';
import useAvailableRegionsStore from '@/context/AvailableRegionsStore';

interface RegionSelectorModalProps {
    onClose: () => void;
}

const RegionSelectorModal: React.FC<RegionSelectorModalProps> = ({ onClose }) => {
    const [expandedGroups, setExpandedGroups] = useState<string[]>([]);
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();
    const { chosenRegion, setChosenRegion } = useRegionStore();
    const { countries, countryDetails } = useCountryDetailsStore();
    const { isRegionAvailable } = useAvailableRegionsStore();

    // Lock body scroll when modal is open
    useEffect(() => {
        const originalStyle = window.getComputedStyle(document.body).overflow;
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = originalStyle;
        };
    }, []);

    // Separate countries and groups, excluding the current region
    const individualCountries: [string, CountryInfo][] = [];
    const groups: [string, CountryGroup][] = [];

    if (countries && chosenRegion) {
        Object.entries(countries).forEach(([key, value]) => {
            if (key === chosenRegion) return; // Skip current region
            if ('type' in value && value.type === 'group') {
                // For groups, we need to check if the current region is in the group's countries
                if (value.type === 'group') {
                    const filteredCountries = { ...value.countries };
                    if (filteredCountries[chosenRegion]) {
                        delete filteredCountries[chosenRegion];
                    }
                    groups.push([key, { ...value, countries: filteredCountries }]);
                }
            } else {
                individualCountries.push([key, value as CountryInfo]);
            }
        });
    }

    const handleRegionSelect = (region: string) => {
        setChosenRegion(region);
        // Always navigate to home screen
        navigate(`/${region}`, { replace: true });
        onClose();
    };

    const toggleGroup = (groupName: string, event: React.MouseEvent) => {
        event.stopPropagation();
        setExpandedGroups(prev =>
            prev.includes(groupName)
                ? prev.filter(g => g !== groupName)
                : [...prev, groupName]
        );
    };

    // Filter countries based on search query
    const { filteredIndividualCountries, filteredGroups } = useMemo(() => {
        if (!countries || !searchQuery.trim()) {
            return {
                filteredIndividualCountries: individualCountries,
                filteredGroups: groups
            };
        }

        const query = searchQuery.toLowerCase();
        const matchesSearch = (name: string) => name.toLowerCase().includes(query);

        const filteredIndividual = individualCountries.filter(([_, info]) =>
            matchesSearch(info.name)
        );

        const filteredGroupsData = groups.map(([groupName, group]) => {
            const filteredCountries = Object.entries(group.countries).filter(([_, info]) =>
                matchesSearch(info.name)
            );
            return [
                groupName,
                { ...group, countries: Object.fromEntries(filteredCountries) }
            ] as [string, CountryGroup];
        }).filter(([_, group]) => Object.keys(group.countries).length > 0);

        return {
            filteredIndividualCountries: filteredIndividual,
            filteredGroups: filteredGroupsData
        };
    }, [countries, searchQuery, individualCountries, groups]);

    const renderCountryButton = (code: string, info: CountryInfo) => (
        <button
            key={code}
            onClick={() => handleRegionSelect(code)}
            className="flex items-center gap-2 w-full p-3 hover:bg-[#f4f4f4] transition-colors"
        >
            <span>{info.flag}</span>
            <span className="text-gray-900">{info.name}</span>
        </button>
    );

    if (!countries) {
        return null;
    }

    // Get current region info
    const currentRegionInfo = chosenRegion ? countries[chosenRegion] as CountryInfo : null;

    return (
        <div
            className="fixed inset-0 bg-black/50 z-[1000] flex items-center justify-center p-4"
            onClick={onClose}
        >
            <div
                className="bg-white rounded-xl shadow-xl max-w-md w-full max-h-[90vh] flex flex-col"
                onClick={(e) => e.stopPropagation()}
            >
                <div className="p-6 border-b border-gray-200">
                    <h2 className="text-2xl font-headline text-gray-900">Select Your Region</h2>
                </div>

                <div className="flex-1 overflow-y-auto min-h-0">
                    {/* Current Region Section */}
                    {currentRegionInfo && (
                        <div className="bg-orange/10 border-b border-orange/20">
                            <div className="flex items-center gap-2 w-full p-3">
                                <span>{currentRegionInfo.flag}</span>
                                <span className="text-gray-900 font-medium">
                                    Current Region: {currentRegionInfo.name}
                                </span>
                            </div>
                        </div>
                    )}

                    {filteredIndividualCountries.length === 0 && filteredGroups.length === 0 ? (
                        <div className="flex items-center gap-2 w-full p-3 text-gray-400">
                            <span>🔍</span>
                            <span>No countries found</span>
                        </div>
                    ) : (
                        <>
                            {/* Individual Countries Section */}
                            {filteredIndividualCountries.length > 0 && (
                                <div>
                                    {filteredIndividualCountries.map(([code, info]) =>
                                        renderCountryButton(code, info)
                                    )}
                                </div>
                            )}

                            {/* Groups Section */}
                            {filteredGroups.map(([groupName, group]) => (
                                <div key={groupName} className="relative">
                                    <button
                                        onClick={(e) => toggleGroup(groupName, e)}
                                        className="flex items-center justify-between w-full px-3 py-2 text-sm font-semibold text-gray-500 hover:bg-gray-50 sticky top-0 bg-white z-10"
                                    >
                                        <span>{groupName}</span>
                                        {(expandedGroups.includes(groupName) || searchQuery) ? (
                                            <ChevronDown className="w-4 h-4" />
                                        ) : (
                                            <ChevronRight className="w-4 h-4" />
                                        )}
                                    </button>
                                    {(expandedGroups.includes(groupName) || searchQuery) && (
                                        <div>
                                            {Object.entries(group.countries).map(([code, info]) =>
                                                renderCountryButton(code, info)
                                            )}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </>
                    )}
                </div>

                <div className="p-4 border-t border-gray-200">
                    <div className="relative mb-4">
                        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 w-4 h-4 text-gray-400" />
                        <input
                            type="text"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            placeholder="Search countries..."
                            className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange focus:border-transparent text-gray-900"
                        />
                    </div>
                    <button
                        onClick={onClose}
                        className="w-full p-3 bg-[#454545] text-white rounded-lg hover:bg-[#353535] transition-colors font-medium"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RegionSelectorModal; 