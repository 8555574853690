import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import remarkGfm from 'remark-gfm';
import { getPostBySlug, getAllPosts, BlogPostMetadata } from './content/metadata';
import './blog.css';

const BlogPost: React.FC = () => {
    const { slug } = useParams<{ slug: string }>();
    const [post, setPost] = useState<BlogPostMetadata | null>(null);
    const [content, setContent] = useState<string>('');
    const [relatedPosts, setRelatedPosts] = useState<BlogPostMetadata[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPostData = async () => {
            if (slug) {
                // Get post metadata
                const postData = getPostBySlug(slug);

                if (postData) {
                    setPost(postData);

                    try {
                        // Fetch the actual markdown content
                        const response = await fetch(`/blog/content/posts/${slug}.md`);
                        if (response.ok) {
                            const markdownText = await response.text();
                            // Extract content (remove frontmatter)
                            const contentStart = markdownText.indexOf('---', 3) + 3;
                            const postContent = markdownText.slice(contentStart).trim();
                            setContent(postContent);
                        } else {
                            console.error(`Failed to load post content: ${slug}`);
                        }
                    } catch (error) {
                        console.error('Error loading markdown file:', error);
                    }

                    // Get related posts (posts in the same category, excluding current post)
                    const allPosts = getAllPosts();
                    const related = allPosts
                        .filter(p => p.category === postData.category && p.slug !== slug)
                        .slice(0, 3);

                    setRelatedPosts(related);
                }

                setLoading(false);
            }
        };

        fetchPostData();
    }, [slug]);

    if (loading || !post) {
        return <div className="min-h-screen bg-background flex items-center justify-center">Loading...</div>;
    }

    return (
        <>
            <Helmet>
                <title>{post.seo.metaTitle}</title>
                <meta name="description" content={post.seo.metaDescription} />
                <meta name="keywords" content={post.seo.keywords.join(', ')} />
                <meta property="og:title" content={post.seo.metaTitle} />
                <meta property="og:description" content={post.seo.metaDescription} />
                <meta property="og:image" content={post.coverImage} />
                <meta property="og:type" content="article" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="article:published_time" content={post.publishedAt} />
                <meta property="article:author" content={post.author.name} />
                <meta property="article:section" content={post.category} />
                {post.tags.map(tag => (
                    <meta key={tag} property="article:tag" content={tag} />
                ))}
            </Helmet>

            <article className="min-h-screen bg-white">
                {/* Hero Section */}
                {/* <div className="relative h-[60vh] min-h-[400px] w-full overflow-hidden">
                    <img
                        src={post.coverImage}
                        alt={post.title}
                        className="absolute inset-0 w-full h-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent"></div>
                    <div className="absolute bottom-0 left-0 right-0 p-8 text-white">
                        <div className="container mx-auto">
                            <div className="max-w-3xl">
                                <div className="text-primary-light mb-4">{post.category}</div>
                                <h1 className="text-4xl md:text-5xl font-headline mb-4">
                                    {post.title}
                                </h1>
                                <div className="flex items-center gap-4">
                                    {post.author.avatar && (
                                        <img
                                            src={post.author.avatar}
                                            alt={post.author.name}
                                            className="w-12 h-12 rounded-full"
                                        />
                                    )}
                                    <div>
                                        <div className="font-medium">{post.author.name}</div>
                                        <div className="text-sm text-white/80">
                                            {new Date(post.publishedAt).toLocaleDateString('en-US', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric'
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <Link to="/blog" className="fixed top-24 left-6 m:left-12 border-[1px] border-primary text-primary rounded-full p-2 flex items-center justify-center hover:bg-primary hover:text-white transition-all duration-300">
                    <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
                    </svg>

                </Link>

                <div className="container mx-auto px-4 py-12 flex flex-col items-center max-w-2xl">
                    <div
                        className="text-xs uppercase font-medium text-gray-500 bg-gray-100 px-2 py-1 rounded-full w-fit mb-8"
                    >
                        {post.category}
                    </div>
                    <h1 className="text-4xl md:text-5xl font-headline mb-4 text-center">
                        {post.title}
                    </h1>
                    <div className="flex items-center gap-4 mt-8">
                        <div className="flex flex-row items-center gap-4">
                            <div className="font-headline">🖋️ {post.author.name}</div>
                            <div className="font-headline">
                                📅 {new Date(post.publishedAt).toLocaleDateString('en-GB', {
                                    year: 'numeric',
                                    month: 'long'
                                })}
                            </div>
                        </div>
                    </div>
                </div>


                {/* Content */}
                <div className="container mx-auto px-4 py-12">
                    <div className="max-w-3xl mx-auto">
                        <div className="blog-content">
                            <ReactMarkdown
                                rehypePlugins={[rehypeRaw, rehypeSanitize]}
                                remarkPlugins={[remarkGfm]}
                            >
                                {content}
                            </ReactMarkdown>
                        </div>

                        {/* Tags */}
                        <div className="mt-8 pt-8 border-t">
                            <div className="flex flex-wrap gap-2">
                                {post.tags.map(tag => (
                                    <span
                                        key={tag}
                                        className="bg-gray-100 text-gray-700 px-3 py-1 rounded-full text-sm"
                                    >
                                        {tag}
                                    </span>
                                ))}
                            </div>
                        </div>

                        {/* Author Bio */}
                        <div className="mt-8 p-6 bg-gray-50 rounded-lg">
                            <div className="flex items-start gap-4">
                                {post.author.avatar && (
                                    <img
                                        src={post.author.avatar}
                                        alt={post.author.name}
                                        className="w-16 h-16 rounded-full"
                                    />
                                )}
                                <div>
                                    <h3 className="font-headline text-lg mb-2">{post.author.name}</h3>
                                    <p className="text-gray-600">
                                        {post.author.name === 'Kibu Team'
                                            ? 'The Kibu Team is a group of passionate individuals who are dedicated to creating better products for children and the planet.'
                                            : post.author.name === 'James Wilson'
                                                ? 'James Wilson is Kibu\'s Head of Sustainability, focusing on eco-friendly product design and environmental education. He has a background in environmental science and sustainable manufacturing.'
                                                : 'A passionate member of the Kibu team, dedicated to creating better products for children and the planet.'}
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* Related Posts */}
                        {relatedPosts.length > 0 && (
                            <div className="mt-12">
                                <h2 className="text-2xl font-headline mb-6">Related Articles</h2>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                    {relatedPosts.map(relatedPost => (
                                        <Link
                                            key={relatedPost.id}
                                            to={`/blog/${relatedPost.slug}`}
                                            className="group"
                                        >
                                            <article className="bg-white rounded-lg overflow-hidden shadow-lg transition-transform group-hover:-translate-y-1">
                                                <div className="aspect-w-16 aspect-h-9 overflow-hidden">
                                                    <img
                                                        src={relatedPost.coverImage}
                                                        alt={relatedPost.title}
                                                        className="w-full h-full object-cover transition-transform group-hover:scale-105"
                                                    />
                                                </div>
                                                <div className="p-4">
                                                    <h3 className="text-lg font-headline mb-2 group-hover:text-primary transition-colors">
                                                        {relatedPost.title}
                                                    </h3>
                                                    <p className="text-gray-600 text-sm line-clamp-2">{relatedPost.excerpt}</p>
                                                </div>
                                            </article>
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        )}

                        {/* Back to Blog */}
                        <div className="mt-12 pt-8 border-t">
                            <Link
                                to="/blog"
                                className="inline-flex items-center text-primary hover:text-primary-dark"
                            >
                                <svg className="w-4 h-4 mr-2" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
                                </svg>
                                Back to Blog
                            </Link>
                        </div>
                    </div>
                </div>
            </article>
        </>
    );
};

export default BlogPost; 