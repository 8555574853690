import React, { useEffect, useRef } from 'react'
import { X } from 'lucide-react'
import useInfoPanelStore from '@/context/InfoPanelStore'
import { useNavigate } from 'react-router-dom'

const InfoScreen: React.FC = () => {
    const { isOpen, currentTitle, currentText, setInfoPanelOpen } = useInfoPanelStore()
    const infoRef = useRef<HTMLDivElement>(null)
    const navigate = useNavigate()

    const close = () => {
        setInfoPanelOpen(false)
    }

    // Handle scroll lock
    useEffect(() => {
        const originalStyle = window.getComputedStyle(document.body).overflow

        if (isOpen) {
            // Save the current scroll position and lock scrolling
            document.body.style.overflow = 'hidden'
        } else {
            // Restore scrolling
            document.body.style.overflow = originalStyle
        }

        // Cleanup function to restore original scroll state
        return () => {
            document.body.style.overflow = originalStyle
        }
    }, [isOpen])

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Escape' && isOpen) {
                close()
            }
        }

        const handleClickOutside = (e: MouseEvent) => {
            if (isOpen && infoRef.current && !infoRef.current.contains(e.target as Node)) {
                close()
            }
        }

        if (isOpen) {
            document.addEventListener('keydown', handleKeyDown)
            document.addEventListener('mousedown', handleClickOutside)
        } else {
            document.removeEventListener('keydown', handleKeyDown)
            document.removeEventListener('mousedown', handleClickOutside)
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown)
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [isOpen])

    // Handle link clicks within the info content
    useEffect(() => {
        const handleLinkClick = (e: Event) => {
            const target = e.target as HTMLElement
            const link = target.closest('a')

            if (link) {
                e.preventDefault()
                const href = link.getAttribute('href')

                // Close the info panel
                close()

                // If it's an internal link (starts with /)
                if (href && href.startsWith('/')) {
                    // Small delay to allow the panel to close smoothly
                    setTimeout(() => {
                        navigate(href)
                    }, 300)
                } else if (href) {
                    // For external links or mailto:
                    window.open(href, '_blank')
                }
            }
        }

        const infoContent = infoRef.current?.querySelector('.info-content')
        if (infoContent) {
            infoContent.addEventListener('click', handleLinkClick as EventListener)
        }

        return () => {
            if (infoContent) {
                infoContent.removeEventListener('click', handleLinkClick as EventListener)
            }
        }
    }, [isOpen, navigate])

    return (
        <div
            ref={infoRef}
            className={`fixed inset-0 z-50 flex items-start md:items-center justify-center bg-black/10 backdrop-blur-sm overflow-y-auto
            ${isOpen ? 'opacity-100 pointer-events-auto ease-hypereaseout duration-300' : 'opacity-0 pointer-events-none duration-300 ease-kibushopdefault'}`}
        >
            <div
                className={`relative flex flex-col bg-white p-4 md:p-6 rounded-2xl m-2 md:m-0 md:p-12 shadow-2xl w-full md:w-auto md:max-w-[700px] min-h-0 max-h-[calc(100vh-1rem)] my-2 md:my-4
                ${isOpen ? 'opacity-100 pointer-events-auto scale-100 translate-y-0 ease-hypereaseout duration-500' : 'opacity-0 pointer-events-none translate-y-0 scale-95 duration-300 ease-kibushopdefault'}`}>
                <div className="info-nav flex items-center justify-between mb-4">
                    <div className="font-headline text-2xl md:text-3xl pr-8">{currentTitle}</div>
                    <button onClick={close} className="absolute top-4 right-4 md:top-6 md:right-6 bg-orange rounded-full p-2">
                        <X
                            className={`h-4 w-4 transition-all text-white duration-1000 ease-kibushopdefault ${isOpen ? 'rotate-0' : 'rotate-90'}`}
                            strokeWidth={3}
                        />
                    </button>
                </div>

                <div
                    className="info-content text-base prose prose-lg overflow-y-auto flex-1
                    prose-headings:font-headline 
                    md:prose-h3:text-2xl md:prose-h3:text-black md:prose-h3:mt-8 md:prose-h3:mb-4
                    prose-h3:text-2xl prose-h3:text-black prose-h3:mt-8 prose-h3:mb-4
                    prose-p:leading-[18px] prose-p:mb-4
                    prose-strong:text-black prose-strong:font-semibold
                    prose-ul:mt-4 prose-ul:mb-6
                    prose-li:mb-3 prose-li:leading-[18px]
                    max-w-none"
                    dangerouslySetInnerHTML={{ __html: currentText }}
                />
            </div>
        </div>
    )
}

export default InfoScreen