import React, { useState, useEffect, useRef } from 'react'

interface InteractiveImageProps {
  parts: {
    part_name: string;
    index: number;
    position: string | null;
    src: string;
    isInteractable?: boolean;
  }[]
  className?: string
  setPartIndexHovered: (index: number | null, position: string | null) => void
  setPartIndexClicked: (index: number | null, position: string | null) => void
  partClicked: (index: number | null, position: string | null, mousePos: { x: number; y: number }) => void
  setMousePosition: (pos: { x: number; y: number }) => void
  navHeight?: number
}

const InteractiveImage: React.FC<InteractiveImageProps> = ({
  parts,
  className = '',
  setPartIndexHovered,
  setPartIndexClicked,
  partClicked,
  setMousePosition,
  navHeight = 0
}) => {
  const [hoveredPart, setHoveredPart] = useState<number | null>(null)
  const [clickedPart, setClickedPart] = useState<number | null>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const loadedImagesRef = useRef<HTMLImageElement[]>([])
  const [imagesLoaded, setImagesLoaded] = useState(false)
  const hitDetectionCanvasRef = useRef<HTMLCanvasElement | null>(null)
  const resizeObserverRef = useRef<ResizeObserver | null>(null)
  const rafRef = useRef<number | null>(null)
  const lastMoveTime = useRef<number>(0)

  // Cancel any pending animation frame on unmount
  useEffect(() => {
    return () => {
      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (!parts) return

    const canvas = canvasRef.current
    const container = containerRef.current
    // console.log("CHECKING interactive image parts", parts)

    // Filter out non-interactable parts
    const interactableParts = parts.filter(part => part.isInteractable === true)

    // console.log("CHECKING interactive image interactable parts", interactableParts)

    const loadImage = (src: string): Promise<HTMLImageElement> => {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = () => resolve(img)
        img.onerror = reject
        img.src = src
      })
    }

    Promise.all(interactableParts.map(part => loadImage(part.src)))
      .then(images => {
        loadedImagesRef.current = images
        // Create a single offscreen canvas for hit detection
        if (!hitDetectionCanvasRef.current) {
          hitDetectionCanvasRef.current = document.createElement('canvas')
        }
        if (images.length > 0) updateCanvasSize()
      })
      .catch(error => {
        console.error('Failed to load images:', error)
      })
      .finally(() => {
        drawAllParts()
        setImagesLoaded(true)
      })

    const updateCanvasSize = () => {
      if (!canvas || !container || !loadedImagesRef.current[0]) return

      // Get the container's dimensions
      const containerRect = container.getBoundingClientRect()
      const containerWidth = containerRect.width
      const containerHeight = containerRect.height

      // Calculate the aspect ratio of the original image
      const imageAspectRatio = loadedImagesRef.current[0].width / loadedImagesRef.current[0].height
      const containerAspectRatio = containerWidth / containerHeight

      // Determine dimensions that maintain aspect ratio and fit within container
      let canvasWidth, canvasHeight
      if (containerAspectRatio > imageAspectRatio) {
        // Container is wider than image aspect ratio
        canvasHeight = containerHeight
        canvasWidth = containerHeight * imageAspectRatio
      } else {
        // Container is taller than image aspect ratio
        canvasWidth = containerWidth
        canvasHeight = containerWidth / imageAspectRatio
      }

      canvas.width = canvasWidth
      canvas.height = canvasHeight

      // Center the canvas in the container
      canvas.style.position = 'absolute'
      canvas.style.left = `${(containerWidth - canvasWidth) / 2}px`
      canvas.style.top = `${((containerHeight - canvasHeight) / 2)}px`
    }

    // Set up ResizeObserver for the container
    if (!resizeObserverRef.current) {
      resizeObserverRef.current = new ResizeObserver(() => {
        updateCanvasSize()
        drawAllParts()
      })
    }

    if (container) {
      resizeObserverRef.current.observe(container)
    }

    return () => {
      if (resizeObserverRef.current && container) {
        resizeObserverRef.current.unobserve(container)
      }
    }
  }, [parts, navHeight])

  const deselect = () => {
    setPartIndexClicked(null, null)
    partClicked(null, null, { x: 0, y: 0 })
  }

  const handleOutsideClick = (e: MouseEvent) => {
    // Don't deselect if clicking on the color picker or its children
    if (e.target instanceof Element &&
      (e.target.closest('.colour-picker-container') ||
        e.target.closest('.colour-picker-button'))) {
      return
    }

    // Only deselect if clicking outside the canvas and color picker
    if (!(e.target instanceof HTMLCanvasElement)) {
      deselect()
    }
  }

  useEffect(() => {
    window.addEventListener('click', handleOutsideClick)
    return () => {
      window.removeEventListener('click', handleOutsideClick)
      deselect()
    }
  }, [])

  const drawAllParts = () => {
    const canvas = canvasRef.current
    if (!canvas) return
    const ctx = canvas.getContext('2d')
    if (!ctx) return

    // Use requestAnimationFrame for smooth rendering
    if (rafRef.current) {
      cancelAnimationFrame(rafRef.current)
    }

    rafRef.current = requestAnimationFrame(() => {
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      loadedImagesRef.current.forEach(img => {
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
      })
    })
  }

  const detectPart = (x: number, y: number) => {
    const ALPHA_THRESHOLD = 10
    const canvas = canvasRef.current
    const hitCanvas = hitDetectionCanvasRef.current
    if (!canvas || !hitCanvas) return null

    // Convert click coordinates to be relative to the canvas
    const canvasRect = canvas.getBoundingClientRect()
    const scaleX = canvas.width / canvasRect.width
    const scaleY = canvas.height / canvasRect.height

    // Adjust coordinates based on canvas position and scaling and ensure they are integers
    const adjustedX = Math.round(x * scaleX)
    const adjustedY = Math.round(y * scaleY)

    // Only check interactable parts
    const interactableParts = parts.filter(part => part.isInteractable === true)
    const hitCtx = hitCanvas.getContext('2d')
    if (!hitCtx) return null

    // Ensure hit detection canvas matches display canvas size
    hitCanvas.width = canvas.width
    hitCanvas.height = canvas.height

    // console.log("Checking for click at:", { adjustedX, adjustedY })

    // Check parts from top to bottom (reverse order)
    for (let i = interactableParts.length - 1; i >= 0; i--) {
      const part = interactableParts[i]
      const img = loadedImagesRef.current[i]

      // Clear the hit detection canvas
      hitCtx.clearRect(0, 0, hitCanvas.width, hitCanvas.height)

      // Draw only the current part we're checking
      hitCtx.drawImage(img, 0, 0, hitCanvas.width, hitCanvas.height)

      try {
        const partPixelData = hitCtx.getImageData(
          adjustedX,
          adjustedY,
          1,
          1
        ).data
        // console.log(`Checking part: ${part.part_name}, alpha: ${partPixelData[3]} `)
        if (partPixelData[3] >= ALPHA_THRESHOLD) {
          // console.log(`Hit detected on part: ${part.part_name} `)
          return part
        }
      } catch (error) {
        console.error(`Error getting image data for part ${part.part_name}: `, {
          error,
          coordinates: { adjustedX, adjustedY },
          dimensions: { width: hitCanvas.width, height: hitCanvas.height }
        })
      }
    }
    // console.log("No part detected at click position")
    return null
  }

  const handleCanvasClick = (event: React.MouseEvent<HTMLCanvasElement>) => {
    const canvas = canvasRef.current
    if (!canvas) return

    const rect = canvas.getBoundingClientRect()
    const x = event.clientX - rect.left
    const y = event.clientY - rect.top

    setMousePosition({ x: event.clientX, y: event.clientY })

    const detectedPart = detectPart(x, y)
    if (!detectedPart) {
      deselect()
      return
    }

    setPartIndexClicked(detectedPart.index, detectedPart.position)
    partClicked(detectedPart.index, detectedPart.position, { x: event.clientX, y: event.clientY })
  }

  const handleCanvasOver = (event: React.MouseEvent<HTMLCanvasElement>) => {
    const now = Date.now()
    // Throttle mousemove events to every 16ms (roughly 60fps)
    if (now - lastMoveTime.current < 16) return
    lastMoveTime.current = now

    const canvas = canvasRef.current
    if (!canvas) return

    const rect = canvas.getBoundingClientRect()
    const x = event.clientX - rect.left
    const y = event.clientY - rect.top

    setMousePosition({ x: event.clientX, y: event.clientY })

    // Use requestAnimationFrame for hit detection
    if (rafRef.current) {
      cancelAnimationFrame(rafRef.current)
    }

    rafRef.current = requestAnimationFrame(() => {
      const detectedPart = detectPart(x, y)
      if (!detectedPart) {
        setPartIndexHovered(null, null)
        return
      }

      setPartIndexHovered(detectedPart.index, detectedPart.position)
    })
  }

  const triggerClickOnPart = (partIndex: number) => {
    const canvas = canvasRef.current
    if (!canvas) return

    const rect = canvas.getBoundingClientRect()
    const centerX = rect.left + rect.width / 2
    const centerY = rect.top + rect.height / 2

    const clickEvent = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
      clientX: centerX,
      clientY: centerY
    })

    canvas.dispatchEvent(clickEvent)
  }

  useEffect(() => {
    const clickOn = 3
    if (imagesLoaded && loadedImagesRef.current.length === parts.length && canvasRef.current) {
      triggerClickOnPart(clickOn)
    }
  }, [imagesLoaded, parts, canvasRef])

  return (
    <div ref={containerRef} className={`interactive - image absolute w - full h - full opacity-0 ${className} `}>
      <canvas
        ref={canvasRef}
        onClick={handleCanvasClick}
        onMouseMove={handleCanvasOver}
        onMouseLeave={() => setHoveredPart(null)}
        className={`w - auto h - auto ${hoveredPart !== null ? 'cursor-pointer' : ''} `}
      />
    </div>
  )
}

export default InteractiveImage
