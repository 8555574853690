import React, { useEffect, useRef, useState, useCallback } from 'react';
import { ScrollingGalleryProps, GalleryMedia } from '../types/scrolling-gallery.types';
import './ScrollingGallery.css';

const defaultCommunityContent = {
    title: {
        prefix: 'Share Your',
        highlight: 'Kibu',
        suffix: 'Story'
    },
    description: 'Join our community of young builders and share your Kibu moments with us.',
    ctaText: 'Share Now',
    ctaLink: '/community'
};

const ScrollingGallery: React.FC<ScrollingGalleryProps> = ({
    className = '',
    communityContent = defaultCommunityContent,
    images
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [isMobile, setIsMobile] = useState(false);
    const animationFrameRef = useRef<number>();
    const lastTimeRef = useRef<number>(0);
    const scrollVelocity = 0.07;
    const [isInView, setIsInView] = useState(false);
    const startDelayTimeoutRef = useRef<NodeJS.Timeout>();
    const lastScrollPositionRef = useRef<number>(0);
    const scrollTimeoutRef = useRef<NodeJS.Timeout>();
    const [isManualScrolling, setIsManualScrolling] = useState(false);
    const [touchStartY, setTouchStartY] = useState(0);
    const [isHorizontalScroll, setIsHorizontalScroll] = useState(false);
    const [loadedMedia, setLoadedMedia] = useState<Set<string>>(new Set());

    const observerRef = useRef<IntersectionObserver | null>(null);
    const mediaObserverRef = useRef<IntersectionObserver | null>(null);

    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth < 768);
        };

        checkMobile();
        window.addEventListener('resize', checkMobile);
        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    useEffect(() => {
        if (isInView) {
            console.log('Component is in view, starting auto-scroll after delay');
            // Clear any existing timeout
            if (startDelayTimeoutRef.current) {
                clearTimeout(startDelayTimeoutRef.current);
            }
            // Start scrolling after a 1 second delay
            startDelayTimeoutRef.current = setTimeout(() => {
                console.log('Starting auto-scroll now');
                startAutoScroll();
            }, 1000);
        } else {
            console.log('Component is not in view');
        }
        return () => {
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
            }
            if (startDelayTimeoutRef.current) {
                clearTimeout(startDelayTimeoutRef.current);
            }
        };
    }, [isInView]);

    // Add scroll event listener
    useEffect(() => {
        const container = containerRef.current;
        if (!container) return;

        const handleScroll = () => {
            const currentPosition = container.scrollLeft;

            // Clear any existing timeout
            if (scrollTimeoutRef.current) {
                clearTimeout(scrollTimeoutRef.current);
            }

            // If the position has changed, pause auto-scroll
            if (currentPosition !== lastScrollPositionRef.current) {
                if (animationFrameRef.current) {
                    cancelAnimationFrame(animationFrameRef.current);
                    animationFrameRef.current = undefined;
                }
            }

            // Set a timeout to resume auto-scroll if position hasn't changed
            scrollTimeoutRef.current = setTimeout(() => {
                if (currentPosition === container.scrollLeft && isInView) {
                    startAutoScroll();
                }
            }, 1000);

            lastScrollPositionRef.current = currentPosition;
        };

        container.addEventListener('scroll', handleScroll);
        return () => {
            container.removeEventListener('scroll', handleScroll);
            if (scrollTimeoutRef.current) {
                clearTimeout(scrollTimeoutRef.current);
            }
        };
    }, [isInView]);

    // Clean up all timeouts
    useEffect(() => {
        return () => {
            if (scrollTimeoutRef.current) {
                clearTimeout(scrollTimeoutRef.current);
            }
            if (startDelayTimeoutRef.current) {
                clearTimeout(startDelayTimeoutRef.current);
            }
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
            }
        };
    }, []);

    const startAutoScroll = () => {
        if (!containerRef.current || !isInView) {
            console.log('Cannot start auto-scroll:', {
                hasContainer: !!containerRef.current,
                isInView
            });
            return;
        }

        // Cancel any existing animation frame
        if (animationFrameRef.current) {
            cancelAnimationFrame(animationFrameRef.current);
        }

        const animate = (currentTime: number) => {
            if (!containerRef.current || !isInView) {
                console.log('Stopping animation - conditions not met');
                if (animationFrameRef.current) {
                    cancelAnimationFrame(animationFrameRef.current);
                }
                return;
            }

            if (!lastTimeRef.current) {
                lastTimeRef.current = currentTime;
            }

            const deltaTime = currentTime - lastTimeRef.current;
            lastTimeRef.current = currentTime;

            const scrollAmount = deltaTime * scrollVelocity;
            containerRef.current.scrollLeft += scrollAmount;
            lastScrollPositionRef.current = containerRef.current.scrollLeft;
            animationFrameRef.current = requestAnimationFrame(animate);
        };

        console.log('Initializing animation');
        lastTimeRef.current = 0;
        animationFrameRef.current = requestAnimationFrame(animate);
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    console.log('Intersection observer update:', {
                        isIntersecting: entry.isIntersecting,
                        intersectionRatio: entry.intersectionRatio
                    });
                    setIsInView(entry.isIntersecting);
                });
            },
            {
                threshold: 0.5,
                rootMargin: '50px'
            }
        );

        if (containerRef.current) {
            observer.observe(containerRef.current);
            console.log('Observer attached to container');
        }

        return () => {
            if (containerRef.current) {
                observer.unobserve(containerRef.current);
            }
        };
    }, []);

    // Function to handle media loading
    const handleMediaLoad = (url: string) => {
        console.log('Media loaded:', url);
        setLoadedMedia(prev => new Set(prev).add(url));
    };

    // Function to check if media is loaded
    const isMediaLoaded = (url: string) => {
        return loadedMedia.has(url);
    };

    // Setup media observer for lazy loading
    const setupMediaObserver = useCallback(() => {
        if (mediaObserverRef.current) {
            mediaObserverRef.current.disconnect();
        }

        mediaObserverRef.current = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        // Get the media URL from the data attribute
                        const mediaElement = entry.target as HTMLElement;
                        const mediaUrl = mediaElement.dataset.mediaUrl;

                        if (mediaUrl) {
                            // Load the actual media
                            const mediaType = mediaElement.dataset.mediaType;

                            if (mediaType === 'image') {
                                const img = mediaElement.querySelector('img');
                                if (img) {
                                    // If data-src exists, use it, otherwise the src might already be set
                                    const dataSrc = img.getAttribute('data-src');
                                    if (dataSrc) {
                                        img.src = dataSrc;
                                        img.classList.remove('opacity-0');
                                    }

                                    // Mark as loaded immediately if it's already complete
                                    if (img.complete) {
                                        handleMediaLoad(mediaUrl);
                                    } else {
                                        img.onload = () => handleMediaLoad(mediaUrl);
                                    }
                                }
                            } else if (mediaType === 'video') {
                                const video = mediaElement.querySelector('video');
                                if (video) {
                                    // If data-src exists, use it, otherwise the src might already be set
                                    const source = video.querySelector('source');
                                    const dataSrc = source?.getAttribute('data-src');

                                    if (dataSrc && source) {
                                        source.src = dataSrc;
                                        video.classList.remove('opacity-0');
                                        video.load();
                                    }

                                    // Check if video is already loaded
                                    if (video.readyState >= 3) { // HAVE_FUTURE_DATA or higher
                                        handleMediaLoad(mediaUrl);
                                    } else {
                                        video.onloadeddata = () => handleMediaLoad(mediaUrl);
                                    }
                                }
                            }
                        }
                    }
                });
            },
            {
                threshold: 0.1,
                rootMargin: '200px' // More aggressive loading
            }
        );

        // Observe all gallery images
        document.querySelectorAll('.media-container').forEach(container => {
            if (mediaObserverRef.current) {
                mediaObserverRef.current.observe(container);
            }
        });
    }, []);

    // Immediately load visible media when component mounts or becomes visible
    useEffect(() => {
        if (isInView) {
            // Force load any media that's already visible
            document.querySelectorAll('.media-container').forEach(container => {
                const mediaElement = container as HTMLElement;
                const mediaUrl = mediaElement.dataset.mediaUrl;
                const mediaType = mediaElement.dataset.mediaType;

                if (mediaUrl && !isMediaLoaded(mediaUrl)) {
                    if (mediaType === 'image') {
                        const img = mediaElement.querySelector('img');
                        if (img && img.getAttribute('data-src')) {
                            img.src = img.getAttribute('data-src') || '';
                            img.classList.remove('opacity-0');

                            if (img.complete) {
                                handleMediaLoad(mediaUrl);
                            } else {
                                img.onload = () => handleMediaLoad(mediaUrl);
                            }
                        }
                    } else if (mediaType === 'video') {
                        const video = mediaElement.querySelector('video');
                        if (video) {
                            const source = video.querySelector('source');
                            if (source && source.getAttribute('data-src')) {
                                source.src = source.getAttribute('data-src') || '';
                                video.classList.remove('opacity-0');
                                video.load();

                                if (video.readyState >= 3) {
                                    handleMediaLoad(mediaUrl);
                                } else {
                                    video.onloadeddata = () => handleMediaLoad(mediaUrl);
                                }
                            }
                        }
                    }
                }
            });
        }
    }, [isInView, loadedMedia]);

    const setupImageObserver = useCallback(() => {
        if (observerRef.current) {
            observerRef.current.disconnect();
        }

        observerRef.current = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.target.classList.contains('gallery-image')) {
                        if (entry.isIntersecting) {
                            entry.target.classList.add('in-view');
                        } else {
                            entry.target.classList.remove('in-view');
                        }
                    }
                });
            },
            {
                threshold: 0.5,
                root: containerRef.current
            }
        );

        // Observe all gallery images
        document.querySelectorAll('.gallery-image').forEach(image => {
            if (observerRef.current) {
                observerRef.current.observe(image);
            }
        });
    }, []);

    useEffect(() => {
        setupImageObserver();

        // Only setup media observer when component is in view
        if (isInView) {
            setupMediaObserver();
        }

        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
            if (mediaObserverRef.current) {
                mediaObserverRef.current.disconnect();
            }
        };
    }, [setupImageObserver, setupMediaObserver, isInView]);

    // Create tiles from media (4 items per tile)
    const tiles = images.reduce((acc: GalleryMedia[][], media, index) => {
        const tileIndex = Math.floor(index / 4);
        if (!acc[tileIndex]) {
            acc[tileIndex] = [];
        }
        acc[tileIndex].push(media);
        return acc;
    }, []);

    const getImageStyles = (image: GalleryMedia) => {
        const baseStyles = isMobile ? {
            width: image.mobileWidth || image.width,
            marginTop: image.mobileMarginTop || image.marginTop,
            marginBottom: image.mobileMarginBottom || image.marginBottom,
            marginLeft: image.mobileMarginLeft || image.marginLeft,
            marginRight: image.mobileMarginRight || image.marginRight,
        } : {
            width: image.width,
            marginTop: image.marginTop,
            marginBottom: image.marginBottom,
            marginLeft: image.marginLeft,
            marginRight: image.marginRight,
        };

        return baseStyles;
    };

    const getInnerStyles = (image: GalleryMedia) => {
        return {
            aspectRatio: image.aspectRatio || '1/1'
        };
    };

    const handleMouseDown = (e: React.MouseEvent) => {
        if (!containerRef.current) return;
        setIsDragging(true);
        setStartX(e.pageX - containerRef.current.offsetLeft);
        setScrollLeft(containerRef.current.scrollLeft);
        e.preventDefault();
    };

    const handleMouseMove = (e: React.MouseEvent) => {
        if (!isDragging || !containerRef.current) return;
        const x = e.pageX - containerRef.current.offsetLeft;
        const walk = (x - startX) * 2;
        containerRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseLeave = () => {
        if (isDragging) {
            handleMouseUp();
        }
    };

    const handleTouchStart = (e: React.TouchEvent) => {
        if (!containerRef.current) return;
        setIsDragging(true);
        setStartX(e.touches[0].pageX - containerRef.current.offsetLeft);
        setScrollLeft(containerRef.current.scrollLeft);
    };

    const handleTouchMove = (e: React.TouchEvent) => {
        if (!isDragging || !containerRef.current) return;

        const touchX = e.touches[0].pageX - containerRef.current.offsetLeft;
        const deltaX = touchX - startX;

        // Only handle horizontal scrolling in the gallery
        if (Math.abs(deltaX) > 5) { // Small threshold to prevent accidental scrolls
            containerRef.current.scrollLeft = scrollLeft - deltaX;
        }
    };

    const handleTouchEnd = () => {
        setIsDragging(false);
    };

    const renderMediaContent = (media: GalleryMedia) => {
        if (media.type === 'video') {
            return (
                <div
                    className="media-container"
                    data-media-url={media.url}
                    data-media-type="video"
                >
                    {isMediaLoaded(media.url) ? (
                        <video
                            src={media.url}
                            poster={media.thumbnailUrl}
                            autoPlay={media.autoplay ?? true}
                            loop={media.loop ?? true}
                            muted={media.muted ?? true}
                            playsInline
                            className="absolute inset-0 w-full h-full object-cover"
                        />
                    ) : (
                        <>
                            <div className="video-placeholder" style={{ backgroundImage: media.thumbnailUrl ? `url(${media.thumbnailUrl})` : undefined }}>
                                {!media.thumbnailUrl && (
                                    <div className="video-loading-indicator">
                                        <svg className="animate-spin h-8 w-8 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                    </div>
                                )}
                            </div>
                            <video
                                data-src={media.url}
                                poster={media.thumbnailUrl}
                                autoPlay={media.autoplay ?? true}
                                loop={media.loop ?? true}
                                muted={media.muted ?? true}
                                playsInline
                                className="absolute inset-0 w-full h-full object-cover opacity-0"
                                onLoadedData={() => {
                                    handleMediaLoad(media.url);
                                }}
                            >
                                <source data-src={media.url} type="video/mp4" />
                            </video>
                        </>
                    )}
                    <div className="gallery-image-overlay">
                        <span className="gallery-image-handle">
                            {media.alt}
                        </span>
                    </div>
                </div>
            );
        }

        return (
            <div
                className="media-container"
                data-media-url={media.url}
                data-media-type="image"
            >
                {isMediaLoaded(media.url) ? (
                    <img
                        src={media.url}
                        alt={media.alt}
                        className="absolute inset-0 w-full h-full object-cover"
                    />
                ) : (
                    <>
                        <div className="image-placeholder">
                            <div className="image-loading-indicator">
                                <svg className="animate-spin h-8 w-8 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            </div>
                        </div>
                        <img
                            data-src={media.url}
                            alt={media.alt}
                            className="absolute inset-0 w-full h-full object-cover opacity-0"
                            onLoad={() => {
                                handleMediaLoad(media.url);
                            }}
                        />
                    </>
                )}
                <div className="gallery-image-overlay">
                    <span className="gallery-image-handle">
                        {media.alt}
                    </span>
                </div>
            </div>
        );
    };

    return (
        <div className={`scrolling-gallery ${className}`}>
            <div
                ref={containerRef}
                className={`scrolling-gallery-container ${isDragging ? 'dragging' : ''}`}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseLeave}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
            >
                <div className="gallery-section">
                    <div className="community-section">
                        <h2 className="community-title">
                            {communityContent.title.prefix}{' '}
                            <span className="highlight">{communityContent.title.highlight}</span>{' '}
                            {communityContent.title.suffix}
                        </h2>
                        <p className="community-description">
                            {communityContent.description}
                        </p>
                        <a href={communityContent.ctaLink} className="community-cta">
                            {communityContent.ctaText}
                        </a>
                    </div>
                </div>
                {tiles.map((tileMedia, tileIndex) => (
                    <div key={tileIndex} className="gallery-section images">
                        {tileMedia.map((media, mediaIndex) => (
                            <div
                                key={mediaIndex}
                                className="gallery-image"
                                style={getImageStyles(media)}
                            >
                                <div className="gallery-image-inner" style={getInnerStyles(media)}>
                                    {renderMediaContent(media)}
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
                <div className="gallery-section">
                    <div className="community-section-right">
                        <h2 className="community-title">
                            {communityContent.title.prefix}{' '}
                            <span className="highlight">{communityContent.title.highlight}</span>{' '}
                            {communityContent.title.suffix}
                        </h2>
                        <p className="community-description">
                            {communityContent.description}
                        </p>
                        <a href={communityContent.ctaLink} className="community-cta">
                            {communityContent.ctaText}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ScrollingGallery;