import { GalleryMedia } from '../types/scrolling-gallery.types';

export const galleryImages: GalleryMedia[] = [
    {
        url: 'https://d1tg5gdu842vo5.cloudfront.net/site-assets/ugc/images/ugc-lacabinedemargaux.jpg',
        type: 'image',
        alt: '@lacabinedemargaux',
        width: '12.375rem',
        marginTop: '5.3125rem',
        mobileWidth: '9.3125rem',
        mobileMarginTop: '2.625rem',
        mobileMarginRight: '1.8125rem'
    },

    {
        url: 'https://d1tg5gdu842vo5.cloudfront.net/site-assets/ugc/images/ugc-laurajackson.jpg',
        type: 'image',
        alt: '@laurajackson',
        width: '14rem',
        marginTop: '2.4375rem',
        marginLeft: '2rem',
        mobileWidth: '10.3125rem',
        mobileMarginTop: '2.1875rem',
        aspectRatio: '9/16'
    },
    {
        url: 'https://d1tg5gdu842vo5.cloudfront.net/site-assets/ugc/video/ugc-designersvane.mp4',
        type: 'video',
        alt: '@designersvane',
        width: '14rem',
        marginTop: '2.4375rem',
        marginLeft: '6rem',
        mobileWidth: '9.75rem',
        mobileMarginTop: '3.1875rem',
        mobileMarginLeft: '2.5rem',
        mobileMarginBottom: 'auto',
        autoplay: true,
        loop: true,
        muted: true,
        aspectRatio: '9/15'
    },
    {
        url: 'https://d1tg5gdu842vo5.cloudfront.net/site-assets/ugc/images/ugc-noahbier.jpg',
        type: 'image',
        alt: '@noahbier',
        width: '10rem',
        marginTop: '2.1875rem',
        marginLeft: '4rem',
        mobileWidth: '9.3125rem',
        mobileMarginTop: '-4.0625rem',
        mobileMarginLeft: '1.5rem',
        mobileMarginBottom: 'auto',
        autoplay: true,
        loop: true,
        muted: true
    },
    {
        url: 'https://d1tg5gdu842vo5.cloudfront.net/site-assets/ugc/images/ugc-submitted_1393.jpg',
        type: 'image',
        alt: 'Submitted',
        width: '10.375rem',
        marginTop: '12.3125rem',
        marginLeft: '1rem',
        mobileWidth: '7.3125rem',
        mobileMarginTop: '5.625rem',
        mobileMarginLeft: '12rem'
    },
    {
        url: 'https://d1tg5gdu842vo5.cloudfront.net/site-assets/ugc/images/ugc-submitted_1476.jpg',
        type: 'image',
        alt: 'Submitted',
        width: '12.375rem',
        marginTop: '2.1875rem',
        marginLeft: '2rem',
        mobileWidth: '10.3125rem',
        mobileMarginTop: '3.0625rem',
        mobileMarginLeft: '13rem',
        mobileMarginBottom: 'auto',
        aspectRatio: '9/16'
    },
    {
        url: 'https://d1tg5gdu842vo5.cloudfront.net/site-assets/ugc/images/ugc-submitted_1456.jpg',
        type: 'image',
        alt: 'Submitted',
        width: '18rem',
        marginTop: '5.5rem',
        marginLeft: '0rem',
        mobileWidth: '10.3125rem',
        mobileMarginTop: '2.1875rem'
    },
    {
        url: 'https://d1tg5gdu842vo5.cloudfront.net/site-assets/ugc/images/ugc-submitted_1388.jpg',
        type: 'image',
        alt: 'Submitted',
        width: '10rem',
        marginTop: '6.4375rem',
        marginLeft: '6rem',
        mobileWidth: '12.75rem',
        mobileMarginTop: '5.1875rem',
        mobileMarginLeft: '2rem',
        mobileMarginBottom: 'auto',
    },
    {
        url: 'https://d1tg5gdu842vo5.cloudfront.net/site-assets/ugc/images/ugc-submitted_1430.jpg',
        type: 'image',
        alt: 'Submitted',
        width: '12.375rem',
        marginTop: '8.3125rem',
        mobileWidth: '9.3125rem',
        mobileMarginTop: '4.625rem',
        mobileMarginLeft: '12.8125rem'
    },

    {
        url: 'https://d1tg5gdu842vo5.cloudfront.net/site-assets/ugc/images/ugc-submitted_1382.jpg',
        type: 'image',
        alt: 'Submitted',
        width: '16rem',
        marginTop: '2.4375rem',
        marginLeft: '10rem',
        mobileWidth: '10.3125rem',
        mobileMarginTop: '2.1875rem',
        mobileMarginLeft: '15.8125rem'
    }
]; 