import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';
import { SpotlightModuleProps } from '../types/spotlight.types';
import Button from '../../../components/Button';
import './SpotlightSplitModule.css';

interface SpotlightSplitModuleProps extends SpotlightModuleProps {
    backgroundColor?: string;
    button?: boolean;
    fadeEffect?: boolean;
}

const SpotlightSplitModule: React.FC<SpotlightSplitModuleProps> = ({
    media,
    content,
    theme = 'dark',
    className = '',
    button = true,
    backgroundColor = 'bg-orange',
    fadeEffect = true
}) => {
    const textColorClass = theme === 'dark' ? 'text-white' : 'text-navy-900';
    const textOpacityClass = theme === 'dark' ? 'text-white/90' : 'text-navy-900/90';
    const colorRef = useRef<HTMLDivElement>(null);
    const videoRef = useRef<HTMLVideoElement>(null);
    const placeholderRef = useRef<HTMLDivElement>(null);
    const [gradientColor, setGradientColor] = useState('transparent');
    const [isVideoVisible, setIsVideoVisible] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (colorRef.current) {
            const computedStyle = window.getComputedStyle(colorRef.current);
            setGradientColor(computedStyle.backgroundColor);
        }
    }, [backgroundColor]);

    useEffect(() => {
        // Set up Intersection Observer for lazy loading
        if (media.type === 'video') {
            const options = {
                root: null,
                rootMargin: '50px',
                threshold: 0.1
            };

            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        setIsVideoVisible(true);
                        observer.disconnect();
                    }
                });
            }, options);

            const elementToObserve = placeholderRef.current || videoRef.current;
            if (elementToObserve) {
                observer.observe(elementToObserve);
            }

            return () => observer.disconnect();
        }
    }, [media.type]);

    useEffect(() => {
        if (fadeEffect) {
            const options = {
                root: null,
                rootMargin: '0px',
                threshold: 0.1
            };

            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    setIsVisible(entry.isIntersecting);
                });
            }, options);

            const elementToObserve = colorRef.current;
            if (elementToObserve) {
                observer.observe(elementToObserve);
            }

            return () => observer.disconnect();
        }
    }, [fadeEffect]);

    const renderVideo = () => {
        if (!isVideoVisible) {
            return (
                <div
                    ref={placeholderRef}
                    className="absolute inset-0 w-full h-full bg-gray-200"
                    style={{
                        backgroundImage: media.posterUrl ? `url(${media.posterUrl})` : undefined,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                    }}
                />
            );
        }

        return (
            <video
                ref={videoRef}
                autoPlay
                muted
                loop
                playsInline
                poster={media.posterUrl}
                className="absolute inset-0 w-full h-full object-cover"
                preload="metadata"
            >
                <source src={media.url} type="video/mp4" />
            </video>
        );
    };

    return (
        <div className={`w-full ${className} ${fadeEffect ? (isVisible ? 'fade-in' : 'fade-out') : ''}`}>
            <div className="module_container">
                <section className="relative w-full overflow-hidden rounded-3xl flex flex-col lg:flex-row">
                    {/* Media Side - On top for mobile */}
                    <div className="relative w-full lg:w-1/2 min-h-[300px] lg:min-h-full order-1 lg:order-2">
                        {/* Background Media */}
                        {media.type === 'video' ? (
                            renderVideo()
                        ) : (
                            <img
                                src={media.url}
                                alt={media.alt || content.title}
                                className="absolute inset-0 w-full h-full object-cover"
                                loading="lazy"
                                decoding="async"
                            />
                        )}
                        {/* Gradient overlay - Different directions for mobile/desktop */}
                        <div
                            className="spotlight-gradient"
                            style={{
                                '--gradient-color': gradientColor
                            } as React.CSSProperties}
                        />
                    </div>

                    {/* Text Content Side - Below media on mobile */}
                    <div
                        ref={colorRef}
                        className={`relative z-10 w-full lg:w-1/2 ${backgroundColor} px-6 md:pl-10 md:pr-0 py-6 md:py-12 flex items-start justify-start order-2 lg:order-1`}
                    >
                        <div className="max-w-xl">
                            <h2 className={`text-3xl md:text-3xl font-headline mb-4 ${textColorClass}`}>
                                {content.title}
                            </h2>
                            {content.subtitle && (
                                <h3 className={`text-xl md:text-2xl font-headline mb-4 ${textOpacityClass}`}>
                                    {content.subtitle}
                                </h3>
                            )}
                            <p className={`text-lg mb-4 md:mb-6 ${textOpacityClass}`}>
                                {content.description}
                            </p>
                            {button && content.ctaLink && content.ctaText && (
                                <Button
                                    to={typeof content.ctaLink === 'function' ? content.ctaLink() : content.ctaLink}
                                    text={content.ctaText}
                                    variant={theme === 'dark' ? 'border-white' : 'border-black'}
                                />
                            )}
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default SpotlightSplitModule; 