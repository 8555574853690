import React, { useState } from 'react';
import { Globe, ChevronDown } from 'lucide-react';
import useRegionStore from '@/context/RegionStore';
import useCountryDetailsStore from '@/context/CountryDetailsStore';
import { useCurrency } from '@/hooks/useCurrency';
import RegionSelectorModal from './RegionSelectorModal';

interface RegionSelectorProps {
    variant?: 'modal' | 'header' | 'footer';
    onSelect?: (region: string) => void;
    className?: string;
}

const RegionSelector: React.FC<RegionSelectorProps> = ({ variant = 'header', onSelect, className = '' }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { chosenRegion } = useRegionStore();
    const { countryDetails } = useCountryDetailsStore();
    const { symbol } = useCurrency();

    const getButtonStyles = () => {
        switch (variant) {
            case 'footer':
                return 'flex items-center gap-2 text-gray-300 hover:text-white transition-colors bg-[#454545] rounded-lg px-4 py-2 w-full';
            case 'header':
                return 'text-black/50 hover:text-black flex items-center gap-2';
            case 'modal':
                return 'w-full p-4 bg-orange justify-center rounded-md font-headline text-lg text-white leading-none transition-all ease-kibu duration-200 cursor-pointer border-2 border-transparent hover:rounded-lg hover:brightness-105 hover:border-black/10 hover:scale-[1.02]';
            default:
                return '';
        }
    };

    return (
        <div className={`${className}`}>
            <button
                onClick={() => setIsModalOpen(true)}
                className={getButtonStyles()}
            >
                {variant === 'header' ? (
                    <>
                        <Globe className="w-5 h-5" />
                        <span className="uppercase">{chosenRegion}</span>
                        <ChevronDown className="w-4 h-4" />
                    </>
                ) : variant === 'footer' ? (
                    <>
                        <Globe className="w-5 h-5" />
                        <span className="flex-1 text-left">
                            {countryDetails ? (
                                <>
                                    {countryDetails.countryFlag} {countryDetails.countryName}
                                    ({symbol})
                                </>
                            ) : (
                                'Choose Region'
                            )}
                        </span>
                        <ChevronDown className="w-4 h-4" />
                    </>
                ) : (
                    'SELECT YOUR REGION'
                )}
            </button>

            {isModalOpen && (
                <RegionSelectorModal
                    onClose={() => setIsModalOpen(false)}
                />
            )}
        </div>
    );
};

export default RegionSelector; 