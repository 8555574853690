import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import LegalPageLayout from '../components/LegalPageLayout';
import { buildRegionUrl, getRegionFromUrl } from '../utils/regionDetection';

const DeliveryAndReturns: React.FC = () => {
    const location = useLocation();
    const currentRegion = getRegionFromUrl(location.pathname) || 'gb';

    return (
        <LegalPageLayout title="Delivery, Returns & Recycling">
            <p className="italic">Last Updated: 27th February 2025</p>
            <p className="lead mb-8">
                Welcome to Kibu's Delivery, Returns, Refunds, and Recycling information page! We believe in making your purchase as smooth, transparent, and eco-friendly as possible. Below you'll find everything you need to know about how we deliver, handle returns, process refunds, and recycle materials.
            </p>

            <h2 className="text-3xl font-headline mt-12 mb-6">Delivery</h2>

            <h3 className="text-2xl font-headline mt-8 mb-4">Production</h3>
            <p>
                We create each pair of Kibu headphones to order using 3D-printed recycled bioplastic from plant sources like corn. This on-demand production process helps us reduce waste and ensure every pair is made just for you.
            </p>
            <p className="mt-4">
                <strong>Production Time:</strong> It typically takes upto 5 working days from when you place your order for us to finish production before dispatch.
            </p>

            <h3 className="text-2xl font-headline mt-8 mb-4">Shipping Options</h3>
            <ul className="list-disc pl-6 space-y-2">
                <li><strong>Free Delivery:</strong> For orders over £50, standard delivery is free.</li>
                <li><strong>Standard Delivery:</strong> £3 for orders under £50, with an estimated 3–5 business days for delivery after production.</li>
                <li><strong>Faster Delivery:</strong> £10 for 1–2 business days delivery after production.</li>
            </ul>
            <p className="mt-4">
                We ship via trusted couriers and will provide you with any necessary tracking details. All delivery times are estimates and may be affected by factors beyond our control (e.g., courier delays, weather, strikes).
            </p>

            <h3 className="text-2xl font-headline mt-8 mb-4">UK-Only</h3>
            <p>
                We currently ship only within the UK. We do not ship internationally at this time.
            </p>

            <h3 className="text-2xl font-headline mt-8 mb-4">International Shipping</h3>
            <p>
                We now ship to the following countries: United States, France, Germany, Netherlands, and Italy.
            </p>
            <ul className="list-disc pl-6 space-y-2">
                <li><strong>United States:</strong> Free Standard Shipping for orders over $120, otherwise $19. Delivery in 4-6 business days after production.</li>
                <li><strong>France:</strong> Free UPS Standard Shipping for orders over €100, otherwise €16. Delivery in 3-8 business days after production.</li>
                <li><strong>Germany:</strong> Free UPS Standard Shipping for orders over €100, otherwise €16. Delivery in 1-5 business days after production.</li>
                <li><strong>Netherlands:</strong> Free UPS Standard Shipping for orders over €100, otherwise €16. Delivery in 1-5 business days after production.</li>
                <li><strong>Italy:</strong> Free UPS Standard Shipping for orders over €100, otherwise €18. Delivery in 2-6 business days after production.</li>
            </ul>
            <p className="mt-4">
                For European countries, we offer DDP (Delivered Duty Paid) shipping, which includes pre-paid taxes and duties. This means you won't have to pay for duties and taxes before receiving your headphones. We use UPS for this service, and the pre-paid taxes and duties are charged as a line item on your order.
            </p>
            <p className="mt-4">
                Sometimes when shipping internationally, Customs in the destination country will require you to authorise UPS to manage your package through customs. This is formally called a Request for Power of Attorney. Usually, UPS will contact you directly to resolve this delay.
            </p>

            <h2 className="text-3xl font-headline mt-12 mb-6">Returns & Refunds</h2>
            <p>
                We want you to be completely happy with your Kibu headphones. However, if you're not satisfied or have changed your mind, our returns process is designed to be straightforward.
            </p>

            <h3 className="text-2xl font-headline mt-8 mb-4">UK Returns</h3>
            <p>
                You have 30 days from the date of receiving your order to request a return or refund. This applies to all products, including most custom items, unless otherwise stated below.
            </p>

            <h4 className="text-xl font-headline mt-8 mb-4">Fees for Custom Orders (UK)</h4>
            <p>
                Because our headphones are made-to-order, we do charge small fees if you return a custom product:
            </p>
            <ul className="list-disc pl-6 space-y-2 mt-4">
                <li><strong>Custom Colour Fee:</strong> £6 deducted from your refund.</li>
                <li><strong>Engraving Fee:</strong> £3 deducted for headband engravings.</li>
            </ul>
            <p className="mt-4">
                These fees help cover the cost of materials and production time. If your product is faulty or damaged, we waive these fees.
            </p>

            <h4 className="text-xl font-headline mt-8 mb-4">How to Return (UK)</h4>
            <ol className="list-decimal pl-6 space-y-4">
                <li>
                    <strong>Contact Us:</strong> Email us at contact@kibu.family with your order details and reason for return. Please include proof of purchase (e.g., order confirmation email).
                </li>
                <li>
                    <strong>Ship the Item(s):</strong> Once we confirm your return request, send your item(s) back to:<br />
                    <address className="mt-2 ml-4 not-italic">
                        Kibu<br />
                        1a Dunn Street<br />
                        London, E8 2DG
                    </address>
                    <p className="mt-2">We recommend using a tracked shipping method; you're responsible for covering the return shipping cost unless the item is faulty.</p>
                </li>
                <li>
                    <strong>Refund Process:</strong> After we inspect the returned items, we'll process your refund. It can take up to 10 business days for funds to appear, depending on your bank.
                </li>
            </ol>

            <h3 className="text-2xl font-headline mt-8 mb-4">International Returns & Exchanges</h3>
            <p>
                Unfortunately, we cannot offer returns or exchanges on orders shipped outside of the UK. Please contact our support team at help@kibu.family for assistance.
            </p>

            <h3 className="text-2xl font-headline mt-8 mb-4">Faulty or Damaged Items</h3>
            <p>
                If your Kibu headphones arrive faulty or damaged, please let us know within 30 days. We'll either provide a replacement or a full refund. In the case of faults or errors on our end, we cover the return postage.
            </p>

            <h3 className="text-2xl font-headline mt-8 mb-4">Exchanges</h3>
            <p>
                We currently do not offer direct exchanges. If you'd like a different product or color, simply follow the return process above, and then place a new order once your refund is processed.
            </p>

            <h3 className="text-2xl font-headline mt-8 mb-4">Shipments to Forwarding Companies</h3>
            <p>
                Please note that if you choose to ship your order to a package forwarding company, Kibu will not be liable for any damage, defect, or loss that occurs. We will not be able to offer replacements or refunds for items that are reported missing or damaged if we have reason to believe they were delivered to one of these locations. Thank you for your understanding.
            </p>

            <h2 className="text-3xl font-headline mt-12 mb-6">Recycling & Sustainability</h2>

            <h3 className="text-2xl font-headline mt-8 mb-4">Materials</h3>
            <p>
                Kibu headphones are made with recycled bioplastic derived from plants like corn, ensuring a more sustainable and eco-friendly product. Our 3D printing process allows us to produce on demand, reducing waste and overstock.
            </p>

            <h3 className="text-2xl font-headline mt-8 mb-4">Packaging</h3>
            <p>
                All of our packaging is fully recyclable—made from cardboard and other easily recyclable materials. We encourage you to dispose of it responsibly in your local recycling bin.
            </p>

            <h3 className="text-2xl font-headline mt-8 mb-4">Take-Back Program</h3>
            <p>
                If your Kibu headphones reach the end of their life, we encourage you to reach out to us at contact@kibu.family. We will accept the headphones back for proper recycling. Alternatively, please follow local guidelines for electronic waste to ensure the materials are disposed of or recycled correctly.
            </p>

            <h2 className="text-3xl font-headline mt-12 mb-6">Questions?</h2>
            <p>
                If you have any questions about delivery, returns, refunds, or our recycling efforts, feel free to email us at contact@kibu.family. We're here to help!
            </p>

            <p className="mt-8">
                For full details of our Terms & Conditions, including limitations and legal disclaimers, please see our{' '}
                <Link to={buildRegionUrl(currentRegion, 'legal/terms-of-service')} className="text-primary hover:underline">Terms of Service</Link>.
                You can also find more information on how we handle your data in our{' '}
                <Link to={buildRegionUrl(currentRegion, 'legal/privacy-policy')} className="text-primary hover:underline">Privacy Policy</Link>.
            </p>

            <p className="mt-8">
                Thank you for choosing Kibu—we appreciate your support in making children's headphones that are kinder to the planet!
            </p>
        </LegalPageLayout>
    );
};

export default DeliveryAndReturns; 