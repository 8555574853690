import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { getAllPosts, getAllCategories, BlogPostMetadata } from './content/metadata';
import './blog.css';

const BlogHome: React.FC = () => {
    const [posts, setPosts] = useState<BlogPostMetadata[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<string>('all');
    const [categories, setCategories] = useState<string[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Load posts and categories
        const allPosts = getAllPosts();
        const allCategories = getAllCategories();
        setPosts(allPosts);
        setCategories(allCategories);
        setLoading(false);
    }, []);

    const filteredPosts = selectedCategory === 'all'
        ? posts
        : posts.filter(post => post.category.toLowerCase() === selectedCategory.toLowerCase());

    if (loading) {
        return <div className="min-h-screen bg-background flex items-center justify-center">Loading...</div>;
    }

    return (
        <>
            <Helmet>
                <title>The Kibu Journal | Children's Headphones, Sustainability & Audio Education</title>
                <meta name="description" content="The official blog of the world's first kids headphones that are buildable, sustainable and fun. Thoughts, news, innovations, and educational insights." />
                <meta name="keywords" content="children's headphones, kids headphones, sustainable headphones, volume-limited headphones, DIY electronics" />
                <meta property="og:title" content="The Kibu Journal | Children's Headphones, Sustainability & Audio Education" />
                <meta property="og:description" content="The official blog of Kibu - makers of buildable, sustainable headphones for children. Thoughts, innovations, and educational insights." />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>

            <div className="min-h-screen">
                {/* Hero Section */}
                <div className="flex flex-col items-center py-28">
                    <div className="flex flex-col items-center container px-4">
                        <h1 className="text-6xl md:text-8xl font-bold text-primary text-center">
                            The Kibu
                            <br />
                            Journal
                        </h1>
                        <p className="text-lg text-black max-w-md mt-12 text-center">
                            The official blog of Kibu - makers of buildable, sustainable headphones for children. Thoughts 💭, innovations 🌿 and educational insights.
                        </p>
                    </div>
                </div>

                {/* Blog Posts Grid - Wild Style */}
                <div className="container mx-auto px-4 py-8">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                        {filteredPosts.map(post => (
                            <div key={post.id} className="flex flex-col blog-post-item">
                                <Link to={`/blog/${post.slug}`} className="block">
                                    <div className="w-full aspect-[4/5] mb-4 overflow-hidden rounded-3xl blog-image-container">
                                        <img
                                            src={post.coverImage}
                                            alt={post.title}
                                            className="blog-image-hover"
                                        />
                                    </div>
                                </Link>
                                <Link to={`/blog/${post.slug}`} className="block">
                                    <h2 className="text-xl font-bold mb-2 transition-colors">
                                        {post.title}
                                    </h2>
                                </Link>
                                <p className="text-gray-700 text-sm mb-4">
                                    {post.excerpt}
                                </p>
                                <div className="mt-auto">
                                    <Link
                                        to={`/blog?category=${post.category.toLowerCase()}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setSelectedCategory(post.category.toLowerCase());
                                        }}
                                        className="text-xs bg-white border-[1px] border-gray-500 font-medium text-gray-500 hover:text-primary bg-gray-100 px-2 py-1 rounded-full"
                                    >
                                        {post.category}
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default BlogHome;