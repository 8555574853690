import React, { useState, useEffect, useCallback } from 'react';
import Nav from './nav/Nav';

interface LegalPageLayoutProps {
    title: string;
    children: React.ReactNode;
}

const LegalPageLayout: React.FC<LegalPageLayoutProps> = ({ title, children }) => {
    const [isCompact, setIsCompact] = useState(false);
    const [lastScrollY, setLastScrollY] = useState(0);

    const handleScroll = useCallback(() => {
        const currentScrollY = window.scrollY;

        // Start compacting after scrolling past the initial view
        if (currentScrollY > 100) {
            setIsCompact(true);
        } else {
            setIsCompact(false);
        }

        setLastScrollY(currentScrollY);
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    return (
        <div className="min-h-screen bg-background">
            {/* Fixed Nav */}
            {/* <div className="fixed top-0 left-0 right-0 z-50">
                <Nav />
            </div> */}

            {/* Content wrapper with padding for fixed nav */}
            <div className="relative">
                {/* Sticky Title */}
                <div
                    className={`sticky top-0 z-40 bg-background border-b border-gray-200 transition-all duration-300 ease-out
                        ${isCompact ? 'py-4' : 'py-8'}`}
                >
                    <div className="standard_container mx-auto px-6">
                        <h1
                            className={`font-headline transition-all duration-300 ease-out
                                ${isCompact ? 'text-2xl md:text-3xl' : 'text-4xl md:text-5xl'}`}
                        >
                            {title}
                        </h1>
                    </div>
                </div>

                {/* Content */}
                <div className="standard_container mx-auto px-6 py-8">
                    <div className="prose prose-lg max-w-none">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LegalPageLayout; 