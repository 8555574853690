import { IProduct } from '@/context/ProductDataStore'
import { IBasketItem } from '@/context/BasketStore'

declare global {
    interface Window {
        fbq?: (...args: any[]) => void
        gtag?: (...args: any[]) => void
    }
}

interface TrackingProduct {
    id: string
    name: string
    variant?: string
    price: number
    category?: string
    quantity?: number
}

// Helper function to format products for Meta Pixel
const formatProductForPixel = (item: IBasketItem | IProduct, quantity: number = 1): TrackingProduct => {
    console.log('pixel item', item)
    if ('colourWay' in item) {

        // IBasketItem
        const formattedProduct = {
            id: item.colourWay._id,
            name: item.colourWay.productTitle,
            variant: item.colourWay.optionTitle,
            price: item.price,
            quantity
        }
        console.log('[Meta Pixel] Formatting basket item:', formattedProduct)
        return formattedProduct
    } else {
        // IProduct
        const price = item.price?.unit_amount ||
            item.availableOptions?.[0]?.options?.[0]?.price?.unit_amount ||
            0
        const formattedProduct = {
            id: item._id,
            name: item.productTitle,
            variant: item.optionTitle,
            price,
            quantity
        }
        console.log('[Meta Pixel] Formatting product:', formattedProduct)
        return formattedProduct
    }
}

// Helper function to format products for GA4
const formatProductForGA = (item: IBasketItem | IProduct, quantity: number = 1): any => {
    if ('colourWay' in item) {
        // IBasketItem
        const formattedProduct = {
            item_id: item.colourWay._id,
            item_name: item.colourWay.productTitle,
            item_variant: item.colourWay.optionTitle,
            price: item.price,
            quantity,
            currency: 'GBP'
        }
        console.log('[Google Analytics] Formatting basket item:', formattedProduct)
        return formattedProduct
    } else {
        // IProduct
        const price = item.price?.unit_amount ||
            item.availableOptions?.[0]?.options?.[0]?.price?.unit_amount ||
            0
        const formattedProduct = {
            item_id: item._id,
            item_name: item.productTitle,
            item_variant: item.optionTitle,
            price,
            quantity,
            currency: 'GBP'
        }
        console.log('[Google Analytics] Formatting product:', formattedProduct)
        return formattedProduct
    }
}

// ViewContent - Track when users view product details
export const trackViewContent = (product: IProduct, region: string | null, price?: number) => {
    // Meta Pixel tracking
    if (!window.fbq) {
        console.warn('[Meta Pixel] ViewContent event not sent - fbq not initialized')
    } else {
        const formattedProduct = formatProductForPixel(product)
        console.log('formattedProduct', formattedProduct)
        const eventData: any = {
            content_ids: [formattedProduct.id],
            content_name: formattedProduct.name,
            content_type: 'product',
            value: price ?? formattedProduct.price,
            currency: 'GBP'
        }
        if (region) {
            eventData.country = region
        }
        console.log('[Meta Pixel] Tracking ViewContent:', eventData)
        window.fbq('track', 'ViewContent', eventData)
    }

    // Google Analytics tracking
    if (!window.gtag) {
        console.warn('[Google Analytics] ViewContent event not sent - gtag not initialized')
    } else {
        const formattedProduct = formatProductForGA(product)
        const eventData = {
            currency: 'GBP',
            value: price ?? formattedProduct.price,
            items: [formattedProduct]
        }
        console.log('[Google Analytics] Tracking view_item:', eventData)
        window.gtag('event', 'view_item', eventData)
    }
}

// AddToCart - Track when items are added to basket
export const trackAddToCart = (item: IBasketItem, region: string | null) => {
    // Meta Pixel tracking
    if (!window.fbq) {
        console.warn('[Meta Pixel] AddToCart event not sent - fbq not initialized')
    } else {
        const formattedProduct = formatProductForPixel(item)
        const contents = [
            formattedProduct,
            ...item.extras.map(extra => formatProductForPixel(extra))
        ]
        const eventData: any = {
            content_ids: contents.map(p => p.id),
            content_name: formattedProduct.name,
            content_type: 'product',
            contents,
            value: item.price,
            currency: 'GBP'
        }
        if (region) {
            eventData.country = region
        }
        console.log('[Meta Pixel] Tracking AddToCart:', eventData)
        window.fbq('track', 'AddToCart', eventData)
    }

    // Google Analytics tracking
    if (!window.gtag) {
        console.warn('[Google Analytics] AddToCart event not sent - gtag not initialized')
    } else {
        const formattedProduct = formatProductForGA(item)
        const items = [
            formattedProduct,
            ...item.extras.map(extra => formatProductForGA(extra))
        ]
        const eventData = {
            currency: 'GBP',
            value: item.price,
            items
        }
        console.log('[Google Analytics] Tracking add_to_cart:', eventData)
        window.gtag('event', 'add_to_cart', eventData)
    }
}

// InitiateCheckout - Track when checkout begins
export const trackInitiateCheckout = (items: IBasketItem[], totalValue: number, region: string | null) => {
    // Meta Pixel tracking
    if (!window.fbq) {
        console.warn('[Meta Pixel] InitiateCheckout event not sent - fbq not initialized')
    } else {
        const contents = items.flatMap(item => [
            formatProductForPixel(item),
            ...item.extras.map(extra => formatProductForPixel(extra))
        ])
        const eventData: any = {
            content_ids: contents.map(p => p.id),
            contents,
            num_items: contents.length,
            value: totalValue,
            currency: 'GBP'
        }
        if (region) {
            eventData.country = region
        }
        console.log('[Meta Pixel] Tracking InitiateCheckout:', eventData)
        window.fbq('track', 'InitiateCheckout', eventData)
    }

    // Google Analytics tracking
    if (!window.gtag) {
        console.warn('[Google Analytics] InitiateCheckout event not sent - gtag not initialized')
    } else {
        const gaItems = items.flatMap(item => [
            formatProductForGA(item),
            ...item.extras.map(extra => formatProductForGA(extra))
        ])
        const eventData = {
            currency: 'GBP',
            value: totalValue,
            items: gaItems
        }
        console.log('[Google Analytics] Tracking begin_checkout:', eventData)
        window.gtag('event', 'begin_checkout', eventData)
    }
}

// Purchase - Track completed purchases
export const trackPurchase = (items: IBasketItem[], totalValue: number, region: string | null) => {
    // Meta Pixel tracking
    if (!window.fbq) {
        console.warn('[Meta Pixel] Purchase event not sent - fbq not initialized')
    } else {
        const contents = items.flatMap(item => [
            formatProductForPixel(item),
            ...item.extras.map(extra => formatProductForPixel(extra))
        ])
        const eventData: any = {
            content_ids: contents.map(p => p.id),
            contents,
            num_items: contents.length,
            value: totalValue,
            currency: 'GBP'
        }
        if (region) {
            eventData.country = region
        }
        console.log('[Meta Pixel] Tracking Purchase:', eventData)
        window.fbq('track', 'Purchase', eventData)
    }

    // Google Analytics tracking
    if (!window.gtag) {
        console.warn('[Google Analytics] Purchase event not sent - gtag not initialized')
    } else {
        const gaItems = items.flatMap(item => [
            formatProductForGA(item),
            ...item.extras.map(extra => formatProductForGA(extra))
        ])
        const eventData = {
            transaction_id: Date.now().toString(), // You might want to pass a real order ID here
            currency: 'GBP',
            value: totalValue,
            items: gaItems
        }
        console.log('[Google Analytics] Tracking purchase:', eventData)
        window.gtag('event', 'purchase', eventData)
    }
}

// Custom event - Track when users start customizing their headphones
export const trackStartCustomization = (baseProduct: IProduct, region: string | null) => {
    // Meta Pixel tracking
    if (!window.fbq) {
        console.warn('[Meta Pixel] StartCustomization event not sent - fbq not initialized')
    } else {
        const formattedProduct = formatProductForPixel(baseProduct)
        const eventData: any = {
            content_id: formattedProduct.id,
            content_name: formattedProduct.name,
            value: formattedProduct.price,
            currency: 'GBP'
        }
        if (region) {
            eventData.country = region
        }
        console.log('[Meta Pixel] Tracking StartCustomization:', eventData)
        window.fbq('trackCustom', 'StartCustomization', eventData)
    }

    // Google Analytics tracking
    if (!window.gtag) {
        console.warn('[Google Analytics] StartCustomization event not sent - gtag not initialized')
    } else {
        const formattedProduct = formatProductForGA(baseProduct)
        const eventData = {
            currency: 'GBP',
            value: formattedProduct.price,
            items: [formattedProduct]
        }
        console.log('[Google Analytics] Tracking start_customization:', eventData)
        window.gtag('event', 'start_customization', eventData)
    }
}

// Custom event - Track when users complete customization
export const trackCompleteCustomization = (customizedProduct: IProduct, selectedComponents: any[], region: string | null) => {
    // Meta Pixel tracking
    if (!window.fbq) {
        console.warn('[Meta Pixel] CompleteCustomization event not sent - fbq not initialized')
    } else {
        const formattedProduct = formatProductForPixel(customizedProduct)
        const eventData: any = {
            content_id: formattedProduct.id,
            content_name: formattedProduct.name,
            value: formattedProduct.price,
            components: selectedComponents,
            currency: 'GBP'
        }
        if (region) {
            eventData.country = region
        }
        console.log('[Meta Pixel] Tracking CompleteCustomization:', eventData)
        window.fbq('trackCustom', 'CompleteCustomization', eventData)
    }

    // Google Analytics tracking
    if (!window.gtag) {
        console.warn('[Google Analytics] CompleteCustomization event not sent - gtag not initialized')
    } else {
        const formattedProduct = formatProductForGA(customizedProduct)
        const eventData = {
            currency: 'GBP',
            value: formattedProduct.price,
            items: [formattedProduct],
            components: selectedComponents
        }
        console.log('[Google Analytics] Tracking complete_customization:', eventData)
        window.gtag('event', 'complete_customization', eventData)
    }
}

// Track payment failures
export const trackPaymentFailed = (items: IBasketItem[], totalValue: number, region: string | null) => {
    // Meta Pixel tracking
    if (!window.fbq) {
        console.warn('[Meta Pixel] PaymentFailed event not sent - fbq not initialized')
    } else {
        const contents = items.flatMap(item => [
            formatProductForPixel(item),
            ...item.extras.map(extra => formatProductForPixel(extra))
        ])
        const eventData: any = {
            content_ids: contents.map(p => p.id),
            contents,
            num_items: contents.length,
            value: totalValue,
            currency: 'GBP'
        }
        if (region) {
            eventData.country = region
        }
        console.log('[Meta Pixel] Tracking PaymentFailed:', eventData)
        window.fbq('trackCustom', 'PaymentFailed', eventData)
    }

    // Google Analytics tracking
    if (!window.gtag) {
        console.warn('[Google Analytics] PaymentFailed event not sent - gtag not initialized')
    } else {
        const gaItems = items.flatMap(item => [
            formatProductForGA(item),
            ...item.extras.map(extra => formatProductForGA(extra))
        ])
        const eventData = {
            currency: 'GBP',
            value: totalValue,
            items: gaItems,
            payment_type: 'stripe'
        }
        console.log('[Google Analytics] Tracking payment_failed:', eventData)
        window.gtag('event', 'payment_failed', eventData)
    }
} 