import React from 'react'
import Skeleton from './Skeleton'

const AddonSkeleton: React.FC = () => {
    return (
        <div className="addon flex flex-col gap-1 mt-5 mb-10">
            <div className="addon-meta flex flex-col gap-1">
                <div className="flex flex-row gap-4 justify-between items-center">
                    <Skeleton className="h-6 w-48" />
                    <Skeleton className="h-5 w-5 rounded-full" />
                </div>
            </div>

            <div className="flex relative -left-4 pl-4 pt-4 items-start overflow-x-scroll no-scrollbar gap-1">
                {[1, 2, 3, 4].map((_, index) => (
                    <div key={index} className="item flex flex-col gap-3 m-1">
                        <Skeleton className="w-[120px] aspect-[4/4]" />
                        <div className="item-info flex flex-col leading-none relative left-1 gap-1">
                            <Skeleton className="h-4 w-20" />
                            <Skeleton className="h-4 w-12" />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default AddonSkeleton 