import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useRegionStore from '@/context/RegionStore';
import useAvailableRegionsStore from '@/context/AvailableRegionsStore';

const NotFound: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { chosenRegion, detectedRegion } = useRegionStore();
    const { isRegionAvailable } = useAvailableRegionsStore();
    const currentRegion = chosenRegion || detectedRegion || 'gb';

    // Handle direct navigation to invalid paths
    useEffect(() => {
        const pathSegments = location.pathname.split('/').filter(Boolean);
        const firstSegment = pathSegments[0]?.toLowerCase();

        // If the path doesn't start with a valid region, redirect to the 404 page with the correct region
        if (firstSegment && firstSegment.length === 2 && !isRegionAvailable(firstSegment)) {
            navigate(`/${currentRegion}/404`, { replace: true });
        }
    }, [location.pathname, currentRegion, navigate, isRegionAvailable]);

    const handleGoHome = () => {
        navigate(`/${currentRegion}`, { replace: true });
    };

    return (
        <div className="min-h-[70vh] flex items-center justify-center px-4">
            <div className="text-center">
                <h1 className="text-9xl font-bold text-gray-200">404</h1>
                <div className="mt-4">
                    <h2 className="text-3xl font-semibold text-gray-900">Page Not Found</h2>
                    <p className="mt-2 text-lg text-gray-600">
                        Sorry, we couldn't find the page you're looking for.
                    </p>
                </div>
                <div className="mt-8">
                    <button
                        onClick={handleGoHome}
                        className="px-6 py-3 text-white bg-black rounded-full hover:bg-gray-800 transition-colors"
                    >
                        Go to Homepage
                    </button>
                </div>
            </div>
        </div>
    );
};

export default NotFound; 