import React, { useEffect } from 'react'
import { X } from 'lucide-react'
import useRegionStore from '@/context/RegionStore'
import useCountryDetailsStore from '@/context/CountryDetailsStore'
import { useNavigate, useLocation } from 'react-router-dom'
import RegionSelector from '@/components/RegionSelector'
import useAvailableRegionsStore from '@/context/AvailableRegionsStore'
import useBasketStore from '@/context/BasketStore'
import { isGlobalRoute } from '@/utils/regionDetection'

const RegionModal: React.FC = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { chosenRegion, detectedRegion, urlRegion, showRegionModal, setShowRegionModal, setChosenRegion, getUrlRegion, setAcceptedRegion } = useRegionStore()
    const { countryDetails, fetchDetails, isRegionSupported, getRegionInfo } = useCountryDetailsStore()
    const { availableRegions, isRegionAvailable } = useAvailableRegionsStore()
    const { clearBasket } = useBasketStore()

    useEffect(() => {
        if (detectedRegion) {
            fetchDetails(detectedRegion)
        }
    }, [detectedRegion, fetchDetails])

    useEffect(() => {
        if (showRegionModal) {
            console.log('[regionModal] Displayed', {
                chosenRegion,
                detectedRegion,
                urlRegion,
                isChosenRegionSupported: chosenRegion ? isRegionSupported(chosenRegion) : null,
                timestamp: new Date().toISOString()
            });
        }
    }, [showRegionModal, chosenRegion, detectedRegion, urlRegion, isRegionSupported]);

    const handleRegionChange = (region: string) => {
        console.log('[regionModal] Region Changed:', {
            from: chosenRegion,
            to: region,
            timestamp: new Date().toISOString()
        });
        if (region !== chosenRegion) {
            clearBasket();
        }
        setShowRegionModal(false);
        setChosenRegion(region);
        setAcceptedRegion(region);
        navigate(`/${region}`, { replace: true });
    };

    const handleContinueWithCurrent = () => {
        if (urlRegion) {
            console.log('[regionModal] Continuing with current region:', {
                from: chosenRegion,
                to: urlRegion,
                timestamp: new Date().toISOString()
            });
            if (urlRegion !== chosenRegion) {
                clearBasket();
            }
            setShowRegionModal(false);
            setChosenRegion(urlRegion);
            setAcceptedRegion(urlRegion);
            navigate(`/${urlRegion}`, { replace: true });
        }
    };

    // Don't show modal on global routes
    if (isGlobalRoute(location.pathname)) {
        return null;
    }

    if (!showRegionModal || !chosenRegion) {
        return null;
    }

    const isChosenRegionSupported = isRegionSupported(chosenRegion)
    const isUrlRegionSupported = isRegionSupported(urlRegion)
    const regionsLoaded = availableRegions.length > 0
    const detectedCountryInfo = getRegionInfo(detectedRegion)
    const urlCountryInfo = urlRegion ? getRegionInfo(urlRegion) : null

    // Only show this modal if both regions are available and they're different
    if (!detectedRegion || !urlRegion || !isRegionAvailable(detectedRegion) || !isRegionAvailable(urlRegion) || urlRegion === detectedRegion) {
        return null;
    }

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/10 backdrop-blur-sm">
            <div className="relative flex flex-col bg-white p-8 rounded-2xl m-4 shadow-2xl w-full max-w-[500px]">
                <button
                    onClick={() => setShowRegionModal(false)}
                    className="absolute top-4 right-4 bg-orange rounded-full p-2"
                >
                    <X className="h-4 w-4 text-white" strokeWidth={3} />
                </button>

                <h2 className="font-headline text-2xl mb-4">
                    {isChosenRegionSupported ? (
                        `Visiting from ${detectedCountryInfo?.name || (detectedRegion || chosenRegion).toUpperCase()}? ${detectedCountryInfo?.flag}`
                    ) : (
                        `We don't currently ship to ${detectedCountryInfo?.name || (detectedRegion || chosenRegion).toUpperCase()} ${detectedCountryInfo?.flag}`
                    )}
                </h2>

                <p className="mb-6">
                    {!regionsLoaded ? (
                        "Loading available regions..."
                    ) : isChosenRegionSupported ? (
                        `Visit your local store or continue to ${urlRegion?.toUpperCase()}?`
                    ) : (
                        "Please select a region where we currently ship to:"
                    )}
                </p>

                <div className="flex flex-row gap-3">
                    {!regionsLoaded ? (
                        <div className="flex items-center justify-center w-full p-4 bg-gray-100 rounded-md">
                            Loading...
                        </div>
                    ) : isChosenRegionSupported ? (
                        <div className="flex-1">
                            <button
                                onClick={() => detectedRegion && handleRegionChange(detectedRegion)}
                                className="flex flex-col items-center w-full p-4 bg-orange justify-center rounded-md font-headline text-white leading-none
                                transition-all ease-kibu duration-200 cursor-pointer border-2 border-transparent
                                hover:rounded-lg hover:brightness-105 hover:border-black/10 hover:scale-[1.02]"
                            >
                                <span className="text-sm mb-1 opacity-75">Your Local Store</span>
                                <span className="text-lg">{detectedCountryInfo?.flag} {(detectedRegion || chosenRegion).toUpperCase()}</span>
                            </button>
                        </div>
                    ) : (
                        <RegionSelector
                            variant="modal"
                            onSelect={handleRegionChange}
                            className="flex w-full"
                        />
                    )}

                    {urlRegion && isChosenRegionSupported && (
                        isUrlRegionSupported ? (
                            <div className="flex-1">
                                <button
                                    onClick={handleContinueWithCurrent}
                                    className="flex flex-col items-center w-full p-4 justify-center rounded-md font-headline text-gray-700 leading-none
                                        transition-all ease-kibu duration-200 cursor-pointer border-2 border-black/50
                                         hover:scale-[1.02] hover:rounded-lg hover:brightness-105 hover:border-black hover:scale-[1.02]"
                                >
                                    <span className="text-sm mb-1 opacity-75">Continue Browsing</span>
                                    <span className="text-lg">{urlCountryInfo?.flag} {urlRegion.toUpperCase()}</span>
                                </button>
                            </div>
                        ) : null
                    )}
                </div>
            </div>
        </div>
    )
}

export default RegionModal 