import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface IInfoPanel {
    isOpen: boolean
    currentTitle: string
    currentText: string
    setInfoPanelOpen: (isOpen: boolean) => void
    showInfo: (title: string, text: string) => void
}

const useInfoPanelStore = create<IInfoPanel>()(
    persist(
        (set) => ({
            isOpen: false,
            currentTitle: '',
            currentText: '',
            setInfoPanelOpen: (isOpen) => set({ isOpen }),
            showInfo: (title: string, text: string) => set({
                isOpen: true,
                currentTitle: title,
                currentText: text
            }),
        }),
        {
            name: 'info-panel-storage',
        }
    )
)

export default useInfoPanelStore 