import React, { lazy, Suspense } from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';
import useRegionStore from '../context/RegionStore';
import Button from '@/components/Button';
import { publicationReviewStats, publicationReviews } from '../modules/reviews/data/publicationReviews';
import { sustainabilitySpotlight, buildSpotlight, awardWinning } from '../modules/spotlights/data/spotlightExamples';
import { photosWithText, photosWithoutText } from '@/modules/photos/data/photoExamples';
import { kibuDrawers } from '@/modules/drawers/data/drawerExamples';
import { galleryImages } from '@/modules/scrolling-gallery/data/galleryExamples';

// Lazy load non-critical components
const ImageSlideshow = lazy(() => import('../components/ImageSlideshow'));
const ProductDetailsCard = lazy(() => import('../components/ProductDetailsCard'));
const ReviewsModule = lazy(() => import('../modules/reviews/components/ReviewsModule'));
const SpotlightsModule = lazy(() => import('../modules/spotlights/components/SpotlightModule'));
const SpotlightSplitModule = lazy(() => import('@/modules/spotlights/components/SpotlightSplitModule'));
const PhotoGridModule = lazy(() => import('@/modules/photos/components/PhotoGridModule'));
const ProductCollectionsModule = lazy(() => import('@/modules/collections/components/ProductCollectionsModule'));
const ComparisonModule = lazy(() => import('@/modules/comparisons/components/ComparisonModule'));
const DrawerModule = lazy(() => import('@/modules/drawers/components/DrawerModule'));
const ScrollingGallery = lazy(() => import('@/modules/scrolling-gallery/components/ScrollingGallery'));
const BlogModule = lazy(() => import('@/modules/blog/components/BlogModule'));

const Home: React.FC = () => {
    const { chosenRegion } = useRegionStore();
    const region = chosenRegion || 'gb';

    const heroImages = [
        {
            url: '/assets/images/product_images/hero_main.webp',
            backgroundUrl: '/assets/images/product_images/hero_background.webp',
            alt: 'Kibu Headphones Hero Image',
            showThumbnail: false
        }
    ];

    return (
        <div className="flex flex-col min-h-screen">
            {/* Hero Section - Critical for LCP */}
            <section className="relative w-full min-h-[107svh] flex items-center justify-center overflow-hidden rounded-b-large hero-section">
                {/* Content - Moved above video to prioritize rendering */}
                <div className="standard_container z-10 absolute bottom-48 flex flex-col md:flex-row gap-8 items-center hero-content">
                    <div className="flex flex-col gap-0 md:gap-4 md:w-2/3">
                        <h1 className="text-5xl md:text-6xl font-headline text-white hero-title">
                            Build. Learn. <br /> Listen. Love.
                        </h1>
                        {/* Add display-block to ensure immediate paint */}
                        <p className="text-lg text-white/90 display-block font-system hero-text leading-tight md:leading-normal">
                            Join the headphone adventure where children build their own sustainable headphones,<span className="hidden-on-mobile"> learning valuable skills while protecting their ears and our planet.</span>
                        </p>
                        <div className="flex gap-4 mt-0 md:mt-0">
                            <Button
                                to={`/${region}/products/wired-headphones-1`}
                                text="Shop Now"
                                variant="solid-orange"
                            />
                        </div>
                    </div>
                </div>

                {/* Background Video - Lazy loaded */}
                <div className="absolute w-full h-[125svh] bottom-0 md:h-full">
                    {/* Simplified hero image for faster rendering */}
                    <img
                        src="https://d1tg5gdu842vo5.cloudfront.net/site-assets/images/headphones-in-context/boy_wearing_forest_green_wide.jpg"
                        alt="Kibu Headphones Hero Image"
                        fetchPriority="high"
                        width={1920}
                        height={1080}
                        className="absolute inset-0 w-full h-full object-cover hero-image"
                        id="hero-image"
                    />
                    {/* <video
                        autoPlay
                        muted
                        loop
                        playsInline
                        className="absolute inset-0 w-full h-full object-cover opacity-0 transition-opacity duration-300"
                        onLoadedData={(e) => {
                            // Once video is loaded, fade it in
                            (e.target as HTMLVideoElement).classList.remove('opacity-0');
                        }}
                    >
                        <source src="https://d1tg5gdu842vo5.cloudfront.net/site-assets/hero-video-iCJ92Jd.mp4" type="video/mp4" />
                    </video> */}
                    {/* Overlay - Simplified gradient for better performance */}
                    <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent hero-overlay z-[2]"></div>
                </div>
            </section>

            {/* Lazy load all non-critical content */}
            <Suspense fallback={<div className="h-20"></div>}>
                {/* Reviews Section */}
                <ReviewsModule
                    onViewAllClick={() => {
                        console.log('View all reviews clicked');
                    }}
                    className="mt-12"
                />

                <SpotlightSplitModule
                    media={buildSpotlight.media}
                    content={buildSpotlight.content}
                    backgroundColor="bg-blue"
                />

                <ProductCollectionsModule
                    collectionCode="F39Jxkd4q"
                    className=""
                    showSubtitle={false}
                />

                <SpotlightSplitModule
                    media={awardWinning.media}
                    content={awardWinning.content}
                    backgroundColor="bg-specialYellow"
                    theme="light"
                />

                <ScrollingGallery
                    images={galleryImages}
                    communityContent={{
                        title: {
                            prefix: "Kibu is",
                            suffix: "about you"
                        },
                        description: "Share your #KibuHeadphones and tag @kibu.family for a chance to be featured!",
                        ctaText: "Instagram",
                        ctaLink: "https://www.instagram.com/kibu.family/"
                    }}
                    className="bg-white"
                />

                <DrawerModule
                    moduleTitle="Headphones Kids Build, Repair, and Love"
                    items={kibuDrawers}
                    defaultMedia={{
                        type: 'video',
                        url: 'https://d1tg5gdu842vo5.cloudfront.net/site-assets/video/build_clips.mp4',
                        posterUrl: 'https://d1tg5gdu842vo5.cloudfront.net/site-assets/images/placeholders/build_clips_placeholder.jpg',
                        alt: 'Baking overview'
                    }}
                    className="my-16"
                />

                <ComparisonModule
                    title="Why Choose Kibu?"
                    subtitle="See how Kibu headphones compare to standard children's headphones"
                />

                <SpotlightsModule
                    media={sustainabilitySpotlight.media}
                    content={sustainabilitySpotlight.content}
                    theme="dark"
                />

                {/* Blog Module */}
                <BlogModule
                    className="my-16"
                    showExcerpt={true}
                    showCategory={true}
                    maxPosts={6}
                />
            </Suspense>
        </div>
    );
};

export default Home; 