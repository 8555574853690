import React, { useEffect, useState, useCallback, useRef } from 'react'
import { BrowserRouter, Routes, Route, useLocation, useNavigate, Navigate } from 'react-router-dom'
import './App.css'
import Basket from './components/Basket'
import InfoScreen from './components/InfoScreen'
import Footer from './components/Footer'
import CookieBanner from './components/CookieBanner'
import ScrollToTop, { ScrollContainerContext } from './components/ScrollToTop'
import Nav from './components/nav/Nav'
import useCookieConsentStore from './context/CookieConsentStore'
import useRegionStore from './context/RegionStore'
import useCountryDetailsStore from './context/CountryDetailsStore'
import { initializeAnalytics, updateAnalyticsConsent, removeTrackingScripts } from './utils/cookieConsent'
import { isGlobalRoute } from './utils/regionDetection'
import { fetchViewerCountry } from './services/api'
import Legal from './pages/Legal'
import PrivacyPolicy from './pages/PrivacyPolicy'
import KibuCareTerms from './pages/KibuCareTerms'
import TermsOfService from './pages/TermsOfService'
import DeliveryAndReturns from './pages/DeliveryAndReturns'
import RegionModal from './components/RegionModal'
import UnsupportedRegionModal from './components/UnsupportedRegionModal'
import RegionSelectorModal from './components/RegionSelectorModal'
import useAvailableRegionsStore from './context/AvailableRegionsStore'
import RegionWrapper from './components/RegionWrapper'
import BackToTop from './components/BackToTop'
import CustomColoursContainer from './components/CustomColoursContainer'
import useCustomColoursStore from './context/CustomColoursStore'
import Blog from './pages/blog/BlogHome'
import BlogPost from './pages/blog/BlogPost'

// Inner component that handles all routing logic
const AppContent = () => {
	const [fullscreenOverlayActive, setFullscreenOverlayActive] = useState<boolean>(false)
	const [hideOverflowActive, setHideOverflowActive] = useState<boolean>(false)
	const { consent } = useCookieConsentStore()
	const [navHeight, setNavHeight] = useState(70)
	const [isNavVisible, setIsNavVisible] = useState(true)
	const [lastScrollY, setLastScrollY] = useState(0)
	const [navVisibilityOverride, setNavVisibilityOverride] = useState<boolean | null>(null)
	const { fetchAllCountries } = useCountryDetailsStore()
	const contentRef = useRef<HTMLDivElement>(null)
	const [isRegionModalOpen, setIsRegionModalOpen] = useState(false)
	const location = useLocation()
	const { urlRegion, showRegionModal, chosenRegion, detectedRegion, acceptedRegion, getUrlRegion, setShowRegionModal, setDetectedRegion, setChosenRegion } = useRegionStore()
	const { isRegionAvailable } = useAvailableRegionsStore()
	const [isDetecting, setIsDetecting] = useState(true)
	const {
		isOpen: customColoursOpen,
		customColourProduct,
		customComponents,
		updateCustomComponent,
		setOpen: setCustomColoursOpen
	} = useCustomColoursStore()
	const isBlogPage = location.pathname.startsWith('/blog')

	// Optimize initial render by deferring non-critical operations
	useEffect(() => {
		const timer = setTimeout(() => {
			fetchAllCountries();
		}, 1000);
		return () => clearTimeout(timer);
	}, []);

	const detectRegion = async () => {
		console.log('[regionModal] Detection: Starting detection');
		setIsDetecting(true);
		const detectedCountryCode = await fetchViewerCountry();
		console.log('[regionModal] Detection: Country detected', {
			detectedCountryCode,
			timestamp: new Date().toISOString()
		});
		setDetectedRegion(detectedCountryCode);
		setIsDetecting(false);
	}

	// Initial region detection
	useEffect(() => {
		detectRegion();
	}, [])

	// Update URL region whenever pathname changes and show modal if needed
	useEffect(() => {
		if (!isGlobalRoute(location.pathname)) {
			getUrlRegion(location.pathname);
		}
	}, [location.pathname]);

	// Show modal if URL region doesn't match detected region and hasn't been accepted
	useEffect(() => {
		if (!isGlobalRoute(location.pathname) && detectedRegion && urlRegion) {
			if (urlRegion !== detectedRegion && urlRegion !== chosenRegion) {
				if (isRegionAvailable(detectedRegion) && isRegionAvailable(urlRegion)) {
					console.log('[regionModal] Trigger:', {
						reason: 'URL Region Change',
						urlRegion,
						detectedRegion,
						chosenRegion,
						timestamp: new Date().toISOString()
					});
					setShowRegionModal(true);
				}
			}
		}
	}, [urlRegion, detectedRegion, chosenRegion, isRegionAvailable, location.pathname]);

	// Set chosen region if not set
	useEffect(() => {
		if (chosenRegion === null && detectedRegion !== null) {
			console.log('[regionModal] Selection:', {
				reason: 'Initial Detection',
				detectedRegion,
				timestamp: new Date().toISOString()
			});
			setChosenRegion(detectedRegion);
		}
	}, [chosenRegion, detectedRegion])

	useEffect(() => {
		let timeout: NodeJS.Timeout | null = null;

		if (fullscreenOverlayActive) {
			setHideOverflowActive(true)
		} else {
			timeout = setTimeout(() => {
				setHideOverflowActive(false)
			}, 200);
		}

		return () => {
			if (timeout) clearTimeout(timeout)
		};
	}, [fullscreenOverlayActive])

	// Handle cookie consent changes
	useEffect(() => {
		if (consent.hasConsented) {
			if (consent.analytics || consent.marketing) {
				initializeAnalytics(consent)
			} else {
				removeTrackingScripts()
			}
			updateAnalyticsConsent(consent)
		}
	}, [consent])

	const handleScroll = useCallback(() => {
		if (!contentRef.current) return;

		const currentScrollY = contentRef.current.scrollTop;
		const scrollHeight = contentRef.current.scrollHeight;
		const clientHeight = contentRef.current.clientHeight;
		const remainingScroll = scrollHeight - (currentScrollY + clientHeight);

		if (currentScrollY < navHeight || remainingScroll < navHeight * 2) {
			setIsNavVisible(true);
			setLastScrollY(currentScrollY);
			return;
		}

		const scrollDelta = currentScrollY - lastScrollY;
		if (Math.abs(scrollDelta) < 10) return;

		setIsNavVisible(scrollDelta < 0);
		setLastScrollY(currentScrollY);
	}, [lastScrollY, navHeight]);

	useEffect(() => {
		const contentElement = contentRef.current;
		if (!contentElement) return;

		contentElement.addEventListener('scroll', handleScroll, { passive: true });

		return () => {
			contentElement.removeEventListener('scroll', handleScroll);
		};
	}, [handleScroll]);

	return (
		<ScrollContainerContext.Provider value={{ contentRef, navHeight }}>
			<div className={`app relative bg-background transition-all ${hideOverflowActive && ''}`}>
				{/* Optimize initial paint by moving fixed positioning to a separate element */}
				<div className="min-h-screen bg-background">
					<div
						ref={contentRef}
						className="fixed inset-0 overflow-y-auto bg-background will-change-transform min-h-screen"
						style={{
							paddingTop: (navVisibilityOverride !== null ? navVisibilityOverride : isNavVisible) ? `${navHeight}px` : '0px',
							transition: 'padding-top 700ms cubic-bezier(0.4, 0, 0.2, 1)',
						}}
					>
						<div className="min-h-full relative">
							<ScrollToTop />
							<Routes>
								{/* Global routes that don't require region */}
								<Route path="/legal/*" element={<Legal />} />
								<Route path="legal/privacy-policy" element={<PrivacyPolicy />} />
								<Route path="legal/kibu-care-terms" element={<KibuCareTerms />} />
								<Route path="legal/terms-of-service" element={<TermsOfService />} />
								<Route path="legal/delivery-and-returns" element={<DeliveryAndReturns />} />
								<Route path="blog" element={<Blog />} />
								<Route path="blog/:slug" element={<BlogPost />} />

								{/* All region-specific routes */}
								<Route path="/*" element={
									<RegionWrapper
										toggleOverlay={setFullscreenOverlayActive}
										navHeight={navHeight}
										setNavVisibilityOverride={setNavVisibilityOverride}
									/>
								} />
							</Routes>

							<Footer
								className="z-10 relative"
							/>

						</div>
					</div>

					{/* Nav overlaid on top with high z-index */}
					<Nav
						isVisible={navVisibilityOverride !== null ? navVisibilityOverride : isNavVisible}
						onHeightChange={setNavHeight}
						className="z-40"
						disableScrollAnimation={isBlogPage}
					/>

					<InfoScreen />

					{/* Basket component */}
					<Basket className="z-50" />

					{/* Region Modal */}
					{showRegionModal && !isGlobalRoute(location.pathname) && (
						<RegionModal />
					)}

					{/* Region Selector Modal */}
					{isRegionModalOpen && (
						<RegionSelectorModal onClose={() => setIsRegionModalOpen(false)} />
					)}

					{/* Custom Colours Container */}
					<CustomColoursContainer
						open={customColoursOpen}
						customColourProduct={customColourProduct}
						customComponents={customComponents}
						updateCustomComponent={updateCustomComponent}
						onClose={() => setCustomColoursOpen(false)}
						onComplete={() => setCustomColoursOpen(false)}
						navHeight={navHeight}
					/>

					{/* Modals at the highest z-index */}
					<UnsupportedRegionModal />

					{/* CookieBanner moved outside the scrollable container */}
					<CookieBanner />
					<BackToTop className="z-500" contentRef={contentRef} />
				</div>
			</div>
		</ScrollContainerContext.Provider>
	);
};

// Outer component that provides the router
function App() {
	return (
		<BrowserRouter
			future={{
				v7_startTransition: true,
				v7_relativeSplatPath: true,
			}}>
			<AppContent />
		</BrowserRouter>
	);
}

export default App;
