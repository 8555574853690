import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import LegalPageLayout from '../components/LegalPageLayout';
import { buildRegionUrl, getRegionFromUrl } from '../utils/regionDetection';

const PrivacyPolicy: React.FC = () => {
    const location = useLocation();
    const currentRegion = getRegionFromUrl(location.pathname) || 'gb';

    return (
        <LegalPageLayout title="Privacy Policy">
            <div className="space-y-6">
                <section>
                    <p className="italic">Last Updated: 28th January 2025</p>
                    <p className="mt-4">
                        Thank you for visiting Kibu (hereafter "Kibu," "we," "us," or "our"). We value your privacy and are committed to protecting your personal data. This Privacy Policy explains how we collect, use, disclose, and protect information we obtain about our customers and website visitors.
                    </p>
                </section>

                <section>
                    <h2 className="text-2xl font-headline mb-4">1. WHO WE ARE</h2>
                    <p>
                        Kibu is owned and operated by Kibu (Company Number: 15495561).
                    </p>
                    <p className="mt-2">
                        Registered Office: 5 Sheep Lane, Hackney Depot, London, E8 4QS<br />
                        Correspondence Address: 1a Dunn Street, London, E8 2DG<br />
                        Privacy Inquiries: privacy@kibu.family
                    </p>
                    <p className="mt-2">
                        For the purposes of UK data protection law (including the UK GDPR and the Data Protection Act 2018), Kibu is the "data controller" of your personal data.
                    </p>
                </section>

                <section>
                    <h2 className="text-2xl font-headline mb-4">2. WHAT DATA WE COLLECT</h2>
                    <p>We collect several types of information from you, depending on how you interact with our website and services.</p>

                    <h3 className="text-xl font-headline mt-4 mb-2">Information You Provide Voluntarily</h3>
                    <ul className="list-disc pl-6">
                        <li><strong>Order Information:</strong> When you purchase from Kibu, we collect your name, shipping address, billing address, email address, and any engraving details you provide (such as a child's name). We may also collect payment details, though payment is processed securely by our third-party payment processor (Stripe).</li>
                        <li><strong>Reviews:</strong> When you submit a product review on our site, we collect your first name, last name, email address (for order verification, not displayed publicly), rating, and any feedback you provide.</li>
                        <li><strong>Email List:</strong> If you opt in to receive our newsletters or marketing emails, we collect your email address and any preferences you indicate.</li>
                    </ul>

                    <h3 className="text-xl font-headline mt-4 mb-2">Information Collected Automatically</h3>
                    <ul className="list-disc pl-6">
                        <li><strong>Cookies:</strong> We use cookies and similar tracking technologies to enhance your experience on our site, remember your preferences, and gather usage statistics. You will see a cookie banner where you can consent to or manage these cookies.</li>
                        <li><strong>Analytics Data:</strong> We use Google Analytics, Facebook/Meta Pixel and Microsoft Clarity to understand how users navigate our site, track conversions, and measure the effectiveness of our advertising.</li>
                    </ul>

                    <h3 className="text-xl font-headline mt-4 mb-2">Information from Third Parties</h3>
                    <ul className="list-disc pl-6">
                        <li><strong>Payment Processors:</strong> When you check out using Stripe, we may receive limited information about your transaction to fulfill and ship your order.</li>
                        <li><strong>Email Marketing Platforms:</strong> We use Klaviyo for our email campaigns. If you sign up for our newsletter or promotions, your email address and preferences are stored in Klaviyo.</li>
                    </ul>

                    <p className="mt-4">
                        <strong>Note on Children's Data:</strong> Our website and services are intended for adults/guardians purchasing items for children. We do not knowingly collect personal data from children under 13 (or the relevant minimum age in your jurisdiction). Any engraving details that include a child's name are provided by the adult purchaser.
                    </p>
                </section>

                <section>
                    <h2 className="text-2xl font-headline mb-4">3. HOW WE USE YOUR DATA</h2>
                    <p>We only use your personal data when the law allows us to. Common uses include:</p>

                    <h3 className="text-xl font-headline mt-4 mb-2">Fulfilling Contracts</h3>
                    <p>To process your orders, arrange delivery, handle returns or exchanges, and keep proper records for legal and financial compliance.</p>

                    <h3 className="text-xl font-headline mt-4 mb-2">Consent</h3>
                    <p>If you have opted into marketing, we will use your data to send you newsletters, promotions, or updates about new products. You can withdraw consent at any time by clicking "unsubscribe" in any email or by contacting us at privacy@kibu.family.</p>

                    <h3 className="text-xl font-headline mt-4 mb-2">Legitimate Interests</h3>
                    <ul className="list-disc pl-6">
                        <li>To improve our products and website based on analytics and feedback.</li>
                        <li>To maintain security and prevent fraud.</li>
                        <li>To personalize your user experience.</li>
                    </ul>

                    <h3 className="text-xl font-headline mt-4 mb-2">Legal Compliance</h3>
                    <p>We may process or disclose your data to comply with legal obligations, government requests, or to assert legal rights.</p>
                </section>

                <section>
                    <h2 className="text-2xl font-headline mb-4">4. DISCLOSURE OF YOUR DATA</h2>
                    <p>We do not sell your personal data to third parties. We may share your data in the following circumstances:</p>
                    <ul className="list-disc pl-6 mt-2">
                        <li><strong>Service Providers:</strong> We use trusted third-party services to help deliver our products.</li>
                        <li><strong>Analytics and Advertising Partners:</strong> Google Analytics and Facebook/Meta Pixel help us measure the effectiveness of our marketing.</li>
                        <li><strong>Legal or Regulatory Requests:</strong> We may share your data if required by law or to protect our rights.</li>
                    </ul>
                </section>

                <section>
                    <h2 className="text-2xl font-headline mb-4">5. INTERNATIONAL DATA TRANSFERS</h2>
                    <p>
                        Our main operations and servers are located in London, UK. However, some of our service providers (such as AWS, MongoDB Atlas, Klaviyo) may store or process data outside the UK. Whenever we transfer your data internationally, we ensure an adequate level of protection is in place.
                    </p>
                </section>

                <section>
                    <h2 className="text-2xl font-headline mb-4">6. DATA RETENTION</h2>
                    <ul className="list-disc pl-6">
                        <li><strong>Order Information:</strong> We retain for at least 6 years to comply with tax and accounting regulations.</li>
                        <li><strong>Marketing Communications:</strong> We keep your email on file indefinitely until you unsubscribe or request deletion.</li>
                        <li><strong>Reviews:</strong> Reviews may be displayed on our site indefinitely unless you request removal.</li>
                        <li><strong>Cookie Data:</strong> Cookie lifespans vary. Please see our Cookie Policy for specific durations.</li>
                    </ul>
                </section>

                <section>
                    <h2 className="text-2xl font-headline mb-4">7. SECURITY MEASURES</h2>
                    <p>We take reasonable steps to protect your personal data against unauthorized access, loss, alteration, or misuse. These include:</p>
                    <ul className="list-disc pl-6 mt-2">
                        <li><strong>HTTPS/TLS Encryption:</strong> Our website uses secure connections (HTTPS) to protect data in transit.</li>
                        <li><strong>Secure Infrastructure:</strong> Our backend is hosted on AWS and MongoDB Atlas, both maintaining robust security certifications.</li>
                        <li><strong>Payment Security:</strong> Stripe handles all payment information, maintaining PCI DSS compliance.</li>
                    </ul>
                </section>

                <section>
                    <h2 className="text-2xl font-headline mb-4">8. COOKIES & TRACKING TECHNOLOGIES</h2>
                    <p>
                        We use cookies, pixel tags, and similar technologies to provide, customize, and measure the performance of our website. When you first visit our site, you will see a cookie banner where you can consent to or decline non-essential cookies.
                    </p>
                    <ul className="list-disc pl-6 mt-2">
                        <li><strong>Essential Cookies:</strong> Necessary for our website to function.</li>
                        <li><strong>Analytics Cookies:</strong> Used by Google Analytics to understand site performance.</li>
                        <li><strong>Advertising Cookies:</strong> Used by Facebook/Meta Pixel to show relevant ads.</li>
                    </ul>
                </section>

                <section>
                    <h2 className="text-2xl font-headline mb-4">9. YOUR RIGHTS</h2>
                    <p>Under UK GDPR, you have certain rights regarding your personal data, including the right to:</p>
                    <ul className="list-disc pl-6 mt-2">
                        <li>Access your personal data</li>
                        <li>Request corrections to inaccurate data</li>
                        <li>Request deletion of your data</li>
                        <li>Object to our processing of your data</li>
                        <li>Request restriction of processing</li>
                        <li>Request data portability</li>
                    </ul>
                </section>

                <section>
                    <h2 className="text-2xl font-headline mb-4">10. CHILDREN'S PRIVACY</h2>
                    <p>
                        Our website and services are intended for adults/guardians purchasing on behalf of children. We do not knowingly collect personal data from children without parental consent. If you believe a child has provided us with personal data, please contact us at privacy@kibu.family.
                    </p>
                </section>

                <section>
                    <h2 className="text-2xl font-headline mb-4">11. CHANGES TO THIS POLICY</h2>
                    <p>
                        We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. If we make material changes, we will notify you by posting a notice on our website or by emailing you if you've provided us with your email address.
                    </p>
                </section>

                <section>
                    <h2 className="text-2xl font-headline mb-4">12. CONTACT US</h2>
                    <p>
                        If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
                    </p>
                    <p className="mt-2">
                        Kibu<br />
                        5 Sheep Lane, Hackney Depot, London, E8 4QS<br />
                        1a Dunn Street, London, E8 2DG (Correspondence)<br />
                        Email: privacy@kibu.family
                    </p>
                </section>
            </div>
        </LegalPageLayout>
    );
};

export default PrivacyPolicy; 