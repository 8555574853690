import React, { useState, useEffect } from 'react'
import { useSwipeable } from 'react-swipeable'
import { IProductImage } from '../types'

type ImageSlideshowProps = {
  images: IProductImage[]
  className?: string
  dynamicImage: IProductImage
  autoJumpToFirst?: boolean
  showEditColors?: boolean
  onEditColors?: () => void
  onAllImagesLoaded?: () => void
}

const ImageSlideshow: React.FC<ImageSlideshowProps> = ({
  images,
  className,
  dynamicImage,
  autoJumpToFirst = false,
  showEditColors = false,
  onEditColors,
  onAllImagesLoaded,
}) => {
  const [allImages, setAllImages] = useState<IProductImage[]>([])
  const [currentIndex, setCurrentIndex] = useState<number>(0)

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleSwipe('left'),
    onSwipedRight: () => handleSwipe('right'),
    preventScrollOnSwipe: true,
  })

  useEffect(() => {
    setAllImages([dynamicImage, ...images])
  }, [images, dynamicImage])

  useEffect(() => {
    const interval = setInterval(() => {
      if (autoJumpToFirst) {
        setCurrentIndex(0)
      }
    }, 8000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (allImages.length === 0) return; // Avoid running if there are no images

    console.log('[imageLoadCheck] Starting to load images');

    // Preload images using link tags
    allImages.forEach(image => {
      const link = document.createElement('link');
      link.rel = 'preload';
      link.as = 'image';
      link.href = image.url;
      link.onload = () => {
        console.log(`[imageLoadCheck] Preloaded image: ${image.url}`);
      };
      document.head.appendChild(link);
    });

    const imageLoadPromises = allImages.map(image => {
      return new Promise<void>((resolve) => {
        const img = new Image();
        img.src = image.url;
        img.onload = () => {
          console.log(`[imageLoadCheck] Image loaded: ${image.url}`);
          resolve();
        };
      });
    });

    Promise.all(imageLoadPromises).then(() => {
      console.log('[imageLoadCheck] All images loaded');
      if (onAllImagesLoaded) {
        onAllImagesLoaded();
      }
    });
  }, [allImages]);

  const handleThumbnailClick = (index: number) => {
    setCurrentIndex(index)
  }

  const handleSwipe = (direction: 'left' | 'right') => {
    if (direction === 'left') {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % allImages.length)
    } else if (direction === 'right') {
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + allImages.length) % allImages.length
      )
    }
  }

  return (
    <div className={`image-slideshow product-container flex flex-col w-full md:sticky top-[172px] self-start ${className} aspect-[4/4.25] max-h-[calc(100vh-192px)]`}>

      <div
        {...swipeHandlers}
        className="product-image h-full bg-white flex justify-center items-center rounded-xl overflow-hidden aspect-[4/4.25] relative">
        <div
          className="w-full h-full transition-transform duration-500 ease-kibushopdefault aspect-[4/4.25]"
          style={{
            transform: `translateX(-${currentIndex * 100}%)`,
            display: 'flex',
          }}>
          {allImages.map((image, index) => (
            <div key={index} className="w-full flex-shrink-0 relative aspect-[4/4.25]">
              {image.backgroundUrl && (
                <img
                  src={image.backgroundUrl}
                  alt={`${image.alt} background`}
                  className="absolute inset-0 w-full h-full object-cover"
                />
              )}
              <img
                src={image.url}
                alt={image.alt}
                className="absolute inset-0 w-full h-full object-contain"
              />
            </div>
          ))}
        </div>
        {showEditColors && currentIndex === 0 && (
          <button
            onClick={onEditColors}
            className="absolute bottom-3 left-3 font-headline flex items-center gap-1 bg-white rounded-lg py-2 px-3 animate-gradient-text"
          >
            <span className="text-gradient">Edit Colours</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M12 19l7-7 3 3-7 7-3-3z"></path>
              <path d="M18 13l-1.5-7.5L2 2l3.5 14.5L13 18l5-5z"></path>
              <path d="M2 2l7.586 7.586"></path>
              <circle cx="11" cy="11" r="2"></circle>
            </svg>
          </button>
        )}
      </div>

      <div className="product-thumbnails flex flex-row gap-2 md:gap-4 mt-2 md:mt-4">
        {allImages.map(
          (image, index) =>
            image.showThumbnail && (
              <button
                key={index}
                onClick={() => handleThumbnailClick(index)}
                className={`product-thumbnail w-full aspect-[4/4] border border-2 border-background rounded-xl bg-orange overflow-hidden ${index === currentIndex ? 'outline outline-2 outline-orange' : ''
                  }`}>
                <div className="relative w-full h-full">
                  {image.backgroundUrl && (
                    <img
                      src={image.backgroundUrl}
                      alt={`${image.alt} background`}
                      className="absolute inset-0 w-full h-full min-w-full min-h-full object-cover"
                    />
                  )}
                  <img
                    src={image.url}
                    alt={image.alt}
                    className="absolute inset-0 w-full h-full object-cover"
                  />
                </div>
              </button>
            )
        )}
      </div>
    </div>
  )
}

export default ImageSlideshow
