import { create } from 'zustand';
import { CountriesResponse } from '@/services/api';

interface AvailableRegionsState {
    availableRegions: string[];
    setAvailableRegions: (countries: CountriesResponse) => void;
    isRegionAvailable: (region: string) => boolean;
}

const extractAvailableRegions = (countries: CountriesResponse): string[] => {
    const regions: string[] = [];

    // Add individual countries
    Object.keys(countries).forEach(key => {
        if (!('type' in countries[key])) {
            regions.push(key.toLowerCase());
        }
    });

    // Add countries from groups
    Object.values(countries).forEach(value => {
        if ('type' in value && value.type === 'group') {
            Object.keys(value.countries).forEach(countryCode => {
                regions.push(countryCode.toLowerCase());
            });
        }
    });

    return regions;
};

const useAvailableRegionsStore = create<AvailableRegionsState>((set, get) => ({
    availableRegions: [],
    setAvailableRegions: (countries: CountriesResponse) => {
        const regions = extractAvailableRegions(countries);
        set({ availableRegions: regions });
    },
    isRegionAvailable: (region: string) => {
        return get().availableRegions.includes(region.toLowerCase());
    },
}));

export default useAvailableRegionsStore; 