import React, { useState, useEffect, RefObject } from 'react';

interface BackToTopProps {
    className?: string;
    contentRef: RefObject<HTMLDivElement>;
}

const BackToTop = ({ className, contentRef }: BackToTopProps) => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        if (contentRef.current && contentRef.current.scrollTop > 100) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        if (contentRef.current) {
            contentRef.current.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    useEffect(() => {
        const currentRef = contentRef.current;
        if (!currentRef) return;

        currentRef.addEventListener('scroll', toggleVisibility);
        return () => currentRef.removeEventListener('scroll', toggleVisibility);
    }, [contentRef]);

    return (
        <div className={`fixed bottom-4 right-4 ${className}`} style={{
            opacity: isVisible ? 1 : 0,
            visibility: isVisible ? 'visible' : 'hidden',
            transition: 'opacity 0.5s ease-in-out, visibility 0.5s ease-in-out'
        }}>
            <button
                onClick={scrollToTop}
                className="bg-gray-400 text-white p-2 rounded-full shadow-lg hover:bg-gray-600"
            >
                ↑ Back to Top
            </button>
        </div>
    );
};

export default BackToTop; 