import { fetchViewerCountry } from './api';

export const detectUserCountry = async (): Promise<string> => {
    try {
        const countryCode = await fetchViewerCountry();
        return countryCode.toLowerCase();
    } catch (error) {
        console.error('Failed to detect user country:', error);
        return 'gb'; // Default to GB if detection fails
    }
};

export const getRegionFromPath = (path: string): string | null => {
    if (!path) return null;
    const match = path.match(/^\/([a-z]{2})(\/|$)/i);
    return match ? match[1].toLowerCase() : null;
}; 