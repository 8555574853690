import React from 'react';
import { Link } from 'react-router-dom';
import { Instagram } from 'lucide-react';
import { buildRegionUrl } from '@/utils/regionDetection';
import useRegionStore from '@/context/RegionStore';
import RegionSelector from './RegionSelector';

interface FooterProps {
    className?: string;
    style?: React.CSSProperties;
}

const Footer: React.FC<FooterProps> = ({ className = '', style = {} }) => {
    const { chosenRegion } = useRegionStore();

    const buildRegionalLink = (path: string) => buildRegionUrl(chosenRegion || 'gb', path);

    return (
        <footer className={`bg-[#353535] text-white pt-12 rounded-t-[32px] ${className}`} style={style}>
            <div className="standard_container mx-auto px-6">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    {/* Company Info */}
                    <div className="flex flex-col gap-4">
                        <Link to="/" className="text-white hover:text-white/30 transition-colors">
                            <h3 className="text-xl font-headline">Kibu Shop</h3>
                        </Link>
                        <p className="text-gray-300">
                            Creating the best headphones for kids, designed with safety and style in mind.
                        </p>

                        {/* Region Selector */}
                        <div className="relative mt-4">
                            <RegionSelector variant="footer" />
                        </div>
                    </div>

                    {/* Quick Links */}
                    <div className="flex flex-col gap-4">
                        <Link to="/legal" className="text-white hover:text-white/30 transition-colors">
                            <h3 className="text-xl font-headline">Legal</h3>
                        </Link>
                        <div className="flex flex-col gap-2">
                            <Link to='/legal' className="text-gray-300 hover:text-white transition-colors">
                                All Legal Documents
                            </Link>
                            <Link to='/legal/terms-of-service' className="text-gray-300 hover:text-white transition-colors">
                                Terms of Service
                            </Link>
                            <Link to='/legal/kibu-care-terms' className="text-gray-300 hover:text-white transition-colors">
                                Kibu Care Terms
                            </Link>
                            <Link to='/legal/privacy-policy' className="text-gray-300 hover:text-white transition-colors">
                                Privacy Policy
                            </Link>
                            <Link to='/legal/delivery-and-returns' className="text-gray-300 hover:text-white transition-colors">
                                Delivery, Returns & Recycling
                            </Link>
                        </div>
                    </div>

                    {/* Social Links */}
                    <div className="flex flex-col gap-4">
                        <h3 className="text-xl font-headline">Connect With Us</h3>
                        <div className="flex gap-4">
                            <a href="https://www.instagram.com/kibu.family/" target="_blank" rel="noopener noreferrer"
                                className="text-gray-300 hover:text-white transition-colors">
                                <Instagram size={24} />
                            </a>
                        </div>
                    </div>
                </div>


            </div>
            <div className="mt-12 py-2 text-center text-gray-400 text-sm">
                <p>&copy; {new Date().getFullYear()} Kibu Limited. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer; 