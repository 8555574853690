import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { IColourway, IProduct } from './ProductDataStore'
import { AddonInputValue } from '@/types'

export interface IBasketItem {
  id: string
  name: string
  price: number
  colourWay: IProduct
  extras: IProduct[]
  extrasData: AddonInputValue[]
}

interface IBasket {
  itemCount: number
  basketOpen: boolean
  basketItems: IBasketItem[]
  setItemCount: (count: number) => void
  setBasketOpen: (open: boolean) => void
  addItemToBasket: (item: IBasketItem) => void
  removeItemFromBasket: (itemId: string) => void
  updateBasketItem: (itemId: string, updatedItem: Partial<IBasketItem>) => void
  clearBasket: () => void
}

const useBasketStore = create<IBasket>()(
  persist(
    (set) => ({
      itemCount: 0,
      basketOpen: false,
      basketItems: [],
      setItemCount: (itemCount) => set({ itemCount }),
      setBasketOpen: (basketOpen) => set({ basketOpen }),
      addItemToBasket: (item: IBasketItem) => set((state) => {
        const updatedItems = [...state.basketItems, item]
        return {
          basketItems: updatedItems,
          itemCount: updatedItems.length,
        }
      }),
      removeItemFromBasket: (itemId: string) => set((state) => {
        const updatedItems = state.basketItems.filter(item => item.id !== itemId)
        return {
          basketItems: updatedItems,
          itemCount: updatedItems.length,
        }
      }),
      updateBasketItem: (itemId: string, updatedItem: Partial<IBasketItem>) => set((state) => {
        const updatedItems = state.basketItems.map(item =>
          item.id === itemId
            ? { ...item, ...updatedItem }
            : item
        )
        return {
          basketItems: updatedItems,
          itemCount: updatedItems.length,
        }
      }),
      clearBasket: () => set({ basketItems: [], itemCount: 0 }),
    }),
    {
      name: 'basket-storage', // unique name for localStorage
    }
  )
)

export default useBasketStore


