import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion'

import InteractiveImage from './InteractiveImage'
import { metaData } from './headphone-parts-image_meta-data'
import { MousePointerClick, X } from 'lucide-react'
import { IComponent, IProduct, ICustomComponent } from '@/context/ProductDataStore'

interface DynamicHeadphonesProps {
  viewName: string
  engraving: string
  customColourProduct: IProduct
  customComponents: ICustomComponent[]
  toggleColourPicker: (partName: string, mousePos: { x: number; y: number }, position: string | null) => void
  interactive: boolean
  className?: string
  miniature?: boolean
  navHeight?: number
}

const DynamicHeadphones: React.FC<DynamicHeadphonesProps> = ({
  viewName,
  engraving,
  customColourProduct,
  customComponents,
  toggleColourPicker,
  interactive,
  className = '',
  miniature = false,
}) => {
  const [partsData, setPartsData] = useState<{ part_name: string; index: number; position: string | null; src: string }[]>([])
  const [partIndexClicked, setPartIndexClicked] = useState<number | null>(null)
  const [partPositionClicked, setPartPositionClicked] = useState<string | null>(null)
  const [partIndexHovered, setPartIndexHovered] = useState<number | null>(null)
  const [hoveredPosition, setHoveredPosition] = useState<string | null>(null)
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 })
  const { activeOrderItemId } = useParams<{ activeOrderItemId: string }>()

  const isMobile = window.matchMedia('(max-width: 812px)').matches
  const easing = [0.21, 0.035, 0.0, 1.0]

  const preloadedImages = new Set<string>()

  const preloadImage = (src: string) =>
    new Promise<void>((resolve, reject) => {
      if (preloadedImages.has(src)) {
        resolve()
        return
      }

      const img = new Image()
      img.src = src
      img.onload = () => {
        preloadedImages.add(src)
        resolve()
      }
      img.onerror = reject
    })

  const preloadAllImages = async (parts: { src: string }[]) => {
    const preloadPromises = parts.map(part => preloadImage(part.src))
    await Promise.all(preloadPromises)
  }

  useEffect(() => {
    // console.log('CHECKING Dynamic headphones customComponents', customComponents)

    const partsWithImages: { part_name: string; index: number; position: string | null; src: string; isInteractable: boolean }[] = customComponents
      .filter((component: ICustomComponent) => {
        // Only include components where display exists and configuratorFrontOn is true
        return component.display?.[0]?.configuratorFrontOn === true;
      })
      .map((component: ICustomComponent) => {
        const zIndex = component.display?.[0]?.['z-index'] ?? 0
        const positionPart = component.position ? `_${component.position}` : ''
        const materialId = component.chosenMaterial.$oid ? `_${component.chosenMaterial.$oid}` : ''
        const imagePath = `/assets/headphones/front_view/${component.componentVariantId.$oid}${positionPart}${materialId}.webp`

        // A component is interactable if it has either:
        // 1. A valid chosenMaterial (not empty string)
        // 2. Available materialOptions
        const hasValidChosenMaterial = component.chosenMaterial?.$oid && component.chosenMaterial.$oid !== ''
        const hasMaterialOptions = Array.isArray(component.materialOptions) && component.materialOptions.length > 0
        const isInteractable = (hasValidChosenMaterial || hasMaterialOptions) && (component.display?.[0]?.configuratorFrontOn ?? false)

        return {
          part_name: component.name,
          index: zIndex,
          position: component.position,
          src: imagePath,
          isInteractable
        }
      })
      // Sort by z-index
      .sort((a, b) => a.index - b.index)

    // console.log('CHECKING Final partsWithImages:', partsWithImages)

    preloadAllImages(partsWithImages)
      .then(() => {
        setPartsData(partsWithImages)
      })
      .catch((error) => {
        console.error('Failed to load images:', error)
        // Log which image failed to load
        console.error('Failed to load image:', error.target?.src || 'Unknown image')
        setPartsData(partsWithImages)
      })
  }, [viewName, customComponents])

  useEffect(() => {
    if (!interactive) {
      setPartIndexClicked(null)
      setPartIndexHovered(null)
      setPartPositionClicked(null)
    }
  }, [interactive])

  const getPartNameByIndex = (index: number | null, position: string | null) => {
    const foundPart = partsData.find(part => part.index === index && part.position === position)
    // console.log('getPartNameByIndex called with:', { index, position, foundPart })
    return foundPart?.part_name || null
  }

  return (
    <div className={`dynamic-headphones relative transform-gpu overflow-hidden z-10 w-full h-full flex items-center justify-center
      ${!miniature && ''} 
      ${viewName === 'top_view' ? '-top-20 md:top-0' : ''} 
      ${className}`}>
      <AnimatePresence mode="wait">
        {viewName === 'front_view' ? (
          <motion.div
            key='front'
            initial={{ opacity: 0, filter: 'blur(5px)', transform: 'scale(1.05)' }}
            animate={{ opacity: 1, filter: 'blur(0px)', transform: 'scale(1)' }}
            transition={{ duration: 0.2 }}
            exit={{ opacity: 0, filter: 'blur(10px)', transform: 'scale(0.95)' }}
            className={`relative w-full h-full flex items-center justify-center transform-gpu ${!miniature ? '-top-12 md:top-0' : ''}`}>
            <div className="relative w-full h-full max-w-full max-h-full object-contain">
              <AnimatePresence>
                {partsData.map((part, index) => (
                  <motion.img
                    src={part.src}
                    key={index}
                    initial={{ y: '-10%', opacity: 0 }}
                    animate={{ y: '0%', opacity: 1, transition: { y: { duration: 2.4, ease: [0, 1, 0, 1] }, opacity: { duration: 0.8, ease: [0, 0, 0, 1] } } }}
                    exit={{ y: '-2%', opacity: 0 }}
                    style={{ zIndex: part.index }}
                    className={`absolute w-full h-full object-contain transform-gpu transition-none top-0 left-0 ${(partIndexHovered === null && partIndexClicked === null)
                      ? 'brightness-100'  // No hover or click
                      : (partIndexHovered === part.index && hoveredPosition === part.position) || (partIndexClicked === part.index && partPositionClicked === part.position)
                        ? 'brightness-100'  // Either hovered or clicked part
                        : 'brightness-75'   // Other parts when something is hovered/clicked
                      }`}
                  />
                ))}
              </AnimatePresence>
            </div>
          </motion.div>
        ) : (
          <motion.div
            key='top'
            initial={{ opacity: 0, filter: 'blur(5px)', transform: 'scale(1.05)' }}
            animate={{ opacity: 1, filter: 'blur(0px)', transform: 'scale(1)' }}
            transition={{ duration: 0.2 }}
            exit={{ opacity: 0, filter: 'blur(10px)', transform: 'scale(0.95)' }}
            className={`relative transform-gpu w-full h-full flex items-center justify-center md:top-0`}>
            <div className="relative w-full h-full max-w-full max-h-full object-contain">
              {partsData.map(part => (
                <img
                  src={part.src}
                  key={part.index}
                  style={{ zIndex: part.index }}
                  className={`${part.index > 0 ? 'absolute' : 'relative'} w-full h-full object-contain ${partIndexHovered === null ? 'brightness-100' : (partIndexHovered === part.index ? 'brightness-100' : 'brightness-75')} transition-all duration-100 ease-kibu top-0 left-0`}
                />
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      {viewName === 'front_view' && interactive && (
        <motion.div
          key='top'
          initial={{ opacity: 0, filter: 'blur(0px)', transform: 'scale(1.3)' }}
          animate={{ opacity: 0.7, filter: 'blur(0px)', transform: 'scale(1)' }}
          transition={{ duration: 0.6, ease: easing }}
          exit={{ opacity: 0, filter: 'blur(10px)', transform: 'scale(0.9)' }}
          className={`absolute transform-gpu flex justify-center text-xs md:text-base items-center w-94 h-32 ${!miniature && '-top-14'} md:-top-20 left-0 right-0 bottom-0 m-auto`}>
          {partIndexClicked ? (
            <div className='flex flex-col justify-center items-center gap-1 mb-20'>
              <X className='h-6 w-6' />
              Click anywhere to deselect
            </div>
          ) : (
            <div className='animate-pulse flex flex-col justify-center items-center gap-1 mb-20'>
              <MousePointerClick className='w-6 h-6' />
              Click Part to Choose Colour
            </div>
          )}
        </motion.div>
      )}
      {viewName === 'top_view' && engraving !== '' && (
        <motion.div
          key='top'
          initial={{ opacity: 0, filter: 'blur(0px)', transform: 'scale(1.3)' }}
          animate={{ opacity: 1, filter: 'blur(0px)', transform: 'scale(1)' }}
          transition={{ duration: 0.6, ease: easing }}
          exit={{ opacity: 0, filter: 'blur(10px)', transform: 'scale(0.9)' }}
          className={`absolute transform-gpu flex justify-center items-center w-94 h-32 top-2 md:top-0 left-0 right-0 bottom-0.5 m-auto text-[4cqw] md:text-[2.5cqw]`}
          style={{
            backgroundColor: '#aaaaaa',
            color: 'transparent',
            textShadow: '1px 1px 2px rgba(255,255,255,0.8)',
            backgroundClip: 'text',
            mixBlendMode: 'multiply'
          }}>
          {engraving}
        </motion.div>
      )}
      {partsData && interactive && (
        <InteractiveImage
          parts={partsData}
          setPartIndexHovered={(index: number | null, position: string | null) => {
            setPartIndexHovered(index)
            setHoveredPosition(position)
          }}
          setPartIndexClicked={(index: number | null, position: string | null) => {
            setPartIndexClicked(index)
            setPartPositionClicked(position)
            setHoveredPosition(null)
          }}
          setMousePosition={setMousePosition}
          partClicked={(index: number | null, position: string | null, mousePos: { x: number, y: number }) =>
            toggleColourPicker(getPartNameByIndex(index, position) || '', mousePos, position)}
          className={`absolute inset-0 w-full h-full -translate-y-[48px] md:translate-y-[0px]`}
        />
      )}
    </div>
  )
}

export default DynamicHeadphones
