import { DrawerItem } from '../types/drawer.types';

export const kibuDrawers: DrawerItem[] = [
    {
        title: 'Build, Repair, Play',
        description: 'More than just headphones – Kibu is a hands-on experience. Build them together, learn how they work, and if anything breaks, simply replace a part instead of the whole thing.',
        media: {
            type: 'image',
            url: 'https://d1tg5gdu842vo5.cloudfront.net/site-assets/images/headphones-in-context/boy_building.jpg',
            alt: 'Child assembling Kibu headphones'
        }
    },
    {
        title: 'Safe for Little Ears',
        description: 'With the Kibu Safety Engine, our headphones always stay at a kid-safe 85dB, no matter the device. Worry-free listening for parents, fun listening for kids.',
        media: {
            type: 'image',
            url: 'https://d1tg5gdu842vo5.cloudfront.net/site-assets/images/headphones-in-context/boy_and_girl_listening.jpg',
            alt: 'Child enjoying music safely'
        }
    },
    {
        title: 'Sustainable by Design',
        description: 'Made from 3D-printed recycled materials, Kibu headphones are built with the planet in mind. And when they reach the end of their journey, send them back for recycling.',
        media: {
            type: 'video',
            url: 'https://d1tg5gdu842vo5.cloudfront.net/site-assets/video/sustainable.mp4',
            posterUrl: 'https://d1tg5gdu842vo5.cloudfront.net/site-assets/images/placeholders/sustainable_placeholder.jpg',
            alt: 'Eco-friendly headphone materials'
        }
    },
    {
        title: 'Sharing Made Simple',
        description: 'Daisy-chain multiple Kibu headphones together for shared listening. Whether on a road trip or a playdate, music and stories are better together.',
        media: {
            type: 'image',
            url: 'https://d1tg5gdu842vo5.cloudfront.net/site-assets/images/headphones-in-context/boy_and_father_listening.jpg',
            alt: 'Two children sharing headphones'
        }
    }
];  
