import React, { useEffect, useState, useContext } from 'react'
import { useSearchParams, useLocation } from 'react-router-dom'
import './ProductPage.css'
import { Loader, Truck, Undo2, AlertTriangleIcon } from 'lucide-react'
import Nav from '@/components/nav/Nav'
import ImageSlideshow from '@/components/ImageSlideshow'
import { IProductImage, ActiveAddonItem, AddonInputValue } from '@/types'
import Testimonials from '@/components/Testimonials'
import ProductInfo from '@/components/ProductInfo'
import PaymentMethods from '@/components/PaymentMethods'
import InfoButton from '@/components/InfoButton'
import Addon, { IAddonItem } from '@/components/Addon'
import AddonSkeleton from '@/components/AddonSkeleton'
import SelectedItems from '@/components/SelectedItems'
import { trackViewContent, trackStartCustomization, trackCompleteCustomization, trackAddToCart } from '@/utils/tracking'
import useRegionStore from '@/context/RegionStore'
import { useCurrency } from '@/hooks/useCurrency'
import { format } from 'date-fns'

import { productsData } from '@/data/productData'
import useProductDataStore, { IColourway, IOption, IPartsModifications, IProduct } from '@/context/ProductDataStore'
import { fetchProductData } from '@/services/api'
import useBasketStore, { IBasketItem } from '@/context/BasketStore'
import { uuidv4 } from '@/services/helpers'
import LoadingRing from '@/components/LoadingRing'
import ProductDetailsCard from '@/components/ProductDetailsCard'
import ProductSpecs from '@/components/ProductSpecs'
import CustomColoursContainer from '@/components/CustomColoursContainer'
import ComparisonModule from '@/modules/comparisons/components/ComparisonModule'
import { kibuDrawers } from '@/modules/drawers/data/drawerExamples'
import DrawerModule from '@/modules/drawers/components/DrawerModule'
import { galleryImages } from '@/modules/scrolling-gallery/data/galleryExamples'
import ScrollingGallery from '@/modules/scrolling-gallery/components/ScrollingGallery'
import { awardWinning } from '@/modules/spotlights/data/spotlightExamples'
import SpotlightSplitModule from '@/modules/spotlights/components/SpotlightSplitModule'
import { ScrollContainerContext } from '@/components/ScrollToTop'
import useCustomColoursStore from '@/context/CustomColoursStore'
import useCountryDetailsStore from '@/context/CountryDetailsStore'

interface ICustomComponent {
	componentVariantId: { $oid: string }
	componentType: string
	name: string
	position: string | null
	chosenMaterial: { $oid: string }
	display?: Array<{
		configuratorFrontOn: boolean
		'z-index'?: number
	}>
	materialOptions?: string[]
}

interface ProductPageProps {
	toggleOverlay: Function
	navHeight: number
	setNavVisibilityOverride: (override: boolean | null) => void
}

const ProductPage: React.FC<ProductPageProps> = ({ toggleOverlay, navHeight, setNavVisibilityOverride }) => {
	const [searchParams] = useSearchParams()
	const location = useLocation()
	const { product, options, setProductData } = useProductDataStore()
	const { setBasketOpen, addItemToBasket } = useBasketStore()
	const { chosenRegion } = useRegionStore()
	const { symbol: currencySymbol, formatPrice, freeDeliveryThreshold } = useCurrency()
	const { countryDetails } = useCountryDetailsStore()
	const contentRef = useContext(ScrollContainerContext);

	const DEFAULT_PRODUCT_ID = "67421f7894b683dbd1ba6491"
	const productId = searchParams.get('variant') || DEFAULT_PRODUCT_ID
	const [isLoading, setIsLoading] = useState(true)
	const [currentPrice, setCurrentPrice] = useState<number>(0)
	const [colourWays, setColourWays] = useState<IAddonItem[]>([])
	const [selectedColorWay, setSelectedColorWay] = useState<string | null>(null)
	const {
		isOpen: customColoursOpen,
		setOpen: setCustomColoursOpen,
		setCustomColourProduct,
		setCustomComponents,
		customComponents: globalCustomComponents,
		customColourProduct: globalCustomColourProduct,
		updateCustomComponent
	} = useCustomColoursStore()
	const [showConfiguratorModal, setShowConfiguratorModal] = useState<boolean>(false)
	const [isInitialLoad, setIsInitialLoad] = useState(true)

	const [addonsSelectedItemIds, setAddonsSelectedItemIds] = useState<ActiveAddonItem[]>([])
	const [addonsInputValues, setAddonsInputValues] = useState<AddonInputValue[]>([])
	const [activeProductImage, setActiveProductImage] = useState<IProductImage>({
		url: '/assets/images/product_images/' + selectedColorWay + '_main.webp',
		backgroundUrl: '/assets/images/product_images/' + selectedColorWay + '_background.webp',
		showThumbnail: true,
		alt: 'Kibu Headphones Default Variant',
	})
	const [imagesLoaded, setImagesLoaded] = useState(false);

	useEffect(() => {
		const CACHE_KEY = `kibu_product_data_${productId}`
		const CACHE_DURATION = 1 * 60 * 10 // 10 minutes

		const fetchData = async () => {
			setIsLoading(true)
			try {
				// Check cache first
				const cachedData = localStorage.getItem(CACHE_KEY)
				if (cachedData) {
					const { data, timestamp } = JSON.parse(cachedData)
					const isExpired = Date.now() - timestamp > CACHE_DURATION

					if (!isExpired) {
						console.log('[COLOUR] Using cached product data for product:', productId)
						if (data.product) {
							const { availableOptions, ...restProduct } = data.product
							// Sort options by order before setting data
							const sortedOptions = availableOptions ? [...availableOptions].sort((a, b) => (a.order || 0) - (b.order || 0)) : []
							setProductData(restProduct, sortedOptions, data.materialInformation || [])
							setIsLoading(false)
							return
						}
					} else {
						console.log('[COLOUR] Cache expired for product:', productId)
					}
				} else {
					console.log('[COLOUR] No cache found for product:', productId)
				}

				// Fetch fresh data if no cache or cache expired
				const productData = await fetchProductData(productId, chosenRegion || 'gb')
				console.log('[regionModal] fetch product data chosenRegion:', chosenRegion)
				console.log('[COLOUR] Fetched fresh product data for product:', productId)
				if (productData.product) {
					const { availableOptions, ...restProduct } = productData.product
					// Sort options by order before setting data
					const sortedOptions = availableOptions ? [...availableOptions].sort((a, b) => (a.order || 0) - (b.order || 0)) : []
					console.log('[COLOUR] Setting product data with options:', sortedOptions)
					setProductData(restProduct, sortedOptions, productData.materialInformation || [])

					// Track ViewContent event when product data is loaded
					const initialPrice = sortedOptions[0]?.options[0]?.price?.unit_amount ?? 0
					trackViewContent(restProduct, chosenRegion, initialPrice)

					// Cache the new data
					localStorage.setItem(CACHE_KEY, JSON.stringify({
						data: productData,
						timestamp: Date.now()
					}))
				}
			} catch (error) {
				console.error('[COLOUR] Error fetching product data:', error)
			} finally {
				setIsLoading(false)
			}
		}

		fetchData()
	}, [])

	useEffect(() => {
		const _colourWays: IProduct[] = options.find(option => option.required)?.options || []
		if (_colourWays) {
			console.log('[COLOUR] Processing colourways from options:', options)
			const foundCustomColourProduct: IProduct | undefined = _colourWays.find((_product: IProduct) => _product.optionTitle?.toLowerCase().includes('custom'))
			console.log('[COLOUR] Raw colourways before mapping:', _colourWays.map(cw => ({ id: cw._id, title: cw.optionTitle, price: cw.price?.unit_amount })))

			// Map colourways preserving original order
			const colourWayItems: IAddonItem[] = _colourWays.map((_product: IProduct) => {
				const item: IAddonItem = {
					id: _product._id,
					imageUrl: '/assets/images/product_thumbnails/' + _product._id + '.webp',
					title: _product.optionTitle || '',
					price: (_product.price?.unit_amount ?? 0) - (_colourWays[0]?.price?.unit_amount ?? 0),
					currency: currencySymbol,
					triggersSpecialSelect: _product.optionTitle?.toLowerCase().includes('custom'),
					originalPrice: _product.price?.unit_amount ?? 0
				}
				return item
			})

			// Move custom colours to the end but preserve order of standard colours
			const customColours = colourWayItems.filter(item => item.triggersSpecialSelect)
			const standardColours = colourWayItems.filter(item => !item.triggersSpecialSelect)

			const sortedColourWayItems = [...standardColours, ...customColours]
			console.log('[COLOUR] Final colourways:', sortedColourWayItems.map(cw => ({
				id: cw.id,
				title: cw.title,
				order: _colourWays.findIndex(c => c._id === cw.id),
				triggersSpecialSelect: cw.triggersSpecialSelect
			})))

			setColourWays(sortedColourWayItems)
			setCustomColourProduct(foundCustomColourProduct)

			// Initialize custom components if we have a custom colour product
			if (foundCustomColourProduct?.components) {
				const initialComponents: ICustomComponent[] = foundCustomColourProduct.components.map((component: ICustomComponent) => ({
					componentVariantId: component.componentVariantId,
					componentType: component.componentType,
					name: component.name,
					position: component.position || null,
					chosenMaterial: { $oid: component.chosenMaterial.$oid || '' },
					display: component.display,
					materialOptions: component.materialOptions
				}))
				setCustomComponents(initialComponents)
			}
		} else {
			console.log('[COLOUR] No colourways found in options:', options)
		}
	}, [product, currencySymbol])

	useEffect(() => {
		const _addonsActiveItemIds: ActiveAddonItem[] = options.map((option: IOption) => ({
			[option.productCatalogueId]: null,
		}))
		setAddonsSelectedItemIds(_addonsActiveItemIds)
	}, [options])

	useEffect(() => {
		if (colourWays.length && selectedColorWay === null) {
			console.log('[COLOUR] Attempting to select default colourway')
			console.log('[COLOUR] Product ID to match:', productId)
			console.log('[COLOUR] Current selectedColorWay:', selectedColorWay)
			console.log('[COLOUR] Available colourways:', colourWays.map(cw => ({
				id: cw.id,
				title: cw.title,
				triggersSpecialSelect: cw.triggersSpecialSelect
			})))
			const matchingColourway = colourWays.find(cw => cw.id === productId)
			console.log('[COLOUR] Matching colourway found:', matchingColourway?.title || 'none')
			const selectedId = matchingColourway?.id || colourWays[0]?.id || null
			console.log('[COLOUR] Selected colourway ID:', selectedId)
			console.log('[COLOUR] First colourway as fallback:', colourWays[0]?.title || 'none')
			setSelectedColorWay(selectedId)
			setIsInitialLoad(true)  // Mark this as initial load when setting default
		}
	}, [colourWays, productId])

	// Handle delayed opening of custom colours on initial load
	useEffect(() => {
		const CUSTOM_COLORS_ID = "674b4a1f197065b8a54a3224"
		if (isInitialLoad && selectedColorWay === CUSTOM_COLORS_ID && productId === CUSTOM_COLORS_ID) {
			console.log('[COLOUR] Setting up delayed modal open for custom colors')
			const timer = setTimeout(() => {
				console.log('[COLOUR] Opening configurator modal after delay')
				setShowConfiguratorModal(true)
				setIsInitialLoad(false)  // Reset the initial load flag
			}, 2000)
			return () => clearTimeout(timer)
		}
	}, [selectedColorWay, isInitialLoad, productId])

	useEffect(() => {
		setActiveProductImage({
			url: 'https://d1tg5gdu842vo5.cloudfront.net/site-assets/images/product_images/' + selectedColorWay + '_main.webp',
			backgroundUrl: 'https://d1tg5gdu842vo5.cloudfront.net/site-assets/images/product_images/' + selectedColorWay + '_background.webp',
			showThumbnail: true,
			alt: 'Kibu Headphones Default Variant',
		})
	}, [selectedColorWay])

	const getColourwayName = (base: string, full: string): string => {
		if (full.startsWith(base)) {
			const result = full.slice(base.length).trimStart()
			return result.startsWith("- ") ? result.slice(2).trimStart() : result
		}
		return full
	}

	const getActiveId = (optionId: string) => {
		const foundItem = addonsSelectedItemIds.find((item) => item.hasOwnProperty(optionId))
		return foundItem ? foundItem[optionId] : null
	}

	const handleAddonSelect = (optionId: string, value: string | null) => {
		// If selecting the custom colors option, open the editor immediately (no delay)
		const selectedOption = options.find(opt => opt.productCatalogueId === optionId)
		const selectedProduct = selectedOption?.options.find(opt => opt._id === value)

		// If clicking the custom colors option when it's already selected, reopen the editor
		if (value === selectedColorWay && selectedProduct?.optionTitle?.toLowerCase().includes('custom')) {
			setIsInitialLoad(false)  // Ensure this is marked as user selection
			setCustomColoursOpen(true)
			return
		}

		// If selecting the custom colors option for the first time
		if (selectedProduct?.optionTitle?.toLowerCase().includes('custom')) {
			setIsInitialLoad(false)  // Ensure this is marked as user selection
			setCustomColoursOpen(true)
			return
		}

		setAddonsSelectedItemIds((prevItems) =>
			prevItems.map((item) => (item.hasOwnProperty(optionId) ? { [optionId]: value } : item))
		)
	}

	const handleAddonInputChange = (optionId: string, itemId: string, inputValue: string) => {
		const newValue: AddonInputValue = {
			optionId: optionId,
			itemId: itemId,
			inputValue: inputValue
		}
		const newValues: AddonInputValue[] = [
			...addonsInputValues.filter(aiv => aiv.optionId !== optionId || aiv.itemId !== itemId),
			newValue
		]
		setAddonsInputValues(newValues)
	}

	useEffect(() => {
		let price: number = 0
		let currency: string = ''
		const activeAddonOptionIds: string[] = addonsSelectedItemIds.flatMap(item =>
			Object.values(item).filter((value): value is string => value !== null)
		)

		for (const option of options) {
			for (const product of option.options) {
				if (product._id === selectedColorWay) {
					price += product.price?.unit_amount ?? 0
					currency = currencySymbol
				} else if (activeAddonOptionIds.includes(product._id)) {
					price += product.price?.unit_amount ?? 0
					currency = currencySymbol
				}
			}
		}

		setCurrentPrice(price)
	}, [selectedColorWay, addonsSelectedItemIds])

	const getSelectedAddonsAsProduct = (ids: string[]): IProduct[] => {
		const products: IProduct[] = []

		for (const option of options) {
			for (const product of option.options) {
				if (ids.includes(product._id)) {
					products.push(product)
				}
			}
		}

		return products
	}

	const handleConfiguratorChoice = (openConfigurator: boolean) => {
		setShowConfiguratorModal(false)
		if (openConfigurator) {
			setCustomColoursOpen(true)
			// Track start of customization
			if (globalCustomColourProduct) {
				trackStartCustomization(globalCustomColourProduct, chosenRegion)
			}
		}
	}

	useEffect(() => {
		if (customColoursOpen) {
			toggleOverlay(true)
			setNavVisibilityOverride(false)
			const handleKeyDown = (e: KeyboardEvent) => {
				if (e.key === 'Escape' && validateCustomComponents()) {
					setCustomColoursOpen(false)
				}
			}
			document.addEventListener('keydown', handleKeyDown)
			return () => {
				document.removeEventListener('keydown', handleKeyDown)
				setNavVisibilityOverride(null)
			}
		} else {
			toggleOverlay(false)
			setNavVisibilityOverride(null)
		}
	}, [customColoursOpen])

	const handleCustomColourClose = () => {
		// Reset to first colourway when custom colours are closed without completing
		if (colourWays.length > 0) {
			const firstNonCustomColourway = colourWays.find(cw => !cw.triggersSpecialSelect)
			if (firstNonCustomColourway) {
				setSelectedColorWay(firstNonCustomColourway.id)
			}
		}
		setCustomColoursOpen(false)
	}

	const handleCustomColourComplete = () => {
		// Set the custom colourway as selected when completing
		const customColourway = colourWays.find(cw => cw.triggersSpecialSelect)
		if (customColourway && globalCustomColourProduct) {
			setSelectedColorWay(customColourway.id)
			// Track completion of customization
			trackCompleteCustomization(globalCustomColourProduct, globalCustomComponents, chosenRegion)
		}
		setCustomColoursOpen(false)
	}

	const handleAddToBasket = () => {
		const colourWay: IProduct | undefined = options[0].options.find(cw => cw._id === selectedColorWay)
		if (!colourWay) {
			return
		}

		const selectedOptionIds: string[] = addonsSelectedItemIds.flatMap(item =>
			Object.values(item).filter((value): value is string => value !== null)
		)
		const selectedOptions: IProduct[] = getSelectedAddonsAsProduct(selectedOptionIds)
		let price: number = colourWay.price?.unit_amount ?? 0
		selectedOptions.forEach(option => {
			price += option.price?.unit_amount ?? 0
		})

		const basketItem: IBasketItem = {
			id: uuidv4(),
			name: colourWay.productTitle,
			colourWay: colourWay,
			price: price,
			extras: selectedOptions,
			extrasData: addonsInputValues
		}

		addItemToBasket(basketItem)
		// Track AddToCart event
		trackAddToCart(basketItem, chosenRegion)
		setBasketOpen(true)
	}

	const hasStringModification = (partsModifications: IPartsModifications): boolean => {
		const result: boolean = Object.values(partsModifications).some(modification => modification.type === "string")
		return result
	}

	const getScrollOffsetY = (): number => window.scrollY

	const validateCustomComponents = (): boolean => {
		return globalCustomComponents.every(component => component.chosenMaterial.$oid !== '')
	}

	// Add this effect to handle body scroll locking
	useEffect(() => {
		if (showConfiguratorModal) {
			document.body.style.overflow = 'hidden'
		} else {
			document.body.style.overflow = 'auto'
		}

		return () => {
			document.body.style.overflow = 'auto'
		}
	}, [showConfiguratorModal])

	const formattedUpdateDate = countryDetails?.returns?.updateDate ? format(new Date(countryDetails.returns.updateDate), "do MMMM yyyy") : '28th January 2025'

	return (
		<div className='shop-animation-container'>
			<div className={`shop-container flex flex-col items-center pb-16`}>
				<div
					className={`nav-section w-full sticky -top-[30px] -mt-[30px] z-40 bg-white transition-all ease-hypereaseout 
						${customColoursOpen ? 'duration-[2400ms] opacity-70 blur-md' : 'duration-[2000ms] opacity-100 blur-none'}`}>
					<div
						className='transition-all'>
						<div className="h-[100px] w-full bg-white border-b-[2px] border-grey flex justify-center pt-[30px]">
							<div className='standard_container flex !flex-row justify-between items-center md:pl-8 md:pr-8'>
								<div className="flex items-center gap-2 md:gap-4">
									{isLoading ? (
										<div className='price text-orange text-2xl md:text-3xl md:w-20 w-12 flex items-center justify-center'>
											<LoadingRing size="sm" />
										</div>
									) : (
										<h3 className='price text-orange text-2xl md:text-3xl md:w-20 w-12'>
											{currencySymbol}{currentPrice}
										</h3>
									)}
									<SelectedItems
										colourWay={options[0]?.options.find(cw => cw._id === selectedColorWay) || null}
										selectedAddons={getSelectedAddonsAsProduct(addonsSelectedItemIds.flatMap(item =>
											Object.values(item).filter((value): value is string => value !== null)
										))}
										maxItems={3}
									/>
								</div>
								<button
									className={`flex items-center p-2 md:pl-4 md:pr-4 bg-orange justify-center w-min-content rounded-md font-headline md:text-lg text-white leading-none
										transition-all ease-kibu duration-200 items-center cursor-pointer border border-2 border-transparent
										hover:rounded-lg hover:brightness-105 hover:border-black/10 hover:scale-[1.02]
										${isLoading ? 'opacity-50 cursor-not-allowed hover:scale-100 hover:brightness-100' : ''}`}
									onClick={() => handleAddToBasket()}
									disabled={isLoading}
								>
									{isLoading ? 'Loading...' : 'Add to Basket'}
								</button>
							</div>
						</div>
					</div>
				</div>

				<div
					className={`main-container standard_container gap-0
						${customColoursOpen ? 'scale-[0.95] blur-sm du duration-[1400ms] ease-hypereaseout' : 'scale-[1.00] duration-1000 ease-hypereaseout'}`}
					style={{ marginTop: '1em' }}>
					<div className="w-full md:flex-grow md:sticky md:top-[90px] self-start">
						{isLoading ? (
							<div className="flex items-center justify-center aspect-[4/4.25]">
								<LoadingRing size="lg" />
							</div>
						) : (
							<div className='relative'>
								<ImageSlideshow
									images={productsData[0].images}
									dynamicImage={activeProductImage}
									className="flex flex-col w-full"
									showEditColors={selectedColorWay === globalCustomColourProduct?._id}
									onEditColors={() => setCustomColoursOpen(true)}
									onAllImagesLoaded={() => setImagesLoaded(true)}
								/>
								<div className="slideshow-shipping-info absolute w-min text-nowrap top-3 left-3 font-headline flex items-center gap-1 bg-white rounded-lg py-2 px-3">
									<div className="overflow-hidden">
										<Truck className="h-4 w-4 animate-truck-animation" />
									</div>{' '}
									Made & shipped within 5 days
								</div>
							</div>
						)}
					</div>
					<div id="product-info" className="information-container w-full md:max-w-[600px] pt-2 md:pt-20 flex flex-col gap-5">
						<ProductInfo _product={productsData[0]} currentPrice={currentPrice} currency={currencySymbol} className="mb-4" isLoading={isLoading} />

						{isLoading ? (
							<>
								<AddonSkeleton />
								<AddonSkeleton />
								<AddonSkeleton />
								<AddonSkeleton />
							</>
						) : (
							<>
								{/* Required colourway addon always first */}
								{options.find(option => option.required) && (
									<Addon
										title={options.find(option => option.required)?.title || "Choose your colourway"}
										required
										description={null}
										items={colourWays}
										activeId={selectedColorWay}
										onSpecialSelect={() => handleConfiguratorChoice(true)}
										onSelect={(id) => {
											// If clicking the custom colors option when it's already selected
											if (id === selectedColorWay) {
												const selectedProduct = options.find(opt => opt.required)?.options.find(opt => opt._id === id)
												if (selectedProduct?.optionTitle?.toLowerCase().includes('custom')) {
													setIsInitialLoad(false)
													setCustomColoursOpen(true)
													return
												}
											}
											// Otherwise handle normal selection
											if (id) setSelectedColorWay(id)
										}}
										info={options.find(option => option.required)?.info}
									/>
								)}

								{/* Other addons in order */}
								{options.map((option: IOption) => {
									if (option.required) {
										return null
									}
									const addonItems: IAddonItem[] = option.options.map((product: IProduct) => {
										const isEngravingOption = option.productCatalogueId === '67519d69197065b8a54a327e'
										const engravingConfig = product.componentModifications?.engravingText

										return {
											id: product._id,
											imageUrl: '/assets/images/product_thumbnails/' + product._id + '.webp',
											title: product.optionTitle || '',
											price: product.price?.unit_amount ?? 0,
											currency: currencySymbol,
											triggerInput: isEngravingOption,
											inputPlaceholder: isEngravingOption ? 'Enter your engraving text' : undefined,
											inputMaxLength: engravingConfig?.maxLength || 14,
											inputPattern: '[A-Za-z0-9\\s.,!?\\-\'/]*',
											inputClassName: 'w-full px-4 py-2 mt-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange focus:border-transparent font-body',
											inputValidation: (value: string) => {
												if (!value) return true
												return /^[A-Za-z0-9\s.,!?\-'/]+$/.test(value)
											}
										}
									})
									return <Addon
										title={option.title}
										key={option.productCatalogueId}
										description={null}
										items={addonItems}
										activeId={getActiveId(option.productCatalogueId)}
										onSelect={(id) => handleAddonSelect(option.productCatalogueId, id)}
										onInputChange={(itemId: string, inputValue: string) => {
											// Only allow valid characters
											if (option.productCatalogueId === '67519d69197065b8a54a327e') {
												const validInput = inputValue.replace(/[^A-Za-z0-9\s.,!?\-'/]/g, '')
												handleAddonInputChange(option.productCatalogueId, itemId, validInput)
											} else {
												handleAddonInputChange(option.productCatalogueId, itemId, inputValue)
											}
										}}
										info={option.info}
									/>
								})}
							</>
						)}

						<div className="flex flex-col gap-4">
							<button
								className={`flex items-center p-4 bg-orange justify-center w-full rounded-xl font-headline text-white leading-none text-2xl
									transition-all ease-kibu duration-200 items-center cursor-pointer border border-2 border-transparent
									hover:rounded-2xl hover:shadow-button-hover hover:brightness-105 hover:border-black/10 hover:scale-[1.02]
									${isLoading ? 'opacity-50 cursor-not-allowed hover:scale-100 hover:brightness-100 hover:shadow-none' : ''}`}
								onClick={() => handleAddToBasket()}
								disabled={isLoading}
							>
								{isLoading ? 'Loading...' : 'Add to Basket'}
							</button>
							<PaymentMethods />
							<div className='flex flex-col gap-2'>
								<div className="flex flex-col gap-2 bg-grey p-5 rounded-2xl">
									<div className="flex flex-row gap-2 items-center">
										<Truck className="text-black" />
										<h4 className="font-body text-base">Free Delivery Over {currencySymbol}{(freeDeliveryThreshold ?? 5000) / 100}</h4>
										<InfoButton title="Delivery Information" text="<p/><p>We want to make sure your Kibu headphones arrive safely and as quickly as possible! Here's what you need to know about delivery:</p><ul><li><strong>Free delivery:</strong> If you spend over £50, delivery is free! Otherwise, it's just £3.</li><li><strong>Standard delivery:</strong> Takes <strong>3–5 business days</strong> and starts right after your headphones are made.</li><li><strong>Faster delivery:</strong> Need them quicker? Choose our faster delivery option for £10, and your order will arrive in <strong>1–2 business days.</strong></li></ul><h3>Why Production?</h3><p>When you order Kibu headphones, we make them just for you using special 3D printers. This way, nothing goes to waste, and every pair is created fresh and ready to be built by you! Production usually takes <strong>5 working days</strong> before we send your headphones out for delivery.</p><p>So whether you go for standard or faster delivery, your headphones are worth the wait—they're made with care and built just for you!</p>" />
									</div>
									{countryDetails?.returns?.active && (
										<div className="flex flex-row gap-2 items-center">
											<Undo2 className="text-black" />
											{countryDetails?.returns?.active ? (
												<h4 className="font-body text-base">{countryDetails.returns.returnDays}-Day Returns</h4>
											) : (
												<h4 className="font-body text-base">${countryDetails?.returns?.returnDays || 20}-Day Returns</h4>
											)}
											<InfoButton
												title="Returns"
												text={`
												<div>
													<p><strong>Last Updated: ${formattedUpdateDate}</strong></p>
													<p>Returns accepted within ${countryDetails?.returns?.returnDays || 20} days of delivery. Conditions apply.</p>
													<p>The product must be undamaged, with its original packaging, and with all the original contents.</p>
													<p>Email us at <a href="mailto:returns@kibu.family" class="text-primary hover:underline">returns@kibu.family</a></p>
													<p class="mt-4">
														<a 
														href="/legal/delivery-and-returns"
														class="text-primary hover:underline"
													>
														View our full returns policy for conditions →
													</a>
													</p>
												</div>
											`}
											/>
										</div>
									)}
								</div>
								<div className="flex items-center gap-3 border border-1 border-dashed border-[#494949] rounded-2xl p-2 pl-4 pr-4">
									<AlertTriangleIcon className="text-[#494949] w-24 h-24 md:w-12 md:h-12 stroke-2" />
									<p className="font-medium leading-[18px]">
										WARNING! Choking Hazard. Small parts, not for children under 3 yrs. Long cable. Strangulation hazard. For use under adult supervision.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<ScrollingGallery
					images={galleryImages}
					communityContent={{
						title: {
							prefix: "Kibu",
							suffix: " is loved!"
						},
						description: "The #KibuHeadphones are loved by kids and parents alike. Share your photos and videos with us on Instagram and tag @kibu.family for a chance to be featured!",
						ctaText: "Instagram",
						ctaLink: "https://www.instagram.com/kibu.family/"
					}}
					className="mt-16 bg-background"
				/>
				<Testimonials id='testimonials' className='mt-16' />
				<DrawerModule
					moduleTitle="Headphones Kids Build, Repair, and Love"
					items={kibuDrawers}
					defaultMedia={{
						type: 'video',
						url: 'https://d1tg5gdu842vo5.cloudfront.net/site-assets/video/build_clips.mp4',
						posterUrl: 'https://d1tg5gdu842vo5.cloudfront.net/site-assets/images/placeholders/build_clips_placeholder.jpg',
						alt: 'Baking overview'
					}}
					className="my-16"
				/>

				<ComparisonModule
					title="Why Choose Kibu?"
					subtitle="See how Kibu headphones compare to standard children's headphones"
				/>
				<SpotlightSplitModule
					media={awardWinning.media}
					content={awardWinning.content}
					backgroundColor="bg-specialYellow"
					theme="light"
					button={false}
				/>

				{/* <div className='product-cards standard_container flex flex-col gap-4 mt-16'>
					<ProductDetailsCard backgroundUrl={'/assets/images/product_detail_cards/boy-building.webp'} align='right' title='Learn from building'>
						<div>
							Kibu headphones offer more than just great sound; they're a hands-on learning experience. Kids and parents can build them together, sparking curiosity and teaching how things work in a fun, engaging way.
							With each simple step, kids gain confidence and develop problem-solving skills, creating memories and a sense of achievement. Plus, they'll learn that when things break, they can fix them—a lesson in responsibility and sustainability.
						</div>
					</ProductDetailsCard>
				</div>
				<div className='product-cards standard_container flex flex-col gap-4 mt-16'>
					<ProductDetailsCard
						backgroundUrl={'/assets/images/product_detail_cards/children-listening.webp'}
						align='left'
						title={'Connect together.\nListen together.'}
					>
						<div>
							With Kibu headphones, sharing audio is easy and fun. Kids can connect their headphones to friends' or siblings' headphones, creating a shared listening experience for music, audiobooks, or games.
						</div>
						<div>
							It's perfect for playdates, car trips, and cozy family time—bringing everyone closer through the joy of listening together.
						</div>
					</ProductDetailsCard>
				</div>
				<div className='product-cards standard_container flex flex-col gap-4 mt-16'>
					<ProductDetailsCard
						backgroundUrl={'/assets/images/product_detail_cards/girl-building.webp'}
						align='right'
						title={'Built to Last,\nSimple to Fix'}
					>
						<div>
							When things break, they shouldn't need to be thrown away. Kibu headphones are designed to be fixed as easily as they're built—no tools, glue, or fiddly parts needed. Just snap in a new piece and keep listening, teaching kids resilience and sustainability along the way.
						</div>

					</ProductDetailsCard>
				</div> */}
				<ProductSpecs id="productspecs" />

			</div>
			{imagesLoaded && (
				<CustomColoursContainer
					open={customColoursOpen}
					customColourProduct={globalCustomColourProduct}
					customComponents={globalCustomComponents}
					updateCustomComponent={updateCustomComponent}
					onClose={handleCustomColourClose}
					onComplete={handleCustomColourComplete}
					navHeight={navHeight}
				/>
			)}
			{showConfiguratorModal && (
				<div
					className="fixed inset-0 bg-black bg-opacity-50 z-[1000] flex items-center justify-center p-4"
					style={{
						position: 'fixed',
						top: 0,
						left: 0,
						height: '100vh',
						width: '100vw',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					<div
						className="bg-white rounded-2xl p-6 max-w-md w-full shadow-lg"
						style={{
							transform: 'translateY(calc(-50vh + 100% + ' + navHeight + 'px))',
							margin: 'auto'
						}}
					>
						<h2 className="text-2xl font-headline mb-4">Ready to customize?</h2>
						<p className="text-gray-600 mb-6">
							Would you like to start customizing your headphones now, or continue reading about the product?
						</p>
						<div className="flex flex-col gap-3">
							<button
								onClick={() => handleConfiguratorChoice(true)}
								className="w-full bg-orange text-white py-3 px-6 rounded-xl font-headline text-lg
									transition-all duration-200 ease-kibu hover:brightness-105 hover:scale-[1.02]"
							>
								Start Customizing
							</button>
							<button
								onClick={() => handleConfiguratorChoice(false)}
								className="w-full bg-gray-100 text-gray-700 py-3 px-6 rounded-xl font-headline text-lg
									transition-all duration-200 ease-kibu hover:bg-gray-200"
							>
								Continue Reading
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

export default ProductPage
