import { SpotlightMedia, SpotlightContent } from '../types/spotlight.types';
import { buildRegionUrl } from '@/utils/regionDetection';
import useRegionStore from '@/context/RegionStore';

// Helper function to get region-aware link
const getRegionLink = (path: string): string => {
    // Get the current region from the store
    const { chosenRegion } = useRegionStore.getState();
    return buildRegionUrl(chosenRegion || 'gb', path);
};

export const buildSpotlight: { media: SpotlightMedia; content: SpotlightContent } = {
    media: {
        type: 'video',
        url: 'https://d1tg5gdu842vo5.cloudfront.net/site-assets/video/last-kids-headphones.mp4',
        posterUrl: '/assets'
    },
    content: {
        title: "The Last Kids' Headphones You'll Ever Need.",
        description: 'Build them. Repair them. Never replace them.',
        ctaText: 'Learn more',
        ctaLink: () => getRegionLink('products/wired-headphones-1#testimonials')
    }
};

export const sustainabilitySpotlight: { media: SpotlightMedia; content: SpotlightContent } = {
    media: {
        type: 'image',
        url: 'https://d1tg5gdu842vo5.cloudfront.net/site-assets/images/headphones-in-context/girl_and_woman_listening.jpg',
        alt: 'Girl and woman laughing as they listen to music outside on a bench'
    },
    content: {
        title: 'Better for children, better for the planet.',
        description: "Made to be put together and easy to fix, Kibu headphones the most sustinable children's headphones that are built for kids to enjoy and designed to stay out of the bin.",
        ctaText: 'Learn more',
        ctaLink: () => getRegionLink('products/wired-headphones-1#testimonials')
    }
};

export const awardWinning: { media: SpotlightMedia; content: SpotlightContent } = {
    media: {
        type: 'video',
        url: 'https://d1tg5gdu842vo5.cloudfront.net/site-assets/video/award_dnd.mp4',
        posterUrl: '/assets'
    },
    content: {
        title: 'Award-Winning Design',
        description: "Our headphones have won the D&AD Pencil Award.",
        ctaText: 'Learn more',
        ctaLink: () => getRegionLink('products/wired-headphones-1')
    }
}; 