import { ArrowDown, ArrowUp, AlertTriangleIcon } from 'lucide-react'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { buildRegionUrl, getRegionFromUrl } from '@/utils/regionDetection'
import { Link } from 'react-router-dom'
import useRegionStore from '@/context/RegionStore'

type FaqItem = {
  id: number
  question: string
  answer: string | JSX.Element
  warning?: string
  open: boolean
}

interface ProductSpecsProps {
  id: string,
  className?: string
}


const ProductSpecs: React.FC<ProductSpecsProps> = ({ id, className }) => {

  const { chosenRegion } = useRegionStore()
  const [faq, setFaq] = useState<FaqItem[]>([
    {
      id: 0,
      question: 'Who can use Kibu headphones?',
      answer: `Kibu headphones are perfect for curious minds aged 4 and up! Whether you're just starting primary school or heading into your teens, these headphones will grow with you.Little builders will need a grown- up's helping hands to put them together - it's a brilliant chance to create something amazing together!`,
      warning: `WARNING! Contains small parts that could cause choking - not for children under 3 years old. The cable is long and could be dangerous. Always use with a grown-up nearby.`,
      open: false
    },
    {
      id: 1,
      question: 'Will Kibu headphones fit comfortably?',
      answer: `Just like your favourite stretchy jumper, these headphones bend and adjust to fit your head perfectly! We've made the headband super flexible, and the ear cushions are as soft as pillows. They're made from gentle materials that feel nice against your skin, so you can wear them comfortably whether you're listening to a short story or having an all-day adventure.`,
      open: false
    },
    {
      id: 2,
      question: 'How do we build the headphones?',
      answer: `Building your Kibu headphones is like following a cooking recipe, but instead of making food, you're creating your very own headphones! We've made a brilliant video that shows you every step, just like when you're baking with someone in the kitchen. Pop it up on your phone or computer, and build along together - you'll be amazed to see how headphones actually work!`,
      open: false
    },
    {
      id: 3,
      question: 'What if something breaks?',
      answer: `Don't worry if something goes wonky - it happens to the best of us! Just like fixing a favourite toy, we can help get your headphones back to perfect working order. Send our friendly team a message, and we'll see what we can do to help. There might be a small cost for the new piece and posting it to you, but fixing is always better than throwing away!`,
      open: false
    },
    {
      id: 4,
      question: 'How do Kibu headphones keep ears safe?',
      answer: `Inside each pair of Kibu headphones lives a clever guardian called the Kibu Safety Engine. It's like having a friendly volume control that makes sure the sound stays at just the right level - never too loud for your precious ears. No matter what you're listening to, our Safety Engine keeps the volume at a safe and cosy 85 decibels (that's about as loud as your kitchen mixer).`,
      open: false
    },
    {
      id: 5,
      question: 'Are these headphones safe for my little one?',
      answer: `We've put safety right at the top of our priority list, just like you do! The headphones are tough but gentle, like a protective big sister or brother. They're built to last and won't make sounds too loud. For our youngest explorers, we recommend having a grown - up nearby during building time and playtime to make sure everything stays fun and safe.`,
      warning: `WARNING! Contains small parts that could cause choking - not for children under 3 years old. The cable is long and could be dangerous. Always use with a grown-up nearby.`,
      open: false
    },
    {
      id: 6,
      question: 'What can we plug Kibu headphones into?',
      answer: `Your Kibu headphones come with a special connector (it's called a 3.5mm jack) that's like a universal key - it fits into many devices! Some devices though don't have a 3.5mm jack, so you may need to use an adapter.`,
      open: false
    },
    {
      id: 7,
      question: 'How are Kibu headphones kind to our planet?',
      answer: `Here's something amazing - your Kibu headphones are made from special plant-based plastic that comes from plants like corn! It's like magic: we turn plants into durable headphones that are gentler on our Earth than regular plastic ones. When they've finished their journey with you, they can be disposed of responsibly. Remember though - even though they're made from plants, they're definitely not for eating!`,
      open: false
    },
    {
      id: 8,
      question: 'Can friends listen together?',
      answer: `Yes! Your Kibu headphones have a brilliant sharing feature - it's like creating a listening chain with your friends. Each pair has a special port where you can plug in another set of headphones, like joining hands in a circle. Keep adding more pairs to share the fun with everyone in your group!`,
      open: false
    },
    {
      id: 10,
      question: 'What makes Kibu headphones special?',
      answer: `Imagine having headphones that are like a favourite teddy bear - built to last and ready for countless adventures! When you build your own Kibu headphones, you're not just getting something to listen with - you're becoming a young scientist and engineer. They're like a friend to your ears, keeping the volume just right, and a friend to our planet too, made from Earth - friendly materials. Plus, if anything ever goes wrong, we can fix them together instead of throwing them away!`,
      open: false
    },
    {
      id: 11,
      question: 'Where do you deliver to?',
      answer: <>Right now, we're delivering our magical headphone kits all across the United Kingdom - from the tippy top of Scotland to the very bottom of Cornwall! To learn more about delivery costs and how to return items if needed, pop over to our <Link to={`/legal/delivery-and-returns`}>delivery and returns page</Link>.</>,
      open: false
    },
    {
      id: 12,
      question: 'How do we keep our headphones clean and happy?',
      answer: `Looking after your Kibu headphones is as easy as washing your hands! Just use a soft, slightly damp cloth to wipe away any fingerprints or dust - like giving them a gentle bath. Don't use any strong cleaning potions, and keep water away from the parts that make the sound.Give them a quick check now and then to make sure they're still comfy and working their best.`,
      open: false
    },
    {
      id: 13,
      question: 'Are they good for adventures and travelling?',
      answer: `Absolutely brilliant for adventures big and small! Your Kibu headphones are tough enough for car journeys, aeroplane trips, and everyday exploring. For extra protection on big adventures, you might want to grab our special Protective Cover.`,
      open: false
    },
    {
      id: 14,
      question: 'What do we do with the headphones when we\'re finished with them ? ',
      answer: `When your Kibu headphones have danced their last dance, don't pop them in your regular recycling bin - send them back to us instead! It's like giving them a ticket to their next adventure. We'll make sure all the special materials get recycled properly, helping them transform into something new. It's a bit like a butterfly emerging from its cocoon!`,
      open: false
    }
  ]);

  const toggleFaqItem = (id: number) => {
    setFaq(prev =>
      prev.map(item =>
        item.id === id ? { ...item, open: !item.open } : { ...item, open: false }
      ))
  }
  return (
    <div className={`w-full mt-48 ${className}`}>
      <div className="w-full flex justify-center">
        <img src='/assets/graphics/spec-top.svg' className='w-full translate-y-[1px]' alt="Decorative top shape" />
      </div>
      <div className="w-full bg-[#353535] text-white py-16 flex items-center justify-center">
        <div className='standard_container'>
          <div className='flex flex-col gap-24'>
            <div className='flex flex-col gap-12 w-full'>
              <h2 className='text-left text-5xl'>Specifications</h2>
              <div className='relative flex flex-col gap-8 md:flex-row md:gap-14'>
                <div className='relative flex gap-6 leading-none md:gap-12 md:w-3/5'>
                  <div className='flex flex-col gap-2 w-full'>
                    <h3 className='text-white text-xl'>Specifications</h3>
                    <div className='flex flex-col gap-2'>
                      <p>Dimensions: 24 x 21 x 3.5cm (Box)</p>
                      <p>Weight: 125grams (Built)</p>
                      <p>Stereo Sound</p>
                      <p>3.5mm Wired</p>
                      <p>Volume Limits to 85db +/- 4db</p>
                      <p>1.2m Device Aux Cable Length</p>
                      <p>Dual 3.5mm Aux Port</p>
                      <p>Daisy Chain Capable</p>
                    </div>
                  </div>
                  <div className='flex flex-col gap-2 w-full'>
                    <h3 className='text-white text-xl'>Box Contents</h3>
                    <div className='flex flex-col gap-2'>
                      <p>Headphone Parts</p>
                      <p>0.45m Headband Aux Cable</p>
                      <p>1.2m Device Aux cable</p>
                    </div>
                  </div>
                </div>
                {/* <div className='top-down-box min-h-24 bg-grey rounded-md overflow-hidden md:w-2/5'>
                </div> */}
              </div>
            </div>
            <div className='flex flex-col gap-12 w-full'>
              <h2 className='text-left text-5xl'>Frequently Asked Questions</h2>
              <div className='faq-items flex flex-col gap-2 group/faqcontainer'>
                {faq.map(faqItem => {
                  const isAnyOpen = faq.some(item => item.open);
                  return (
                    <div className={`faqItem border border-0 border-b-2 border-white py-2 mb-4 flex flex-col gap-2 group/faqitem transition-opacity duration-200 
                      ${faqItem.open
                        ? 'opacity-100 group-hover/faqcontainer:peer-hover/faqitem:opacity-50'
                        : isAnyOpen
                          ? 'opacity-50 hover:opacity-100'
                          : 'opacity-100'
                      } peer/faqitem`} key={faqItem.id}>
                      <button
                        className={`w-full relative flex justify-between items-center transition-all duration-200 top-0 ease-kibushopdefault ${faqItem.open ? 'top-1' : 'group-hover/faqitem:top-1'}`}
                        onClick={() => toggleFaqItem(faqItem.id)}>
                        <h3 className='text-xl text-left'>{faqItem.question}</h3>
                        {faqItem.open ?
                          <ArrowUp className='h-6 w-6 min-w-6' strokeWidth={3} />
                          : <ArrowDown className='h-6 w-6 min-w-6' strokeWidth={3} />
                        }
                      </button>
                      <div className={`faq-answer overflow-hidden transition-all duration-500 ease-kibushopdefault mt-1 mb-2 whitespace-pre-line ${faqItem.open ? 'opacity-100 max-h-[800px] mt-6 mb-6' : 'opacity-0 max-h-0'}`}>
                        <div className='text-xl'>{faqItem.answer}</div>
                        {faqItem.warning && (
                          <div className="flex items-center gap-3 border border-1 border-dashed border-white rounded-2xl p-4 mt-5 max-w-[750px]">
                            <AlertTriangleIcon className="text-white w-24 h-24 md:w-8 md:h-8 stroke-2" />
                            <p className="font-medium leading-[18px] text-white">
                              {faqItem.warning}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center">
        <img src='/assets/graphics/spec-bottom.svg' className='w-full -translate-y-[1px]' alt="Decorative bottom shape" />
      </div>
    </div>
  )
}

const shape: JSX.Element = (
  <svg width="1808" height="1177" viewBox="0 0 1808 1177" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1808 82.5391C1543.45 30.1143 1234.29 0 904 0C573.707 0 264.555 30.1143 0 82.5391V1094.46C264.555 1146.89 573.707 1177 904 1177C1234.29 1177 1543.45 1146.89 1808 1094.46V82.5391Z"
      fill="#353535"
    />
  </svg>
)

export default ProductSpecs