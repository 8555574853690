import React, { useState, useEffect } from 'react'
import DynamicHeadphones from './customColours/DynamicHeadphones'
import { IProduct, IComponent, ICustomComponent } from '@/context/ProductDataStore'
import useProductDataStore from '@/context/ProductDataStore'
import useBasketStore from '@/context/BasketStore'
import { X } from 'lucide-react'

interface CustomColoursContainerProps {
  open: boolean
  customColourProduct: IProduct | undefined
  customComponents: ICustomComponent[]
  updateCustomComponent: (componentName: string, materialId: string, position: string | null) => void
  onClose: () => void
  onComplete: () => void
  navHeight?: number
  basketItemId?: string
}

const CustomColoursContainer: React.FC<CustomColoursContainerProps> = ({
  open,
  customColourProduct,
  customComponents,
  updateCustomComponent,
  onClose,
  onComplete,
  navHeight,
  basketItemId,
}) => {
  const [showColourPicker, setShowColourPicker] = useState(false)
  const [colourPickerPos, setColourPickerPos] = useState({ x: 0, y: 0 })
  const [colourPickerPartName, setColourPickerPartName] = useState<string | null>(null)
  const [colourPickerPosition, setColourPickerPosition] = useState<string | null>(null)
  const [availableMaterials, setAvailableMaterials] = useState<string[]>([])
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const materialInformation = useProductDataStore(state => state.materialInformation)
  const { updateBasketItem } = useBasketStore()

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden'
      // Lock the scrollable container
      const scrollContainer = document.querySelector('.overflow-y-auto')
      if (scrollContainer) {
        scrollContainer.classList.add('overflow-hidden')
      }
      window.scrollTo(0, 0)
    } else {
      document.body.style.overflow = ''
      // Unlock the scrollable container
      const scrollContainer = document.querySelector('.overflow-y-auto')
      if (scrollContainer) {
        scrollContainer.classList.remove('overflow-hidden')
      }
    }

    return () => {
      document.body.style.overflow = ''
      // Ensure we unlock the scrollable container on cleanup
      const scrollContainer = document.querySelector('.overflow-y-auto')
      if (scrollContainer) {
        scrollContainer.classList.remove('overflow-hidden')
      }
    }
  }, [open])

  useEffect(() => {
    if (colourPickerPartName && customColourProduct?.components) {
      const component = customColourProduct.components.find(
        (comp: ICustomComponent) => comp.name === colourPickerPartName && comp.position === colourPickerPosition
      )
      setAvailableMaterials(component?.materialOptions || [])
    }
  }, [colourPickerPartName, colourPickerPosition, customColourProduct])

  const toggleColourPicker = (partName: string | null, mousePos: { x: number, y: number }, position: string | null = null) => {
    // console.log('toggleColourPicker called with:', { partName, mousePos, position })
    if (partName) {
      // If clicking the same part that's already selected, keep the picker open
      if (partName === colourPickerPartName && position === colourPickerPosition) {
        return
      }

      setColourPickerPos({ x: mousePos.x, y: mousePos.y })
      setColourPickerPartName(partName)
      setColourPickerPosition(position)
      setShowColourPicker(true)
      console.log('colourPickerDetails...', {
        navHeight,
        colourPickerPos: { x: mousePos.x, y: mousePos.y },
        screenHeight: window.innerHeight,
        maxAllowedTop: window.innerHeight - 200
      })

      // Log the component we found
      if (customColourProduct) {
        const component = (customColourProduct.components as ICustomComponent[]).find(
          (comp: ICustomComponent) => comp.name === partName && comp.position === position
        )
        console.log('Found component:', component)
      }
    } else {
      setShowColourPicker(false)
      setColourPickerPosition(null)
    }
  }

  const handleMaterialSelect = (materialId: string) => {
    if (!colourPickerPartName || !customColourProduct?.components) return

    console.log('customColourBasketCheck: Starting material select', {
      materialId,
      colourPickerPartName,
      colourPickerPosition,
      basketItemId,
      currentComponents: customComponents
    })

    // Update the component locally
    updateCustomComponent(colourPickerPartName, materialId, colourPickerPosition)

    // Update the product store with the new components
    const { product, options, setProductData, materialInformation } = useProductDataStore.getState()
    if (product && options) {
      console.log('customColourBasketCheck: Updating product store', {
        currentProduct: product,
        currentOptions: options
      })

      // Find and update the custom colour product in the options
      const updatedOptions = options.map(option => {
        return {
          ...option,
          options: option.options.map(opt => {
            if (opt._id === customColourProduct._id) {
              console.log('customColourBasketCheck: Found matching product in store', {
                productId: opt._id,
                updatedComponents: customComponents
              })
              return {
                ...opt,
                components: customComponents
              }
            }
            return opt
          })
        }
      })

      // Update the product store
      setProductData(product, updatedOptions, materialInformation)
      console.log('customColourBasketCheck: Updated product store')
    }

    // If this is being used to edit a basket item, update it
    if (basketItemId && customColourProduct) {
      // Find the component that was updated
      const updatedComponents = customComponents.map(comp => {
        if (comp.name === colourPickerPartName && comp.position === colourPickerPosition) {
          console.log('customColourBasketCheck: Updating component', {
            componentName: comp.name,
            position: comp.position,
            oldMaterial: comp.chosenMaterial,
            newMaterial: materialId
          })
          return {
            ...comp,
            chosenMaterial: { $oid: materialId }
          }
        }
        return comp
      })

      console.log('customColourBasketCheck: Updating basket item', {
        basketItemId,
        updatedComponents,
        fullUpdate: {
          ...customColourProduct,
          components: updatedComponents
        }
      })

      // Create a complete copy of the product with updated components
      const updatedProduct = {
        ...customColourProduct,
        components: updatedComponents
      }

      console.log('customColourBasketCheck: Final product update', {
        basketItemId,
        updatedProduct
      })

      // Update the basket item with the complete colourWay
      try {
        updateBasketItem(basketItemId, {
          colourWay: updatedProduct
        })
        console.log('customColourBasketCheck: Successfully updated basket item')
      } catch (error) {
        console.error('customColourBasketCheck: Error updating basket item', error)
      }
    }
  }

  const handleClose = () => {
    setShowConfirmDialog(true)
  }

  const handleConfirmClose = () => {
    setShowConfirmDialog(false)
    onClose()
  }

  const handleCancelClose = () => {
    setShowConfirmDialog(false)
  }

  const isComplete = () => {
    return true  // Allow completion regardless of color selection state
  }

  const handleComplete = () => {
    console.log('customColourBasketCheck: Completing custom colours', {
      basketItemId,
      finalComponents: customComponents
    })

    // Update the product store with the final components
    const { product, options, setProductData, materialInformation } = useProductDataStore.getState()
    if (product && options && customColourProduct) {
      console.log('customColourBasketCheck: Final update to product store', {
        currentProduct: product,
        currentOptions: options,
        finalComponents: customComponents
      })

      // Find and update the custom colour product in the options
      const updatedOptions = options.map(option => {
        return {
          ...option,
          options: option.options.map(opt => {
            if (opt._id === customColourProduct._id) {
              return {
                ...opt,
                components: customComponents
              }
            }
            return opt
          })
        }
      })

      // Update the product store
      setProductData(product, updatedOptions, materialInformation)
      console.log('customColourBasketCheck: Successfully updated product store')
    }

    // If this is being used to edit a basket item, update it one final time
    if (basketItemId && customColourProduct) {
      console.log('customColourBasketCheck: Final basket update on complete', {
        basketItemId,
        components: customComponents
      })

      try {
        // Update the basket item with the final state
        updateBasketItem(basketItemId, {
          colourWay: {
            ...customColourProduct,
            components: customComponents
          }
        })
        console.log('customColourBasketCheck: Successfully updated basket item on complete')
      } catch (error) {
        console.error('customColourBasketCheck: Error updating basket item on complete', error)
      }
    }

    onComplete()
  }

  if (customColourProduct === undefined) {
    return null
  } else {
    return (
      <div className={`fixed w-screen h-screen custom-colours-container top-0 left-0 transition-bg flex items-center justify-center z-[9999] overflow-hidden
        ${open ? 'bg-black/10 duration-500 ease-hypereaseout max-h-none' : 'bg-transparent max-h-0 duration-200 ease-kibushopdefault pointer-events-none'}`}
        style={{ position: 'fixed', top: 0, left: 0, height: '100vh' }}>
        <div className={`relative w-full h-full bg-custom-colours-background/80 backdrop-blur-xl overflow-hidden
            ${open ? 'opacity-100 scale-100 duration-[300ms] delay-[300ms] ease-kibushopdefault' : 'opacity-0 scale-120 duration-100 ease-kibushopdefault pointer-events-none'}`}>
          <div className="absolute top-6 left-0 justify-center flex w-full items-center gap-3 z-[110]">
            <button
              type="button"
              onClick={() => handleClose()}
              className={`flex items-center p-2 md:pl-4 md:pr-4 bg-white justify-center w-min-content rounded-md font-headline md:text-lg text-black leading-none
                transition-all ease-kibu duration-200 items-center cursor-pointer border border-2 border-transparent
                hover:rounded-lg hover:brightness-105 hover:border-white/10 hover:scale-[1.02]`}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
              Close
            </button>
            <button
              type="button"
              onClick={() => isComplete() && handleComplete()}
              className={`flex items-center p-2 md:pl-4 md:pr-4 bg-orange justify-center w-min-content rounded-md font-headline md:text-lg text-white leading-none
                transition-all ease-kibu duration-200 items-center cursor-pointer border border-2 border-transparent
                hover:rounded-lg hover:brightness-105 hover:border-black/10 hover:scale-[1.02]`}
              disabled={!isComplete()}
            >
              Complete
            </button>
          </div>

          <div className="relative w-full h-full md:px-60 md:py-20">
            <DynamicHeadphones
              className={`${open ? '' : 'pointer-events-none'}`}
              viewName='front_view'
              engraving=''
              toggleColourPicker={toggleColourPicker}
              customColourProduct={customColourProduct}
              customComponents={customComponents}
              interactive
            />
          </div>

          {showColourPicker && (
            <div
              className={`z-[120]
                md:absolute md:transform md:-translate-x-1/2
                fixed bottom-0 left-0 w-full md:w-auto
                transition-transform duration-300 ease-kibushopdefault
                colour-picker-container
                ${open ? 'translate-y-0' : 'translate-y-full'}`}
              style={{
                ...window.innerWidth >= 768 ? {
                  left: `${colourPickerPos.x}px`,
                  top: `${Math.min(colourPickerPos.y - (navHeight || 0) + 20, window.innerHeight - 250)}px`
                } : {
                  marginBottom: `${navHeight}px`
                }
              }}
            >
              <div className="flex flex-col gap-2 max-w-md mx-auto bg-white shadow-lg rounded-2xl p-4">
                <h3 className="flex justify-center items-center text-sm font-medium text-gray-900 mb-2 md:text-left text-center">
                  {colourPickerPartName}
                  {/* <div className='p-0.5 rounded-full cursor-pointer hover:bg-black hover:text-white' onClick={() => {setShowColourPicker(false); setColourPickerPosition(null);}}>
                    <X className='h-4 w-4'/>
                  </div> */}
                </h3>
                <div className="grid grid-cols-6 md:grid-cols-4 gap-4">
                  {availableMaterials.map((materialId) => {
                    const materialInfo = materialInformation.find(m => m.materialId === materialId)
                    if (!materialInfo) return null

                    const isSelected = customComponents.some(comp =>
                      comp.chosenMaterial.$oid === materialId &&
                      comp.name === colourPickerPartName &&
                      comp.position === colourPickerPosition
                    )

                    return (
                      <div key={materialId} className="relative group">
                        <div className="absolute -top-8 mb-2 px-2 py-1 bg-gray-900 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap pointer-events-none z-[1000]">
                          {materialInfo.kibu_name}
                        </div>
                        <button
                          className={`w-10 h-10 rounded-lg transition-colors colour-picker-button outline outline-2 outline-offset-1
                            ${isSelected
                              ? 'outline-orange hover:outline-orange/80'
                              : 'outline-gray-200 hover:outline-orange/50'}`}
                          style={{ backgroundColor: materialInfo.hex_code }}
                          onClick={() => handleMaterialSelect(materialId)}
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          )}

          {/* Confirmation Dialog */}
          {showConfirmDialog && (
            <div className="fixed inset-0 z-[60] flex items-center justify-center bg-black/50">
              <div className="bg-white rounded-xl p-6 max-w-md w-full mx-4 shadow-xl">
                <h3 className="text-xl font-bold mb-4">Are you sure?</h3>
                <p className="text-gray-600 mb-6">
                  If you close now, your custom colour selections will be lost and the first colourway will be selected instead.
                </p>
                <div className="flex justify-end gap-3">
                  <button
                    type="button"
                    onClick={() => handleCancelClose()}
                    className="flex items-center p-2 md:pl-4 md:pr-4 w-max justify-center gap-1 w-min-content rounded-md font-headline text-black/50 leading-none
                      transition-all ease-kibu duration-200 items-center cursor-pointer border border-2 border-black/50
                      hover:bg-black hover:rounded-lg hover:brightness-105 hover:border-black/10 hover:scale-[1.02] hover:text-white"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={() => handleConfirmClose()}
                    className={`flex items-center p-2 md:pl-4 md:pr-4 bg-orange justify-center w-min-content rounded-md font-headline md:text-lg text-white leading-none
                      transition-all ease-kibu duration-200 items-center cursor-pointer border border-2 border-transparent
                      hover:rounded-lg hover:brightness-105 hover:border-black/10 hover:scale-[1.02]`}
                  >
                    Yes, close
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default CustomColoursContainer