import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import LegalPageLayout from '../components/LegalPageLayout';

const Legal: React.FC = () => {
    const legalDocuments = [
        {
            title: 'Delivery, Returns & Recycling',
            path: '/legal/delivery-and-returns',
            description: 'Information about our delivery service, returns process, and commitment to sustainability.'
        },
        {
            title: 'Privacy Policy',
            path: '/legal/privacy-policy',
            description: 'Learn how we collect, use, and protect your personal information.'
        },
        {
            title: 'Terms of Service',
            path: '/legal/terms-of-service',
            description: 'The terms and conditions that govern your use of our services.'
        },
        {
            title: 'Kibu Care Terms',
            path: '/legal/kibu-care-terms',
            description: 'Terms and conditions specific to Kibu Care service.'
        }
    ];

    return (
        <LegalPageLayout title="Legal">
            <div className="grid gap-8">
                {legalDocuments.map((doc) => (
                    <Link
                        key={doc.path}
                        to={doc.path}
                        className="block p-6 border border-gray-200 rounded-lg hover:border-primary transition-colors"
                    >
                        <h2 className="text-2xl font-headline mb-2">{doc.title}</h2>
                        <p className="text-gray-600">{doc.description}</p>
                    </Link>
                ))}
            </div>
        </LegalPageLayout>
    );
};

export default Legal;