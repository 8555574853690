import { redirect } from 'react-router-dom';

// Only include regions that are fully supported by the backend
export const SUPPORTED_REGIONS: string[] = ['gb', 'us', 'de', 'au', 'ca', 'ad', 'ba', 'it', 'ro'];

// Global routes that don't require region prefixing
export const GLOBAL_ROUTES = [
    'legal',
    'privacy-policy',
    'kibu-care-terms',
    'terms-of-service',
    'delivery-and-returns',
    // Add future global routes here, e.g.:
    // 'articles',
    // 'blog',
];

// Function to check if a path is a global route
export const isGlobalRoute = (pathname: string): boolean => {
    const firstSegment = pathname.split('/')[1]?.toLowerCase();
    return GLOBAL_ROUTES.includes(firstSegment);
};

// Function to check if a region is supported
export const isRegionSupported = (region: string): boolean => {
    return SUPPORTED_REGIONS.includes(region.toLowerCase());
};

// Function to get current region from URL
export const getRegionFromUrl = (pathname: string): string | null => {
    // Don't process region for global routes
    if (isGlobalRoute(pathname)) {
        return null;
    }

    const match = pathname.match(/^\/([^/]+)/);
    if (match && isRegionSupported(match[1])) {
        return match[1].toLowerCase();
    }
    return null;
};

// Function to build region-specific URL
export const buildRegionUrl = (region: string, path: string): string => {
    // Don't add region prefix to global routes
    if (isGlobalRoute(path)) {
        return path;
    }

    // Remove any existing region prefix and leading/trailing slashes
    const cleanPath = path.replace(/^\/[^/]+/, '').replace(/^\/+|\/+$/g, '');
    return `/${region.toLowerCase()}/${cleanPath}`;
};

// Function to ensure a path has a region prefix
export const ensureRegionPrefix = (path: string, defaultRegion: string = 'gb'): string => {
    // Don't add region prefix to global routes
    if (isGlobalRoute(path)) {
        return path;
    }

    const currentRegion = getRegionFromUrl(path);
    if (!currentRegion) {
        return buildRegionUrl(defaultRegion, path);
    }
    return path;
}; 