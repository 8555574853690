import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';

type ButtonVariant =
    | 'border-white'
    | 'border-black'
    | 'border-orange'
    | 'solid-white'
    | 'solid-black'
    | 'solid-orange';

interface ButtonProps {
    to?: string;
    text: string;
    variant?: ButtonVariant;
    showArrow?: boolean;
    onClick?: () => void;
    className?: string;
}

const getVariantClasses = (variant: ButtonVariant): string => {
    const variants = {
        'border-white': 'border border-white text-white hover:scale-x-105',
        'border-black': 'border border-black text-black hover:scale-x-105',
        'border-orange': 'border border-orange-500 text-orange-500 hover:scale-x-105',
        'solid-white': 'bg-white text-black hover:scale-x-105',
        'solid-black': 'bg-black text-white hover:scale-x-105',
        'solid-orange': 'bg-orange text-white hover:scale-x-105'
    };

    return variants[variant];
};

const Button: React.FC<ButtonProps> = ({
    to,
    text,
    variant = 'border-white',
    showArrow = true,
    onClick,
    className = ''
}) => {
    const baseClassName = "inline-flex items-center gap-2 px-6 py-3 rounded-lg transition-all duration-300";
    const variantClassName = getVariantClasses(variant);
    const buttonClassName = `${baseClassName} ${variantClassName} ${className}`;

    if (to) {
        return (
            <Link
                to={to}
                className={buttonClassName}
            >
                {text}
                {showArrow && <ArrowRight className="w-5 h-5" />}
            </Link>
        );
    }

    return (
        <button
            onClick={onClick}
            className={buttonClassName}
        >
            {text}
            {showArrow && <ArrowRight className="w-5 h-5" />}
        </button>
    );
};

export default Button; 