import React from 'react';

const PaymentMethods: React.FC = () => {
    return (
        <div className='flex flex-col items-center justify-center md:flex-row gap-1'>
            <div className='flex flex-row gap-1 items-center justify-center'>
                <img src="/assets/images/payment_services/Amex.webp" alt="Amex Logo" className='md:w-[50px] w-[40px]' />
                <img src="/assets/images/payment_services/Mastercard.webp" alt="Mastercard Logo" className='md:w-[50px] w-[40px]' />
                <img src="/assets/images/payment_services/Visa.webp" alt="Visa Logo" className='md:w-[50px] w-[40px]' />
                <img src="/assets/images/payment_services/Revolut.webp" alt="Revolut Logo" className='md:w-[50px] w-[40px]' />
                <img src="/assets/images/payment_services/ApplePay.webp" alt="Apple Pay Logo" className='md:w-[50px] w-[40px]' />
                <img src="/assets/images/payment_services/Link.webp" alt="Link Logo" className='md:w-[50px] w-[40px]' />
                <img src="/assets/images/payment_services/Stripe.webp" alt="Stripe Logo" className='md:w-[50px] w-[40px]' />
            </div>
            {/* <h4 className='ml-2 text-xs relative -top-0.5'>Secure Payments</h4> */}
        </div>
    );
};

export default PaymentMethods; 