import { IMainProduct, IProductImage } from '@/types'
const apiUrl: string = process.env.REACT_APP_API_URL!


export const productsData: IMainProduct[] = [
  {
    id: 1,
    name: 'Kibu Headphones',
    item_id: '621e2d34b51d4e21d1fa1234',
    price: 39,
    images: [
      // {
      //   url: '/assets/images/Boy-Building-Image-Thumbnail.jpg',
      //   showThumbnail: true,
      //   alt: 'Boy building Kibu Headphones',
      // },
      {
        url: `https://d1tg5gdu842vo5.cloudfront.net/site-assets/images/headphones-in-context/boy_and_girl_listening.jpg`,
        showThumbnail: true,
        alt: 'Boy and girl watching tablet using Kibu Headphones',
      },
      // {
      //   url: '/assets/images/Boy-Listening.jpg',
      //   showThumbnail: true,
      //   alt: 'Boy listening to Kibu Headphones',
      // },
      {
        url: `https://d1tg5gdu842vo5.cloudfront.net/site-assets/images/headphones-in-context/boy_and_father_listening.jpg`,
        showThumbnail: true,
        alt: 'Boy and father listening to Kibu Headphones together',
      },
      {
        url: `https://d1tg5gdu842vo5.cloudfront.net/site-assets/images/headphones-in-context/girl_and_father_building.jpg`,
        showThumbnail: true,
        alt: 'Girl and father building Kibu Headphones',
      },
    ],
    description1:
      "Inspire your child's imagination with headphones they can build. Assembling them boosts confidence and fosters hands-on learning, nurturing independence and problem-solving skills.",
    description2:
      'No complicated tools. No single-use plastics. No unnecessary waste—just endless creativity and learning.',
  },
]