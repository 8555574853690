import React from 'react'

type SkeletonProps = {
    className?: string
    variant?: 'rectangular' | 'circular'
}

const Skeleton: React.FC<SkeletonProps> = ({ className = '', variant = 'rectangular' }) => {
    return (
        <div
            className={`
        animate-pulse bg-grey
        ${variant === 'circular' ? 'rounded-full' : 'rounded-xl'}
        ${className}
      `}
        />
    )
}

export default Skeleton 