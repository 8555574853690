import React, { useEffect, useRef, useState } from 'react'
import useBasketStore, { IBasketItem } from '@/context/BasketStore'
import { Loader, X, XCircle, ThumbsUp, MessageSquare, Undo2 } from 'lucide-react'
import PaymentMethods from './PaymentMethods'
import { getStripeCheckoutLink, StripeCheckoutData, StripeCheckoutItem } from '@/services/api'
import { IPartsModifications, IProduct, ICustomComponent } from '@/context/ProductDataStore'
import { AddonInputValue } from '@/types'
import useOrderSummaryStore from '@/context/OrderSummaryStore'
import DynamicHeadphones from './customColours/DynamicHeadphones'
import CustomColoursContainer from './CustomColoursContainer'
import useRegionStore from '@/context/RegionStore'
import useCountryDetailsStore from '@/context/CountryDetailsStore'
import { trackInitiateCheckout } from '@/utils/tracking'
import { useCurrency } from '@/hooks/useCurrency'

// Update types to match the required structure
interface ComponentData {
	partId: {
		$oid: string;
	};
	side?: string;
	chosenMaterial: {
		$oid: string;
	};
}

type BasketProps = {
	className?: string
}

interface RemovedItem extends IBasketItem {
	originalIndex: number;
}

const Basket: React.FC<BasketProps> = ({ className }) => {
	const { basketOpen, setBasketOpen, basketItems, removeItemFromBasket, itemCount, updateBasketItem } = useBasketStore()
	const { setCurrentOrder } = useOrderSummaryStore()
	const { chosenRegion, detectedRegion } = useRegionStore()
	const { countryDetails } = useCountryDetailsStore()
	const { symbol: currencySymbol, formatPrice, freeDeliveryThreshold } = useCurrency()

	const basketRef = useRef<HTMLDivElement>(null)
	const [fetchingCheckoutLink, setFetchingCheckoutLink] = useState<boolean>(false)
	const [checkoutPrice, setCheckoutPrice] = useState<number>(0)
	const [deliveryCost, setDeliveryCost] = useState<number>(0)
	const [amountToFreeDelivery, setAmountToFreeDelivery] = useState<number>(0)
	const [removedItems, setRemovedItems] = useState<RemovedItem[]>([])
	const [customColoursOpen, setCustomColoursOpen] = useState<boolean>(false)
	const [activeCustomItem, setActiveCustomItem] = useState<{ id: string, product: IProduct, components: ICustomComponent[] } | null>(null)

	// Handle scroll lock
	useEffect(() => {
		const originalStyle = window.getComputedStyle(document.body).overflow

		if (basketOpen) {
			// Save the current scroll position and lock scrolling
			document.body.style.overflow = 'hidden'
		} else {
			// Restore scrolling
			document.body.style.overflow = originalStyle
		}

		// Cleanup function to restore original scroll state
		return () => {
			document.body.style.overflow = originalStyle
		}
	}, [basketOpen])

	const close = () => {
		setBasketOpen(false)
	}

	const handleRemoveItem = (id: string) => {
		const itemToRemove = basketItems.find(item => item.id === id)
		const itemIndex = basketItems.findIndex(item => item.id === id)
		if (itemToRemove) {
			setRemovedItems(prev => [{ ...itemToRemove, originalIndex: itemIndex }, ...prev])
		}
		removeItemFromBasket(id)
	}

	const handleRemoveAllItems = () => {
		const itemsWithIndices = basketItems.map((item, index) => ({
			...item,
			originalIndex: index
		}))
		setRemovedItems(prev => [...itemsWithIndices, ...prev])
		basketItems.forEach(item => removeItemFromBasket(item.id))
	}

	const handleUndo = () => {
		if (removedItems.length > 0) {
			// Get the most recently removed item
			const [itemToRestore, ...remainingRemovedItems] = removedItems
			setRemovedItems(remainingRemovedItems)

			// Get all current basket items
			const currentBasketItems = [...basketItems]

			// Find where to insert the restored item
			let insertIndex = itemToRestore.originalIndex

			// Adjust insert index if it's beyond current basket size
			if (insertIndex > currentBasketItems.length) {
				insertIndex = currentBasketItems.length
			}

			// Insert the item at the correct position
			currentBasketItems.splice(insertIndex, 0, itemToRestore)

			// Clear the basket and add all items in the correct order
			basketItems.forEach(item => removeItemFromBasket(item.id))
			currentBasketItems.forEach(item => {
				useBasketStore.getState().addItemToBasket(item)
			})
		}
	}

	useEffect(() => {
		const handleKeyDown = (e: KeyboardEvent) => {
			if (e.key === 'Escape' && basketOpen) {
				close()
			}
		}

		const handleClickOutside = (e: MouseEvent) => {
			if (basketOpen && basketRef.current && !basketRef.current.contains(e.target as Node)) {
				close()
			}
		}

		if (basketOpen) {
			document.addEventListener('keydown', handleKeyDown)
			document.addEventListener('mousedown', handleClickOutside)
		} else {
			document.removeEventListener('keydown', handleKeyDown)
			document.removeEventListener('mousedown', handleClickOutside)
		}

		// Cleanup function
		return () => {
			document.removeEventListener('keydown', handleKeyDown)
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [basketOpen])

	useEffect(() => {
		let price: number = 0
		basketItems.forEach(item => {
			// Convert price to pence/cents if it's not already
			price += item.price * 100
		})
		setCheckoutPrice(price)

		// Get standard shipping rate
		const standardShipping = countryDetails?.shippingRates?.find(rate => rate.name === 'Standard Shipping')
		if (standardShipping?.type === 'threshold' && standardShipping.thresholds) {
			// Find the applicable threshold based on the basket total
			const applicableThreshold = standardShipping.thresholds.find(threshold => price >= threshold.minAmount)
				|| standardShipping.thresholds[standardShipping.thresholds.length - 1]

			setDeliveryCost(applicableThreshold.amount)

			// If there's a free shipping threshold (amount: 0), calculate amount needed
			const freeShippingThreshold = standardShipping.thresholds.find(threshold => threshold.amount === 0)
			if (freeShippingThreshold) {
				if (price >= freeShippingThreshold.minAmount) {
					setAmountToFreeDelivery(0)
				} else {
					setAmountToFreeDelivery(freeShippingThreshold.minAmount - price)
				}
			}
		}
	}, [basketItems, countryDetails])

	const createStripeCheckoutBody = (): StripeCheckoutData | null => {
		const checkoutItems: StripeCheckoutItem[] = []

		basketItems.forEach((basketItem: IBasketItem) => {
			// Handle custom headphones (items with components)
			if (basketItem.colourWay.components?.length) {
				const checkoutItem: StripeCheckoutItem = {
					productItemId: basketItem.colourWay._id,
					quantity: 1,
					parentProductItemId: null,
					components: basketItem.colourWay.components.map(component => ({
						partId: component.componentVariantId,
						side: component.position || undefined,
						chosenMaterial: component.chosenMaterial
					}))
				}
				checkoutItems.push(checkoutItem)
			} else {
				// Handle regular products
				const checkoutItem: StripeCheckoutItem = {
					productItemId: basketItem.colourWay._id,
					quantity: 1,
					parentProductItemId: null
				}
				checkoutItems.push(checkoutItem)
			}

			// Handle extras/addons
			basketItem.extras.forEach((item: IProduct) => {
				if (item.components?.length) {
					const checkoutItem: StripeCheckoutItem = {
						productItemId: item._id,
						quantity: 1,
						parentProductItemId: basketItem.colourWay._id,
						components: item.components.map(component => ({
							partId: component.componentVariantId,
							side: component.position || undefined,
							chosenMaterial: component.chosenMaterial
						}))
					}
					checkoutItems.push(checkoutItem)
				} else {
					const checkoutItem: StripeCheckoutItem = {
						productItemId: item._id,
						quantity: 1,
						parentProductItemId: basketItem.colourWay._id
					}

					// Handle engraving text
					const engravingInput = basketItem.extrasData.find(data => data.itemId === item._id)
					if (item.componentModifications?.engravingText && engravingInput) {
						checkoutItem.componentModifications = {
							engravingText: engravingInput.inputValue
						}
					}

					checkoutItems.push(checkoutItem)
				}
			})
		})

		// Get the base URL (protocol + host)
		const baseUrl = `${window.location.protocol}//${window.location.host}`
		// Get the country code from the URL path segments
		const countryCode = chosenRegion ? chosenRegion : detectedRegion ? detectedRegion : 'GB'

		return {
			countryCode: countryCode.toUpperCase(),
			items: checkoutItems,
			successUrl: `${baseUrl}/${countryCode.toLowerCase()}/shop/checkout/success`,
			errorUrl: `${baseUrl}/${countryCode.toLowerCase()}/shop/checkout/error`
		}
	}

	const handleStripeCheckout = async () => {
		const stripeCheckoutData = createStripeCheckoutBody()
		if (!stripeCheckoutData) return

		// Save the order summary before proceeding
		setCurrentOrder(basketItems, checkoutPrice, deliveryCost)

		// Track InitiateCheckout event
		trackInitiateCheckout(basketItems, checkoutPrice + deliveryCost, chosenRegion)

		setFetchingCheckoutLink(true)
		try {
			const checkoutLink = await getStripeCheckoutLink(stripeCheckoutData)
			if (checkoutLink) {
				setBasketOpen(false)
				setTimeout(() => {
					window.location.assign(checkoutLink.checkoutUrl);
				}, 300) // Wait for basket close animation
			}
		} catch (error) {
			console.error('Error fetching product data:', error)
		} finally {
			setFetchingCheckoutLink(false)
		}
	}

	const handleEditCustomColours = (item: IBasketItem) => {
		console.log('customColourBasketCheck: Opening editor for item', {
			itemId: item.id,
			currentComponents: item.colourWay.components
		})

		// Ensure we have a deep copy of the components to avoid reference issues
		const components = item.colourWay.components ? JSON.parse(JSON.stringify(item.colourWay.components)) : []

		console.log('customColourBasketCheck: Created deep copy of components', {
			components
		})

		setActiveCustomItem({
			id: item.id,
			product: item.colourWay,
			components: components
		})
		setCustomColoursOpen(true)
	}

	const handleCustomColourClose = () => {
		setCustomColoursOpen(false)
		setActiveCustomItem(null)
	}

	const handleCustomColourComplete = () => {
		console.log('customColourBasketCheck: Completing custom colours in basket', {
			activeCustomItem
		})

		if (activeCustomItem) {
			console.log('customColourBasketCheck: Updating basket item on complete', {
				id: activeCustomItem.id,
				components: activeCustomItem.components
			})

			// Update the basket item with the final state
			updateBasketItem(activeCustomItem.id, {
				colourWay: {
					...activeCustomItem.product,
					components: activeCustomItem.components
				}
			})
		}

		setCustomColoursOpen(false)
		setActiveCustomItem(null)
	}

	const updateCustomComponent = (componentName: string, materialId: string, position: string | null) => {
		if (!activeCustomItem) return

		console.log('customColourBasketCheck: Basket updating component', {
			componentName,
			materialId,
			position,
			currentComponents: activeCustomItem.components
		})

		const updatedComponents = activeCustomItem.components.map(comp => {
			if (comp.name === componentName && comp.position === position) {
				console.log('customColourBasketCheck: Found matching component in basket', {
					component: comp,
					newMaterialId: materialId
				})
				return {
					...comp,
					chosenMaterial: { $oid: materialId }
				}
			}
			return comp
		})

		console.log('customColourBasketCheck: Setting new active item state', {
			updatedComponents
		})

		setActiveCustomItem(prev => {
			const newState = prev ? {
				...prev,
				components: updatedComponents
			} : null
			console.log('customColourBasketCheck: New active item state', newState)
			return newState
		})
	}

	useEffect(() => {
		if (activeCustomItem) {
			console.log('customColourBasketCheck: Active custom item changed', {
				id: activeCustomItem.id,
				components: activeCustomItem.components
			})
		}
	}, [activeCustomItem])

	return (
		<>
			<div
				ref={basketRef}
				className={`basket fixed top-0 right-0 z-60 h-[100dvh] drop-shadow-basket w-full md:w-[600px] transition-all ${className} ${basketOpen ? 'translate-x-0 shadow-basket ease-hypereaseout duration-500' : 'translate-x-full shadow-none duration-300 ease-kibushopdefault'}`}
			>
				<div className="relative flex flex-col gap-8 w-full h-full bg-white w-full md:p-6">
					<div className="basket-nav flex items-center justify-between p-6 md:p-0 pb-0">
						<div className='flex gap-3 items-center'>
							<div className="opacity-30">Your basket ({itemCount} items)</div>
							<div className="flex gap-2">
								<button
									onClick={() => handleRemoveAllItems()}
									className="text-black/50 hover:text-black transition-colors"
								>
									Clear
								</button>
								{removedItems.length > 0 && (
									<button
										onClick={handleUndo}
										className="flex items-center gap-1 text-orange hover:text-orange/80 transition-colors"
									>
										<Undo2 size={16} />
										Undo
									</button>
								)}
							</div>
						</div>
						<button onClick={close} className="bg-orange rounded-full p-2 transition-all duration-1000 ease-hypereaseout hover:scale-110">
							<X
								className={`h-4 w-4 transition-all text-white duration-1000 ease-kibushopdefault ${basketOpen ? 'rotate-0' : 'rotate-90'}`}
								strokeWidth={3}
							/>
						</button>
					</div>



					{itemCount === 0 && (
						<div className="basket-no-items text-black/30 text-center">You haven't added anything to your basket yet</div>
					)}
					<div className="basket-items flex flex-col gap-4 p-6 pt-1 md:p-0 pb-[320px] md:pb-[320px] max-h-full overflow-y-scroll">
						{basketItems.map((item: IBasketItem) => (
							<div
								className="basket-item flex justify-between items-center border border-black/15 p-4 rounded-lg"
								key={item.id}
							>
								<div className="flex items-center gap-4">
									<div className="rounded-md overflow-hidden relative">
										{item.colourWay._id === "674b4a1f197065b8a54a3224" && item.colourWay.components ? (
											<div className="relative w-[100px] h-[100px] cursor-pointer overflow-hidden" onClick={() => handleEditCustomColours(item)}>
												<div className="absolute inset-0">
													<img
														src={'/assets/images/product_images/' + item.colourWay._id + '_background.webp'}
														className="w-full h-full object-cover"
														alt="Background"
													/>
												</div>
												<div className="absolute inset-0 flex items-center justify-center scale-[0.8] -left-3">
													<div className="w-full h-full relative">
														<DynamicHeadphones
															viewName="front_view"
															engraving=""
															customColourProduct={item.colourWay}
															customComponents={item.colourWay.components}
															toggleColourPicker={() => { }}
															interactive={false}
															miniature={true}
															key={JSON.stringify(item.colourWay.components)}
															className="transform-none"
														/>
													</div>
												</div>
												<div className="absolute inset-0 bg-black/0 hover:bg-black/10 transition-colors flex items-center justify-center">
													<span className="text-white opacity-0 hover:opacity-100 transition-opacity text-sm font-medium">Edit Colours</span>
												</div>
											</div>
										) : (
											<img
												src={'/assets/images/product_thumbnails/' + item.colourWay._id + '.webp'}
												width={100}
												className="aspect-square object-cover"
												alt={item.name}
											/>
										)}
									</div>
									<div className="flex flex-col gap-1 ">
										<div className="font-bold leading-none">
											{item.name}
										</div>
										<div className="opacity-70 leading-none">{item.colourWay.optionTitle}</div>
										<div className="opacity-70 leading-none text-xs flex flex-col gap-1">
											{item.extras.map((item) => (
												<div key={item._id}>+ {item.productTitle}</div>
											))}
										</div>
										<div className="opacity-70 leading-none font-headline text-blue">{currencySymbol}{item.price}</div>
									</div>
								</div>
								<button className="text-red-500 bg-white border border-2 border-red-500 rounded-md p-2 transition-all ease-hypereaseout duration-[2000ms] scale-100 hover:scale-110 hover:bg-red-500 hover:text-white" onClick={() => handleRemoveItem(item.id)}>
									<X className="h-4 w-4" />
								</button>
							</div>
						))}
					</div>
					<div className='fixed bottom-0 right-0 left-0 flex flex-col w-9/10 md:w-[600px] bg-white p-6 pt-4 pb-safe border-t border-black/10 gap-2 z-[100]'>
						{/* Cost Summary */}
						{/* Delivery Progress Bar */}
						<div className="delivery-progress flex flex-col gap-2 mb-2">
							{deliveryCost === 0 ? (
								<div className="flex items-center gap-2 text-green-600">
									<ThumbsUp size={20} />
									<span>You've qualified for free delivery</span>
								</div>
							) : freeDeliveryThreshold !== null ? (
								<div className="flex items-center gap-2 text-gray-600">
									<MessageSquare size={20} />
									<span>You're {formatPrice(amountToFreeDelivery)} away from free shipping</span>
								</div>
							) : null}
							{freeDeliveryThreshold !== null && (
								<div className="w-full h-2 bg-gray-200 rounded-full overflow-hidden">
									<div
										className="h-full bg-[#179D1A] transition-all duration-500 ease-out rounded-full"
										style={{
											width: `${Math.min((checkoutPrice / freeDeliveryThreshold) * 100, 100)}%`
										}}
									/>
								</div>
							)}
						</div>
						<div className="w-full">
							<div className="flex flex-col gap-2 text-base">
								<div className="flex justify-between">
									<span className="text-black/50">Subtotal</span>
									<span>{formatPrice(checkoutPrice)}</span>
								</div>
								<div className="flex justify-between">
									<span className="text-black/50">Delivery</span>
									<span>{deliveryCost === 0 ? 'FREE' : formatPrice(deliveryCost)}</span>
								</div>
								<div className="flex justify-between font-bold pt-2 border-t border-black/10">
									<span>Total</span>
									<span>{formatPrice(checkoutPrice + deliveryCost)}</span>
								</div>
							</div>
						</div>

						<button
							className={`flex items-center gap-2 p-4 bg-orange justify-center w-full mt-2 rounded-xl font-headline text-white leading-none
								transition-all ease-kibu duration-200 items-center cursor-pointer border border-2 border-transparent
								hover:rounded-2xl hover:shadow-button-hover hover:brightness-105 hover:border-black/10 hover:scale-[1.02]
								${fetchingCheckoutLink && 'opacity-70 cursor-not-allowed'}
								${itemCount < 1 && 'pointer-events-none opacity-30'}`}
							onClick={() => handleStripeCheckout()}
							disabled={itemCount < 1 || fetchingCheckoutLink}
						>
							{fetchingCheckoutLink ? (
								<>
									<Loader className="h-5 w-5 animate-spin" />
									Processing...
								</>
							) : (
								'Stripe Checkout'
							)}
						</button>

						<div className='flex justify-center my-3'><PaymentMethods /></div>
					</div>
				</div>
			</div>

			{/* Custom Colours Container */}
			{activeCustomItem && (
				<CustomColoursContainer
					open={customColoursOpen}
					customColourProduct={activeCustomItem.product}
					customComponents={activeCustomItem.components}
					updateCustomComponent={updateCustomComponent}
					onClose={handleCustomColourClose}
					onComplete={handleCustomColourComplete}
					basketItemId={activeCustomItem.id}
				/>
			)}
		</>
	)
}

export default Basket
