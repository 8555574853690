import React, { useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate, Navigate } from 'react-router-dom';
import useRegionStore from '@/context/RegionStore';
import useAvailableRegionsStore from '@/context/AvailableRegionsStore';
import useCountryDetailsStore from '@/context/CountryDetailsStore';
import { fetchViewerCountry } from '@/services/api';
import { isSpecialLink } from '@/utils/specialLinks';
import Home from '@/pages/Home';
import ProductPage from '@/pages/ProductPage';
import CheckoutPage from '@/pages/checkout';
import ComingSoon from '@/pages/ComingSoon';
import NotFound from '@/pages/NotFound';

interface RegionWrapperProps {
    toggleOverlay: (state: boolean) => void;
    navHeight: number;
    setNavVisibilityOverride: (state: boolean | null) => void;
}

const RegionWrapper: React.FC<RegionWrapperProps> = ({ toggleOverlay, navHeight, setNavVisibilityOverride }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { urlRegion, showRegionModal, chosenRegion, detectedRegion, acceptedRegion, getUrlRegion, setShowRegionModal, setDetectedRegion, setChosenRegion } = useRegionStore();
    const { isRegionAvailable, availableRegions } = useAvailableRegionsStore();
    const { countries } = useCountryDetailsStore();
    const [isDetecting, setIsDetecting] = React.useState(true);

    const detectRegion = async () => {
        console.log('[regionModal] Detection: Starting detection');
        setIsDetecting(true);
        const detectedCountryCode = await fetchViewerCountry();
        console.log('[regionModal] Detection: Country detected', {
            detectedCountryCode,
            timestamp: new Date().toISOString()
        });
        setDetectedRegion(detectedCountryCode);
        setIsDetecting(false);
    };

    // Handle region routing and unsupported regions
    useEffect(() => {
        // Wait for both regions to be loaded and region detection
        if (!availableRegions.length || isDetecting) return;

        // Check for special links first
        const cleanPath = location.pathname.replace(/^\/+/, '');
        if (isSpecialLink(cleanPath)) {
            const targetRegion = chosenRegion || detectedRegion || 'gb';
            console.log(`[SPECIAL_LINK] Redirecting from special link:`, {
                from: location.pathname,
                to: `/${targetRegion}`,
                specialLink: cleanPath,
                timestamp: new Date().toISOString()
            });
            navigate(`/${targetRegion}`, { replace: true });
            return;
        }

        // Extract the first segment of the path to check for region
        const pathSegments = location.pathname.split('/').filter(Boolean);
        const firstSegment = pathSegments[0]?.toLowerCase();

        // If URL has no region, redirect to appropriate region
        if (!firstSegment) {
            // If user hasn't explicitly chosen a region, use detected region
            const targetRegion = chosenRegion || detectedRegion;
            if (!targetRegion) return; // Wait until we have a valid region

            const newPath = `/${targetRegion}`;
            if (newPath !== location.pathname) {
                console.log(`[REGION] Redirecting to detected/chosen region:`, {
                    from: location.pathname,
                    to: newPath,
                    detectedRegion,
                    chosenRegion,
                    timestamp: new Date().toISOString()
                });
                navigate(newPath, { replace: true });
            }
            return;
        }

        // Only treat as a region if it's a 2-letter code
        const isTwoLetterCode = firstSegment.length === 2;

        // If it's not a 2-letter code, redirect to the appropriate region with the full path
        if (!isTwoLetterCode) {
            const targetRegion = chosenRegion || detectedRegion || 'gb';
            const newPath = `/${targetRegion}${location.pathname}`;
            console.log(`[REGION] Redirecting non-region path:`, {
                from: location.pathname,
                to: newPath,
                timestamp: new Date().toISOString()
            });
            navigate(newPath, { replace: true });
            return;
        }

        // Handle valid region in URL
        if (isRegionAvailable(firstSegment)) {
            // If URL region is different from detected region and hasn't been accepted, show modal
            if (detectedRegion && firstSegment !== detectedRegion && firstSegment !== acceptedRegion) {
                if (isRegionAvailable(detectedRegion)) {
                    console.log('[REGION] Showing modal for region change:', {
                        from: detectedRegion,
                        to: firstSegment,
                        timestamp: new Date().toISOString()
                    });
                    setShowRegionModal(true);
                }
            } else {
                // If it matches detected region or has been accepted, update chosen region
                setChosenRegion(firstSegment);
            }
        } else {
            // If region is not available, show unsupported region modal
            console.log(`[REGION] Invalid region in URL:`, {
                urlRegion: firstSegment,
                timestamp: new Date().toISOString()
            });
            setShowRegionModal(true);
        }
    }, [location.pathname, chosenRegion, detectedRegion, acceptedRegion, availableRegions, isRegionAvailable, navigate]);

    // Update URL region whenever pathname changes and show modal if needed
    useEffect(() => {
        getUrlRegion(location.pathname);
    }, [location.pathname]);

    // Show modal if URL region doesn't match detected region and hasn't been accepted
    useEffect(() => {
        if (detectedRegion && urlRegion) {
            if (urlRegion !== detectedRegion && urlRegion !== chosenRegion) {
                if (isRegionAvailable(detectedRegion) && isRegionAvailable(urlRegion)) {
                    console.log('[regionModal] Trigger:', {
                        reason: 'URL Region Change',
                        urlRegion,
                        detectedRegion,
                        chosenRegion,
                        timestamp: new Date().toISOString()
                    });
                    setShowRegionModal(true);
                }
            }
        }
    }, [urlRegion, detectedRegion, chosenRegion, isRegionAvailable]);

    // Initial region detection
    useEffect(() => {
        detectRegion();
    }, []);

    // Set chosen region if not set
    useEffect(() => {
        if (chosenRegion === null && detectedRegion !== null) {
            console.log('[regionModal] Selection:', {
                reason: 'Initial Detection',
                detectedRegion,
                timestamp: new Date().toISOString()
            });
            setChosenRegion(detectedRegion);
        }
    }, [chosenRegion, detectedRegion]);

    // Initialize available regions when countries data is loaded
    useEffect(() => {
        if (countries) {
            useAvailableRegionsStore.getState().setAvailableRegions(countries);
        }
    }, [countries]);

    return (
        <Routes>
            {/* Show loading or home page while detecting region */}
            <Route path="/" element={
                isDetecting ? (
                    <div className="flex items-center justify-center min-h-screen">
                        <div className="text-lg">Loading...</div>
                    </div>
                ) : (
                    <Navigate to={`/${isRegionAvailable(chosenRegion || '') ? chosenRegion : detectedRegion || 'gb'}`} replace />
                )
            } />

            {/* All routes with region prefix */}
            <Route path="/:region">
                <Route path="" element={<Home />} />
                <Route path="products/wired-headphones-1" element={
                    <ProductPage
                        toggleOverlay={toggleOverlay}
                        navHeight={navHeight}
                        setNavVisibilityOverride={setNavVisibilityOverride}
                    />
                } />
                <Route path="shop/checkout/*" element={<CheckoutPage />} />
                <Route path="comingsoon" element={<ComingSoon />} />
                <Route path="*" element={<NotFound />} />
            </Route>

            {/* Handle non-region paths during detection */}
            <Route path="*" element={
                isDetecting ? (
                    <div className="flex items-center justify-center min-h-screen">
                        <div className="text-lg">Loading...</div>
                    </div>
                ) : (
                    <NotFound />
                )
            } />
        </Routes>
    );
};

export default RegionWrapper; 