import React from 'react'

type LoadingRingProps = {
    className?: string
    size?: 'sm' | 'md' | 'lg'
}

const LoadingRing: React.FC<LoadingRingProps> = ({ className = '', size = 'md' }) => {
    const sizeClasses = {
        sm: 'w-4 h-4 border-2',
        md: 'w-8 h-8 border-3',
        lg: 'w-12 h-12 border-4'
    }

    return (
        <div className={`loading-ring ${className}`}>
            <div className={`
        ${sizeClasses[size]}
        border-orange/30 
        border-t-orange
        rounded-full 
        animate-spin
      `}></div>
        </div>
    )
}

export default LoadingRing 