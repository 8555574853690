export interface SpecialLinkConfig {
    id: string;
    // Future configuration options can be added here, such as:
    // homeScreenLayout?: string;
    // featuredProducts?: string[];
    // customComponents?: string[];
}

// Add new special links here
export const SPECIAL_LINKS: Record<string, SpecialLinkConfig> = {
    'arup': {
        id: 'arup',
        // Add future configurations here
    },
    'email': {
        id: 'email',
        // Add future configurations here
    },
    // Add more special links as needed:
    // 'example': {
    //     id: 'example',
    //     homeScreenLayout: 'special',
    // },
};

export const isSpecialLink = (path: string): boolean => {
    const cleanPath = path.toLowerCase().replace(/^\/+/, '');
    return Object.keys(SPECIAL_LINKS).includes(cleanPath);
};

export const getSpecialLinkConfig = (path: string): SpecialLinkConfig | null => {
    const cleanPath = path.toLowerCase().replace(/^\/+/, '');
    return SPECIAL_LINKS[cleanPath] || null;
}; 