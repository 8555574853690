import { create } from 'zustand'
import { IProduct, ICustomComponent } from './ProductDataStore'

interface CustomColoursState {
    isOpen: boolean
    customColourProduct: IProduct | undefined
    customComponents: ICustomComponent[]
    basketItemId?: string
    setOpen: (isOpen: boolean) => void
    setCustomColourProduct: (product: IProduct | undefined) => void
    setCustomComponents: (components: ICustomComponent[] | ((prev: ICustomComponent[]) => ICustomComponent[])) => void
    setBasketItemId: (id: string | undefined) => void
    updateCustomComponent: (componentName: string, materialId: string, position: string | null) => void
}

const useCustomColoursStore = create<CustomColoursState>((set) => ({
    isOpen: false,
    customColourProduct: undefined,
    customComponents: [],
    basketItemId: undefined,
    setOpen: (isOpen) => set({ isOpen }),
    setCustomColourProduct: (product) => set({ customColourProduct: product }),
    setCustomComponents: (components) => set((state) => ({
        customComponents: typeof components === 'function' ? components(state.customComponents) : components
    })),
    setBasketItemId: (id) => set({ basketItemId: id }),
    updateCustomComponent: (componentName, materialId, position) =>
        set((state) => ({
            customComponents: state.customComponents.map(c => {
                if (c.name === componentName && c.position === position) {
                    return { ...c, chosenMaterial: { $oid: materialId } }
                }
                return c
            })
        }))
}))

export default useCustomColoursStore 