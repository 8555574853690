import { ProductCollection } from "@/modules/collections/types/collection.types"

const apiUrl: string = process.env.REACT_APP_API_URL!
interface CountryResponse {
	country_code: string;
}

export const fetchViewerCountry = async (): Promise<string> => {
	try {
		const response = await fetch(`${apiUrl}/viewerCountry`);
		if (!response.ok) {
			throw new Error('Failed to fetch country code');
		}
		const data: CountryResponse = await response.json();
		return data.country_code.toLowerCase();
	} catch (error) {
		console.error('Failed to fetch viewer country:', error);
		return 'gb'; // Default to GB if the API fails
	}
}

interface IApiComponent {
	componentType: string
	name: string
	defaultVariant: string
	quantity: number
	display?: Array<{
		configuratorFrontOn: boolean
		'z-index'?: number
	}>
	defaultMaterial?: string
	materialOptions?: string[]
	positions?: Array<{
		position: string
		quantity: number
	}>
}

interface ITransformedComponent {
	componentVariantId: { $oid: string }
	componentType: string
	name: string
	position: string | null
	chosenMaterial: { $oid: string }
	display?: Array<{
		configuratorFrontOn: boolean
		'z-index'?: number
	}>
	materialOptions?: string[]
}

const transformComponents = (apiComponents: IApiComponent[]): ITransformedComponent[] => {
	return apiComponents.flatMap(component => {
		// Skip components without defaultMaterial
		// if (!component.defaultMaterial && !component.materialOptions) {
		// 	return []
		// }

		const baseComponent: Omit<ITransformedComponent, 'position'> = {
			componentVariantId: {
				$oid: component.defaultVariant
			},
			componentType: component.componentType,
			name: component.name,
			display: component.display,
			materialOptions: component.materialOptions,
			chosenMaterial: {
				$oid: component.defaultMaterial || ''
			}
		}

		if (!component.positions) {
			// If no positions specified, create a single component with null position
			return [{
				...baseComponent,
				position: null,
			} as ITransformedComponent]
		}

		// Create a component for each position
		return component.positions.flatMap(pos => {
			const components: ITransformedComponent[] = []
			for (let i = 0; i < pos.quantity; i++) {
				components.push({
					...baseComponent,
					position: pos.position,
				})
			}
			return components
		})
	})
}

export const fetchProductData = async (productId: string, countryCode: string) => {
	try {
		const response = await fetch(`${apiUrl}/shop/products/${productId}/full-details`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-country-code': countryCode.toUpperCase()
			},
		})
		const data = await response.json()
		console.log('[data]: ', data)
		console.log('[data] apiUrl: ', apiUrl)
		console.log('[data] productId: ', productId)
		console.log('[data] countryCode: ', countryCode.toUpperCase())

		// Transform components in the main product and all options
		if (data.product.availableOptions) {
			data.product.availableOptions.forEach((option: any) => {
				option.options.forEach((product: any) => {
					if (product.components) {
						product.components = transformComponents(product.components)
					}
				})
			})
		}

		return data
	} catch (error) {
		console.error('Failed to fetch product data:', error)
		throw error
	}
}

export type StripeCheckoutItem = {
	productItemId: string
	quantity: number
	parentProductItemId: string | null
	components?: Array<{
		partId: {
			$oid: string
		}
		side?: string | undefined
		chosenMaterial: {
			$oid: string
		}
	}>
	componentModifications?: {
		engravingText: string
	}
}

export type StripeCheckoutData = {
	items: StripeCheckoutItem[]
	orderNumber?: string
	countryCode: string
	successUrl: string
	errorUrl: string
}

interface CheckoutResponse {
	products: any[]
	stripeProductIds: Array<{
		priceId: string
		quantity: number
	}>
	checkoutSessionId: string
	checkoutUrl: string
	orderNumber: string
}

let lastOrderNumber: string | null = null

export const getStripeCheckoutLink = async (checkoutData: StripeCheckoutData): Promise<CheckoutResponse> => {
	try {
		// If we have a stored order number, add it to the request
		if (lastOrderNumber) {
			checkoutData.orderNumber = lastOrderNumber
		}

		console.log('checkoutDataSent: ', JSON.stringify(checkoutData, null, 2))

		const response = await fetch(`${apiUrl}/shop/checkout`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(checkoutData),
		})

		if (!response.ok) {
			throw new Error(`Failed to fetch checkout link: ${response.statusText}`)
		}

		const data: CheckoutResponse = await response.json()
		console.log('data: ', data)

		// Store the new order number
		lastOrderNumber = data.orderNumber

		return data
	} catch (error) {
		console.error('Failed to fetch checkout link:', error)
		throw error
	}
}

export const fetchProductCollection = async (params: { id?: string; collectionCode?: string; country_code: string }): Promise<ProductCollection> => {
	try {
		const headers: Record<string, string> = {
			'Content-Type': 'application/json',
			'x-country-code': params.country_code,
		};

		// Only add the collection code header if it's defined
		if (params.collectionCode) {
			headers['x-collection-code'] = params.collectionCode;
		}

		const response = await fetch(`${apiUrl}/productCollections`, {
			method: 'GET',
			headers,
		});

		if (!response.ok) {
			throw new Error('Failed to fetch product collection');
		}

		const data: ProductCollection = await response.json();
		console.log('[COLOUR] data: ', data)
		return data;
	} catch (error) {
		console.error('Failed to fetch product collection:', error);
		throw error;
	}
};

export interface CountryDetails {
	countryName: string;
	currency: {
		code: string;
		symbol: string;
		name: string;
	};
	returns: {
		active: boolean;
		returnDays: number;
		updateDate: string;
	} | null;
	countryFlag: string;
	shippingRates: Array<{
		name: string;
		type: string;
		thresholds?: Array<{
			minAmount: number;
			amount: number;
			displayName: string;
		}>;
		amount?: number;
		displayName?: string;
		deliveryEstimate: {
			minimum: { unit: string; value: number };
			maximum: { unit: string; value: number };
		};
	}>;
	prePaidTaxesAndDuties: any;
	deliveryEstimates: Array<{
		minimum: { unit: string; value: number };
		maximum: { unit: string; value: number };
	}>;
	freeDeliveryThreshold: number;
	freeDeliveryThresholdFormatted: string;
}

export const fetchCountryDetails = async (countryCode: string): Promise<CountryDetails> => {
	try {
		const response = await fetch(`${apiUrl}/countryDetails`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-country-code': countryCode.toUpperCase()
			},
		});

		if (!response.ok) {
			throw new Error('Failed to fetch country details');
		}

		const data: CountryDetails = await response.json();
		return data;
	} catch (error) {
		console.error('Failed to fetch country details:', error);
		throw error;
	}
};

export interface CountryInfo {
	name: string;
	flag: string;
}

export interface CountryGroup {
	type: 'group';
	countries: {
		[key: string]: CountryInfo;
	};
}

export interface CountriesResponse {
	[key: string]: CountryInfo | CountryGroup;
}

export const fetchCountries = async (): Promise<CountriesResponse> => {
	try {
		const response = await fetch(`${apiUrl}/countries`);
		if (!response.ok) {
			throw new Error('Failed to fetch countries');
		}
		const data: CountriesResponse = await response.json();
		return data;
	} catch (error) {
		console.error('Failed to fetch countries:', error);
		throw error;
	}
};
