import React, { useState, useEffect } from 'react';
import { IProduct } from '@/context/ProductDataStore';

interface SelectedItemsProps {
    colourWay: IProduct | null;
    selectedAddons: IProduct[];
    maxItems?: number;
}

const SelectedItems: React.FC<SelectedItemsProps> = ({ colourWay, selectedAddons }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const allItems = [colourWay, ...selectedAddons].filter((item): item is IProduct => item !== null);
    const baseVisibleItems = isMobile ? 2 : 3;
    const remainingItems = allItems.length - baseVisibleItems;

    // Show one extra item if there's only one remaining
    const actualVisibleItems = remainingItems === 1 ? baseVisibleItems + 1 : baseVisibleItems;
    const visibleItems = allItems.slice(0, actualVisibleItems);
    const remainingCount = allItems.length - actualVisibleItems;

    return (
        <div className="flex items-center gap-1 md:gap-2">
            {visibleItems.map((item, index) => (
                <div
                    key={item._id}
                    className="flex items-center bg-white border border-[#D2D2D2] md:p-[3px] p-[2px] rounded-[8px] md:h-[50px] h-[40px] overflow-hidden"
                >
                    <div className="aspect-square h-full overflow-hidden md:rounded-[5px] rounded-[6px]">
                        <img
                            src={`/assets/images/product_thumbnails/${item._id}.webp`}
                            alt={item.optionTitle || 'Product option'}
                            className="w-full h-full object-cover"
                        />
                    </div>
                    {/* <span className="truncate max-w-[80px] px-2 text-sm font-body">
                        {item.optionTitle}
                    </span> */}
                </div>
            ))}
            {remainingCount > 0 && (
                <div className="flex items-center justify-center bg-white border border-[#D2D2D2] rounded-lg px-3 h-[40px] aspect-square">
                    <span className="text-sm font-body max-w-[20px]">+{remainingCount}</span>
                </div>
            )}
        </div>
    );
};

export default SelectedItems; 