import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export interface CookieConsent {
    necessary: boolean // Always true, can't be toggled
    analytics: boolean // Google Analytics
    marketing: boolean // Meta Pixel
    preferences: boolean // Site preferences/settings
    hasConsented: boolean // Whether user has made a choice
}

interface CookieConsentStore {
    consent: CookieConsent
    setConsent: (consent: Partial<CookieConsent>) => void
    resetConsent: () => void
}

const initialConsent: CookieConsent = {
    necessary: true,
    analytics: false,
    marketing: false,
    preferences: false,
    hasConsented: false,
}

const useCookieConsentStore = create<CookieConsentStore>()(
    persist(
        (set) => ({
            consent: initialConsent,
            setConsent: (newConsent) =>
                set((state) => ({
                    consent: {
                        ...state.consent,
                        ...newConsent,
                        hasConsented: true,
                    },
                })),
            resetConsent: () => set({ consent: initialConsent }),
        }),
        {
            name: 'cookie-consent-storage',
        }
    )
)

export default useCookieConsentStore 