import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import React from 'react';

// Create a context to access the contentRef and navHeight
export const ScrollContainerContext = React.createContext<{
    contentRef: React.RefObject<HTMLDivElement> | null;
    navHeight: number;
}>({ contentRef: null, navHeight: 0 });

const ScrollToTop = () => {
    const { pathname, hash } = useLocation();
    const { contentRef, navHeight } = React.useContext(ScrollContainerContext);

    const scrollToHash = (hash: string) => {
        const element = document.getElementById(hash)
        if (element && contentRef?.current) {
            // Get the sticky nav height (100px height + 30px padding)
            const stickyNavHeight = 100

            // Calculate total offset (main nav height + sticky nav)
            const totalOffset = navHeight + stickyNavHeight

            // Get element's position relative to the scroll container
            const containerRect = contentRef.current.getBoundingClientRect()
            const elementRect = element.getBoundingClientRect()
            const elementPosition = elementRect.top - containerRect.top + contentRef.current.scrollTop

            const offsetPosition = elementPosition - totalOffset

            // Custom smooth scroll animation
            const startPosition = contentRef.current.scrollTop
            const distance = offsetPosition - startPosition
            const duration = 500
            let start: number | null = null

            const animation = (currentTime: number) => {
                if (start === null) start = currentTime
                const timeElapsed = currentTime - start
                const progress = Math.min(timeElapsed / duration, 1)

                // Easing function for smoother animation
                const ease = (t: number) => t < 0.5
                    ? 4 * t * t * t
                    : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1

                contentRef.current?.scrollTo({
                    top: startPosition + (distance * ease(progress)),
                    behavior: 'auto'
                })

                if (timeElapsed < duration) {
                    requestAnimationFrame(animation)
                }
            }

            requestAnimationFrame(animation)
        }
    }

    // Handle initial page load and hash changes
    useEffect(() => {
        // Reset scroll position on new page without hash
        if (!hash) {
            contentRef?.current?.scrollTo({
                top: 0,
                left: 0,
                behavior: 'auto'
            });
            return;
        }

        // Handle hash scrolling
        const hashValue = hash.slice(1)
        if (hashValue) {
            // Initial attempt
            scrollToHash(hashValue)

            // Retry after a delay to ensure content is loaded
            const timeoutId = setTimeout(() => scrollToHash(hashValue), 500)
            return () => clearTimeout(timeoutId)
        }
    }, [pathname, hash]);

    return null;
};

export default ScrollToTop; 