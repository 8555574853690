import { Info } from 'lucide-react'
import React from 'react'
import useInfoPanelStore from '@/context/InfoPanelStore'

type InfoButtonProps = {
    title: string
    text: string
}

const InfoButton: React.FC<InfoButtonProps> = ({
    title, text
}) => {
    const { showInfo } = useInfoPanelStore()

    return (
        <button
            onClick={() => showInfo(title, text)}
            className="flex w-fit h-fit rounded-lg leading-none text-blue font-headline text-sm"
        >
            <Info className="w-5 h-5 text-blue cursor-pointer" />
        </button>
    )
}

export default InfoButton 