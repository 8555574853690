import React, { useEffect, useRef, useState } from 'react';
import { Plus } from 'lucide-react';
import { DrawerModuleProps, DrawerItem, DrawerMedia } from '../types/drawer.types';
import './DrawerModule.css';

const DrawerModule: React.FC<DrawerModuleProps> = ({
    moduleTitle,
    items,
    className = '',
    defaultMedia
}) => {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const [mediaVisible, setMediaVisible] = useState(true);
    const [previousMedia, setPreviousMedia] = useState<DrawerMedia | null>(null);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const bodyRefs = useRef<(HTMLDivElement | null)[]>([]);
    const moduleRef = useRef<HTMLDivElement>(null);
    const [isVisible, setIsVisible] = useState(false);
    const [loadedMedia, setLoadedMedia] = useState<Set<string>>(new Set());
    const isMobile = typeof window !== 'undefined' && window.innerWidth < 1024;

    // Preload default media when component mounts
    useEffect(() => {
        if (defaultMedia && defaultMedia.url) {
            // If it's a video with a poster, preload the poster
            if (defaultMedia.type === 'video' && defaultMedia.posterUrl) {
                const img = new Image();
                img.src = defaultMedia.posterUrl;
                img.onload = () => {
                    console.log('Default media poster preloaded');
                };
            }
        }
    }, [defaultMedia]);

    // Intersection Observer to detect when the module is visible
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    setIsVisible(true);
                    observer.disconnect();
                }
            },
            { threshold: 0.1 } // Trigger when 10% of the element is visible
        );

        if (moduleRef.current) {
            observer.observe(moduleRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    // Add cleanup effect for unmounting
    useEffect(() => {
        return () => {
            setMediaVisible(false);
        };
    }, []);

    useEffect(() => {
        // Load saved state
        const savedIndex = localStorage.getItem('drawerActiveIndex');
        if (savedIndex !== null) {
            setActiveIndex(parseInt(savedIndex));
        }
    }, []);

    useEffect(() => {
        // Save state
        if (activeIndex !== null) {
            localStorage.setItem('drawerActiveIndex', activeIndex.toString());
        } else {
            localStorage.removeItem('drawerActiveIndex');
        }
    }, [activeIndex]);

    const handleClick = (index: number) => {
        // Save the current media before changing
        if (activeIndex !== null) {
            setPreviousMedia(items[activeIndex].media);
        } else if (defaultMedia) {
            setPreviousMedia(defaultMedia);
        }

        setIsTransitioning(true);
        setMediaVisible(false);

        setTimeout(() => {
            setActiveIndex(activeIndex === index ? null : index);
            setMediaVisible(true);

            // Mark the media as loaded when a drawer is opened
            if (index !== null && index !== activeIndex) {
                const mediaUrl = items[index].media.url;
                setLoadedMedia(prev => new Set(prev).add(mediaUrl));
            }

            // Reset transition state after animation completes
            setTimeout(() => {
                setIsTransitioning(false);
                setPreviousMedia(null);
            }, 500); // Match this with the CSS transition duration
        }, 300);
    };

    useEffect(() => {
        // Update heights for animations
        bodyRefs.current.forEach((ref, index) => {
            if (ref) {
                if (index === activeIndex) {
                    const height = ref.scrollHeight;
                    ref.style.height = `${height}px`;
                } else {
                    ref.style.height = '0';
                }
            }
        });
    }, [activeIndex]);

    // Initialize with default media visible
    useEffect(() => {
        if (defaultMedia && activeIndex === null) {
            setMediaVisible(true);
            // Pre-load the default media
            if (defaultMedia.url) {
                setLoadedMedia(prev => new Set(prev).add(defaultMedia.url));
            }
        }
    }, [defaultMedia, activeIndex]);

    // Function to check if media is loaded
    const isMediaLoaded = (url: string) => {
        return loadedMedia.has(url);
    };

    // Function to mark media as loaded
    const handleMediaLoad = (url: string) => {
        setLoadedMedia(prev => new Set(prev).add(url));
    };

    const renderMedia = (media: DrawerMedia) => {
        // If no media is provided, return null
        if (!media) return null;

        // For images, load them normally
        if (media.type === 'image') {
            return (
                <img
                    src={media.url}
                    alt={media.alt || ''}
                    className="w-full h-full object-cover"
                    loading="lazy"
                    onLoad={() => handleMediaLoad(media.url)}
                />
            );
        }

        // For videos, only load if the drawer is visible and active
        if (media.type === 'video') {
            // Always show the poster image
            if (!isMediaLoaded(media.url) && media.posterUrl) {
                return (
                    <div className="video-placeholder visible" style={{ backgroundImage: `url(${media.posterUrl})` }}>
                        {/* <div className="video-loading-indicator">
                            <svg className="animate-spin h-8 w-8 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </div> */}
                    </div>
                );
            }

            // For default media or active drawer, load the video
            const shouldLoadVideo = isVisible && (activeIndex === null || media === defaultMedia || (activeIndex !== null && media === items[activeIndex].media));

            return (
                <video
                    key={`${media.url}-${mediaVisible}`}
                    autoPlay
                    muted
                    loop
                    playsInline
                    poster={media.posterUrl}
                    className="w-full h-full object-cover"
                    onLoadedData={() => handleMediaLoad(media.url)}
                >
                    <source src={shouldLoadVideo ? media.url : ''} type="video/mp4" />
                </video>
            );
        }

        return null;
    };

    return (
        <div className={`drawer-module ${className}`} ref={moduleRef}>
            {isVisible && (
                <div className="module_container">
                    <div className="drawer-container">
                        <div className="drawer-content">
                            <h2 className="drawer-title">{moduleTitle}</h2>
                            <div className="drawer-list">
                                {items.map((item, index) => (
                                    <div key={index} className="drawer-item">
                                        <button
                                            className="drawer-header"
                                            onClick={() => handleClick(index)}
                                        >
                                            <h3>{item.title}</h3>
                                            <Plus className={`drawer-icon ${index === activeIndex ? 'open' : ''}`} />
                                        </button>
                                        <div
                                            ref={el => bodyRefs.current[index] = el}
                                            className="drawer-body"
                                        >
                                            <div className="drawer-content-wrapper">
                                                <p className="drawer-description">{item.description}</p>
                                                {isMobile && (
                                                    <div className="drawer-media-container">
                                                        {isTransitioning && previousMedia && index === activeIndex && (
                                                            <div className="drawer-media fade-out visible">
                                                                {renderMedia(previousMedia)}
                                                            </div>
                                                        )}
                                                        <div className={`drawer-media ${isTransitioning && index === activeIndex ? 'fade-in' : 'visible'}`}>
                                                            {renderMedia(item.media)}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {!isMobile && (
                            <div className="drawer-media-container">
                                {/* Previous media (fading out) */}
                                {isTransitioning && previousMedia && (
                                    <div className="drawer-media fade-out visible">
                                        {renderMedia(previousMedia)}
                                    </div>
                                )}

                                {/* Current media (fading in) */}
                                <div className={`drawer-media ${activeIndex === null || mediaVisible ? 'visible' : ''} ${isTransitioning ? 'fade-in' : ''}`}>
                                    {renderMedia(activeIndex !== null ? items[activeIndex].media : defaultMedia)}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
            {!isVisible && (
                <div className="w-full py-12">
                    {/* Placeholder for when module is not yet visible */}
                </div>
            )}
        </div>
    );
};

export default DrawerModule; 