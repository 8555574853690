import React, { useState, useEffect, useMemo } from 'react'
import { Search, ChevronDown, ChevronRight, X } from 'lucide-react'
import useRegionStore from '@/context/RegionStore'
import useCountryDetailsStore from '@/context/CountryDetailsStore'
import { useNavigate, useLocation } from 'react-router-dom'
import { CountryInfo, CountryGroup } from '@/services/api'
import useAvailableRegionsStore from '@/context/AvailableRegionsStore'
import { isGlobalRoute } from '@/utils/regionDetection'

const UnsupportedRegionModal: React.FC = () => {
    const [expandedGroups, setExpandedGroups] = useState<string[]>([]);
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate()
    const location = useLocation()
    const { setChosenRegion, setShowRegionModal, showRegionModal } = useRegionStore()
    const { getRegionInfo, countries } = useCountryDetailsStore()
    const { isRegionAvailable } = useAvailableRegionsStore()

    // Lock body scroll when modal is open
    useEffect(() => {
        const originalStyle = window.getComputedStyle(document.body).overflow;
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = originalStyle;
        };
    }, []);

    // Get the region from the URL
    const pathSegments = location.pathname.split('/').filter(Boolean)
    const urlRegion = pathSegments[0]?.toLowerCase()

    // Get country info for the URL region
    const regionInfo = urlRegion ? getRegionInfo(urlRegion) : null
    const countryName = regionInfo?.name || urlRegion?.toUpperCase() || ''
    const countryFlag = regionInfo?.flag || ''

    const handleRegionSelect = (region: string) => {
        setChosenRegion(region);
        setShowRegionModal(false);
        // Always navigate to home screen
        navigate(`/${region}`, { replace: true });
    };

    const toggleGroup = (groupName: string, event: React.MouseEvent) => {
        event.stopPropagation();
        setExpandedGroups(prev =>
            prev.includes(groupName)
                ? prev.filter(g => g !== groupName)
                : [...prev, groupName]
        );
    };

    // Separate countries and groups
    const individualCountries: [string, CountryInfo][] = [];
    const groups: [string, CountryGroup][] = [];

    if (countries) {
        Object.entries(countries).forEach(([key, value]) => {
            if ('type' in value && value.type === 'group') {
                groups.push([key, value]);
            } else {
                individualCountries.push([key, value as CountryInfo]);
            }
        });
    }

    // Filter countries based on search query
    const { filteredIndividualCountries, filteredGroups } = useMemo(() => {
        if (!countries || !searchQuery.trim()) {
            return {
                filteredIndividualCountries: individualCountries,
                filteredGroups: groups
            };
        }

        const query = searchQuery.toLowerCase();
        const matchesSearch = (name: string) => name.toLowerCase().includes(query);

        const filteredIndividual = individualCountries.filter(([_, info]) =>
            matchesSearch(info.name)
        );

        const filteredGroupsData = groups.map(([groupName, group]) => {
            const filteredCountries = Object.entries(group.countries).filter(([_, info]) =>
                matchesSearch(info.name)
            );
            return [
                groupName,
                { ...group, countries: Object.fromEntries(filteredCountries) }
            ] as [string, CountryGroup];
        }).filter(([_, group]) => Object.keys(group.countries).length > 0);

        return {
            filteredIndividualCountries: filteredIndividual,
            filteredGroups: filteredGroupsData
        };
    }, [countries, searchQuery, individualCountries, groups]);

    const renderCountryButton = (code: string, info: CountryInfo) => (
        <button
            key={code}
            onClick={() => handleRegionSelect(code)}
            className="flex items-center gap-2 w-full p-3 hover:bg-[#f4f4f4] transition-colors"
        >
            <span>{info.flag}</span>
            <span className="text-gray-900">{info.name}</span>
        </button>
    );

    // Only show this modal if we have an unsupported region in the URL
    // and we're not on a global route (legal/blog pages)
    if (!showRegionModal || !urlRegion || isRegionAvailable(urlRegion) || isGlobalRoute(location.pathname)) {
        return null;
    }

    console.log(`[REGION] Unsupported region detected: ${urlRegion}`);

    return (
        <div className="fixed inset-0 z-[99998] flex items-center justify-center bg-black/50">
            <div className="bg-white rounded-xl shadow-xl max-w-md w-full mx-4 max-h-[90vh] flex flex-col relative z-[99999]">
                <div className="p-6 border-b border-gray-200">
                    <h2 className="text-2xl font-headline text-gray-900">
                        Region Unavailable {countryFlag}
                    </h2>
                    <p className="mt-2 text-gray-600">
                        Sorry but we don't currently ship to {countryName}.
                        Please select a region where we currently ship to:
                    </p>
                </div>

                <div className="flex-1 overflow-y-auto min-h-0">
                    {filteredIndividualCountries.length === 0 && filteredGroups.length === 0 ? (
                        <div className="flex items-center gap-2 w-full p-3 text-gray-400">
                            <span>🔍</span>
                            <span>No countries found</span>
                        </div>
                    ) : (
                        <>
                            {/* Individual Countries Section */}
                            {filteredIndividualCountries.length > 0 && (
                                <div>
                                    {filteredIndividualCountries.map(([code, info]) =>
                                        renderCountryButton(code, info)
                                    )}
                                </div>
                            )}

                            {/* Groups Section */}
                            {filteredGroups.map(([groupName, group]) => (
                                <div key={groupName} className="relative">
                                    <button
                                        onClick={(e) => toggleGroup(groupName, e)}
                                        className="flex items-center justify-between w-full px-3 py-2 text-sm font-semibold text-gray-500 hover:bg-gray-50 sticky top-0 bg-white z-10"
                                    >
                                        <span>{groupName}</span>
                                        {(expandedGroups.includes(groupName) || searchQuery) ? (
                                            <ChevronDown className="w-4 h-4" />
                                        ) : (
                                            <ChevronRight className="w-4 h-4" />
                                        )}
                                    </button>
                                    {(expandedGroups.includes(groupName) || searchQuery) && (
                                        <div>
                                            {Object.entries(group.countries).map(([code, info]) =>
                                                renderCountryButton(code, info)
                                            )}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </>
                    )}
                </div>

                <div className="p-4 border-t border-gray-200">
                    <div className="relative">
                        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 w-4 h-4 text-gray-400" />
                        <input
                            type="text"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            placeholder="Search countries..."
                            className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange focus:border-transparent"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UnsupportedRegionModal 