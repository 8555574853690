import React, { useState, useEffect } from 'react'
import InfoButton from './InfoButton'
import { Ban } from 'lucide-react'
import { IInfoDetails } from '@/context/ProductDataStore'
export interface IAddonItem {
	id: string | null
	imageUrl: string
	title: string
	price: number
	currency: string
	triggersSpecialSelect?: boolean
	triggerInput?: boolean
	inputPlaceholder?: string
	inputMaxLength?: number
	inputPattern?: string
	inputClassName?: string
	inputValidation?: (value: string) => boolean
	originalPrice?: number
}

type AddonProps = {
	title: string
	description: string | null
	items: IAddonItem[]
	activeId: string | null
	onSelect: (id: string | null) => void
	onSpecialSelect?: () => void
	onInputChange?: (id: string, value: string) => void
	required?: boolean
	className?: string
	info?: IInfoDetails
}

const Addon: React.FC<AddonProps> = ({
	title,
	description,
	items,
	activeId,
	onSelect,
	onSpecialSelect = null,
	onInputChange,
	required = false,
	className = '',
	info
}) => {
	const [inputVisible, setInputVisible] = useState<boolean>(false)
	const [inputValue, setInputValue] = useState<string>('')
	const [addonItems, setAddonItems] = useState<IAddonItem[]>([])
	const noSelection: IAddonItem = {
		id: null,
		imageUrl: '',
		title: 'No, thank you',
		price: 0,
		currency: ''
	}

	useEffect(() => {
		if (required) {
			setAddonItems(items)
		} else {
			setAddonItems([noSelection, ...items])
		}
	}, [items, required])

	useEffect(() => {
		if (activeId && onInputChange) {
			onInputChange(activeId, inputValue)
		}
	}, [inputValue])

	return (
		<div className="addon flex flex-col gap-0">
			<div className="addon-meta flex flex-col gap-1">
				<div className="flex flex-row gap-4 justify-between items-center">
					<h2 className="text-black leading-none m-0">{title}</h2>
					{info && <InfoButton title={info.title} text={info.text} />}
				</div>
				<p className={`leading-none ${!description && 'hidden'}`}>{description}</p>
			</div>

			<div className="flex overflow-x-auto pt-4 pb-0 px-4 -mx-4 items-start no-scrollbar gap-1 relative">
				{addonItems.map((item: IAddonItem, index: number) => {
					return (
						<div className="item flex flex-col gap-3 m-1 grow-0 max-w-[120px] transform-gpu" key={item.id}>
							<button
								onClick={() => {
									if (item.triggersSpecialSelect && onSpecialSelect) {
										onSpecialSelect()
										onSelect(item.id)
									} else if (item.triggerInput) {
										setInputVisible(true)
										onSelect(item.id)
									} else {
										setInputVisible(false)
										onSelect(item.id)
									}
								}}
								className={`product-thumbnail w-[120px] aspect-[4/4] transition-all ease-in-out border border-[3px] border-background rounded-xl 
									overflow-hidden outline outline-[3px] md:hover:outline-orange hover:duration-75 md:hover:scale-105 bg-white relative
									drop-shadow-addons supports-[hover:hover]:hover:outline-orange supports-[hover:hover]:hover:duration-75 supports-[hover:hover]:hover:scale-105 cursor-pointer
									${item.id === activeId
										? 'outline-orange duration-100'
										: 'outline-transparent duration-300'
									}`}
							>
								{item.imageUrl === ''
									? <div className='w-full h-full flex items-center justify-center'><Ban className='h-8 w-8 text-grey' /></div>
									: <div className="absolute inset-0">
										<img src={item.imageUrl} alt={item.title} className="w-full h-full object-cover" />
									</div>
								}
							</button>
							<div className="item-info relative flex flex-col grow-0 leading-none relative left-1">
								<h4 className="relative m-0 max-w-full">{item.title}</h4>
								<h5 className={`text-orange text-base m-0 ${item.price <= 0 && 'hidden'}`}>+{item.currency}{item.price}</h5>
							</div>
						</div>
					)
				})}
			</div>

			<div className={`input flex flex-col gap-2 mt-2 overflow-visible transition-all duration-500 ease-hypereaseout ${inputVisible ? 'max-h-20 opacity-100 mb-2' : 'max-h-0 opacity-0 pointer-events-none'}`}>
				{inputVisible && (
					<div className="relative w-full">
						<input
							className={activeId && items.find(item => item.id === activeId)?.inputClassName ||
								'w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange focus:border-transparent font-body'}
							title={activeId && items.find(item => item.id === activeId)?.inputPlaceholder || title}
							placeholder={activeId && items.find(item => item.id === activeId)?.inputPlaceholder || title}
							value={inputValue}
							maxLength={activeId ? items.find(item => item.id === activeId)?.inputMaxLength : undefined}
							pattern={activeId ? items.find(item => item.id === activeId)?.inputPattern : undefined}
							onChange={(e) => {
								const activeItem = items.find(item => item.id === activeId)
								if (activeItem?.inputValidation) {
									if (activeItem.inputValidation(e.target.value)) {
										setInputValue(e.target.value)
									}
								} else {
									setInputValue(e.target.value)
								}
							}}
						/>
						{activeId && items.find(item => item.id === activeId)?.inputMaxLength && (
							<div className="absolute right-3 top-1/2 -translate-y-1/2 text-sm text-gray-500">
								{inputValue.length}/{items.find(item => item.id === activeId)?.inputMaxLength}
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	)
}

export default Addon
