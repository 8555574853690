import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { IBasketItem } from './BasketStore'

interface IOrderSummary {
    items: IBasketItem[]
    subtotal: number
    delivery: number
    total: number
}

interface IOrderSummaryStore {
    currentOrder: IOrderSummary | null
    setCurrentOrder: (items: IBasketItem[], subtotal: number, delivery: number) => void
    clearCurrentOrder: () => void
}

const useOrderSummaryStore = create<IOrderSummaryStore>()(
    persist(
        (set) => ({
            currentOrder: null,
            setCurrentOrder: (items, subtotal, delivery) => set({
                currentOrder: {
                    items,
                    subtotal,
                    delivery,
                    total: subtotal + delivery
                }
            }),
            clearCurrentOrder: () => set({ currentOrder: null })
        }),
        {
            name: 'order-summary-storage'
        }
    )
)

export default useOrderSummaryStore 