import useCountryDetailsStore from '@/context/CountryDetailsStore';
import useRegionStore from '@/context/RegionStore';
import { useEffect } from 'react';

interface CurrencyInfo {
    symbol: string;
    code: string;
    name: string;
    formatPrice: (amount: number) => string;
    freeDeliveryThreshold: number | null;
    freeDeliveryThresholdFormatted: string | null;
}

export const useCurrency = (): CurrencyInfo => {
    const { countryDetails, fetchDetails } = useCountryDetailsStore();
    const { chosenRegion } = useRegionStore();

    const defaultCurrency = {
        symbol: '£',
        code: 'GBP',
        name: 'British Pound'
    };

    // Fetch details for the chosen region
    useEffect(() => {
        if (chosenRegion) {
            fetchDetails(chosenRegion);
        }
    }, [chosenRegion, fetchDetails]);

    const currency = countryDetails?.currency || defaultCurrency;

    const formatPrice = (amount: number): string => {
        // Convert amount from cents/pence to actual currency
        const actualAmount = amount / 100;

        // Use the browser's Intl.NumberFormat for proper currency formatting
        return new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: currency.code,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(actualAmount);
    };

    return {
        ...currency,
        formatPrice,
        freeDeliveryThreshold: countryDetails?.freeDeliveryThreshold ?? null,
        freeDeliveryThresholdFormatted: countryDetails?.freeDeliveryThresholdFormatted ?? null
    };
}; 