import React from 'react';
import { Check, X } from 'lucide-react';
import { ComparisonModuleProps } from '../types/comparison.types';
import { defaultFeatures } from '../data/comparisonData';
import './ComparisonModule.css';

const ComparisonModule: React.FC<ComparisonModuleProps> = ({
    title = 'Why Choose Kibu?',
    subtitle = "See how Kibu headphones compare to standard children's headphones",
    className = '',
    features = defaultFeatures
}) => {
    return (
        <div className={`module_container ${className}`}>
            <div className="comparison-header">
                <h2 className="comparison-title">{title}</h2>
                <p className="comparison-subtitle">{subtitle}</p>
            </div>

            <div className="relative">
                <div className="kibu-column-highlight" />
                <table className="comparison-table">
                    <colgroup>
                        <col />
                        <col />
                        <col />
                    </colgroup>
                    <thead>
                        <tr className="comparison-row">
                            <th className="comparison-feature text-left"></th>
                            <th className="comparison-check">Kibu</th>
                            <th className="comparison-check">Others</th>
                        </tr>
                    </thead>
                    <tbody>
                        {features.map((feature, index) => (
                            <tr key={index} className="comparison-row">
                                <td className="comparison-feature">
                                    <div className="feature-header">
                                        {/* {feature.icon && (
                                                <img
                                                    src={feature.icon}
                                                    alt={feature.title}
                                                    className="feature-icon"
                                                />
                                            )} */}
                                        <h3 className="feature-title">{feature.title}</h3>
                                    </div>
                                    <p className="feature-description">{feature.description}</p>
                                </td>
                                <td className="comparison-check">
                                    <div className={`check-circle ${feature.kibuHas ? 'active' : 'inactive'}`}>
                                        {feature.kibuHas ? (
                                            <Check className="check-icon has" />
                                        ) : (
                                            <X className="check-icon hasnt" />
                                        )}
                                    </div>
                                </td>
                                <td className="comparison-check">
                                    <div className={`check-circle ${feature.othersHave ? 'active' : 'inactive'}`}>
                                        {feature.othersHave ? (
                                            <Check className="check-icon has" />
                                        ) : (
                                            <X className="check-icon hasnt" />
                                        )}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ComparisonModule; 