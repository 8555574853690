import { motion } from "framer-motion";
import { CheckCircle } from "lucide-react";

const customEasing = [0.6, -0.05, 0.01, 0.99]; // Example cubic bezier easing

const AnimatedCheckCircle = () => {
  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="mx-auto h-16 w-16 text-green-500"
    >
      {/* Circle */}
      <motion.circle
        cx="12"
        cy="12"
        r="10"
        strokeDasharray="63"
        strokeDashoffset="63"
        animate={{ strokeDashoffset: 0 }}
        transition={{ duration: 2, ease: customEasing }}
      />
      
      {/* Check Mark */}
      <motion.path
        d="M9 12l2 2 4-4"
        strokeDasharray="10"
        strokeDashoffset="10"
        animate={{ strokeDashoffset: 0 }}
        transition={{ duration: 1.2, ease: customEasing, delay: 1.2 }}
      />
    </motion.svg>
  );
};

export default AnimatedCheckCircle;
