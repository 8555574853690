import useBasketStore from '@/context/BasketStore'
import { ShoppingCart } from 'lucide-react'
import { Link } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import RegionSelectorModal from '../RegionSelectorModal'
import useRegionStore from '@/context/RegionStore'
import useCountryDetailsStore from '@/context/CountryDetailsStore'
import { buildRegionUrl } from '@/utils/regionDetection'

interface NavProps {
	isVisible: boolean;
	onHeightChange: (height: number) => void;
	className?: string;
	disableScrollAnimation?: boolean;
}

const Nav = ({ isVisible, onHeightChange, className = '', disableScrollAnimation = false }: NavProps) => {
	const { basketOpen, setBasketOpen, itemCount } = useBasketStore()
	const navRef = useRef<HTMLElement>(null)
	const [isRegionModalOpen, setIsRegionModalOpen] = useState(false)
	const { chosenRegion, detectedRegion } = useRegionStore()
	const { getRegionInfo } = useCountryDetailsStore()

	useEffect(() => {
		if (!navRef.current) return

		const resizeObserver = new ResizeObserver(entries => {
			for (const entry of entries) {
				onHeightChange(entry.contentRect.height)
			}
		})

		resizeObserver.observe(navRef.current)
		return () => resizeObserver.disconnect()
	}, [onHeightChange])

	const countryInfo = getRegionInfo(chosenRegion || detectedRegion)

	const Logo = ({ fill = "#FF5C00" }: { fill?: string }) => (
		<Link to="" aria-label="Home">
			<svg className="h-10 w-auto" viewBox="0 0 33 49" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M27.6441 17.2072L30.5811 14.2702C32.076 12.7775 33 10.7139 33 8.43469C33 3.87849 29.3061 0.184694 24.75 0.184694C22.4708 0.184694 20.4072 1.10869 18.9145 2.60359L16.5011 5.01699V0.183594H0V16.6836H4.8323L2.4134 19.1025C0.9229 20.5952 0 22.6566 0 24.9336C0 29.4897 3.6938 33.1836 8.25 33.1836C10.5292 33.1836 12.5928 32.2596 14.0855 30.7647L17.0225 27.8277C18.1951 30.956 21.2113 33.1836 24.7489 33.1836C29.3051 33.1836 32.9989 29.4897 32.9989 24.9336C32.9989 21.396 30.7713 18.3787 27.643 17.2072H27.6441Z"
					fill={fill}
					style={{ fill: fill, fillOpacity: 1 }}
				/>
				<path
					d="M27.8269 48.8157C26.0896 48.8157 24.8145 47.5724 24.8145 45.7235V40.623H26.6315V45.3729C26.6315 46.4886 27.2531 47.158 28.2254 47.158C29.2614 47.158 29.9309 46.3929 29.9309 45.2453V40.623H31.7479V48.6244H30.154L30.0743 47.7637H29.9787C29.4846 48.4331 28.7992 48.8157 27.8269 48.8157Z"
					fill={fill}
					style={{ fill: fill, fillOpacity: 1 }}
				/>
				<path
					d="M19.3432 48.8153C18.2435 48.8153 17.319 48.3849 16.8408 47.6677H16.7452L16.6496 48.624H15.0557V37.1479H16.8727V41.4674H16.9683C17.4624 40.8139 18.3231 40.4314 19.3592 40.4314C21.4631 40.4314 23.0251 42.2165 23.0251 44.6233C23.0251 47.0301 21.4631 48.8153 19.3432 48.8153ZM18.9766 47.1735C20.2518 47.1735 21.1284 46.1375 21.1284 44.6233C21.1284 43.1091 20.2518 42.0731 18.9766 42.0731C17.7015 42.0731 16.8727 43.0932 16.8727 44.6233C16.8727 46.1694 17.7015 47.1735 18.9766 47.1735Z"
					fill={fill}
					style={{ fill: fill, fillOpacity: 1 }}
				/>
				<path
					d="M11.0858 48.6241V40.6227H12.9028V48.6241H11.0858ZM11.9943 39.4751C11.3567 39.4751 10.8467 38.981 10.8467 38.3275C10.8467 37.674 11.3567 37.1799 11.9943 37.1799C12.6318 37.1799 13.1419 37.674 13.1419 38.3275C13.1419 38.981 12.6318 39.4751 11.9943 39.4751Z"
					fill={fill}
					style={{ fill: fill, fillOpacity: 1 }}
				/>
				<path
					d="M1.19336 48.6241V37.4668H3.07415V42.0413H3.88704L7.17046 37.4668H9.40191L5.54469 42.8382V42.9339L9.641 48.6241H7.40955L3.88704 43.7467H3.07415V48.6241H1.19336Z"
					fill={fill}
					style={{ fill: fill, fillOpacity: 1 }}
				/>
			</svg>
		</Link>
	)

	const BasketButton = ({ isOrange = false }: { isOrange?: boolean }) => (
		<button
			onClick={() => setBasketOpen(!basketOpen)}
			className={`flex items-center text-xs font-headline font-bold gap-1 transition-all  duration-1000 group/cartbutton
				hover:gap-2 hover:ease-hypereaseout
				${isOrange ? 'text-orange' : 'text-white'}`}
		>
			<ShoppingCart className={`w-6 h-6 ${isOrange ? 'text-orange' : 'text-white'}`} />
			<div className='scale-100 transition-all duration-1000 group-hover/cartbutton:scale-150 group-hover/cartbutton:ease-hypereaseout'>{itemCount}</div>
		</button>
	)

	return (
		<>
			{/* Shadow nav elements */}
			<div className="fixed top-0 left-0 z-30 md:pl-5 pl-5 h-[70px] hidden md:flex items-center">
				<Logo fill="#FF5C00" />
			</div>

			<div className="fixed top-0 right-0 z-30 md:pr-5 pr-5 h-[70px] hidden md:flex items-center gap-6">
				<BasketButton isOrange={true} />
			</div>

			{/* Main nav */}
			<nav
				ref={navRef}
				className={`fixed top-0 left-0 bg-primary md:pl-5 md:pr-5 pl-5 pr-5 w-full h-[70px] ${!disableScrollAnimation ? 'transition-transform duration-700 will-change-transform' : ''} ${!disableScrollAnimation ? (isVisible ? 'translate-y-0' : '-translate-y-full') : 'translate-y-0'} ${className}`}
			>
				<div className="mx-auto flex justify-between items-center h-full w-full">
					<Logo fill="white" />
					<div className="flex items-center space-x-6">
						<Link
							to={buildRegionUrl(chosenRegion || detectedRegion || 'gb', 'products/wired-headphones-1')}
							className="text-white px-4 py-2 rounded-lg hover:bg-white hover:text-primary transition-colors font-headline"
						>
							Headphones
						</Link>
						<button
							onClick={() => setIsRegionModalOpen(true)}
							className="w-10 h-10 rounded-full bg-white/10 hover:bg-white/20 transition-colors flex items-center justify-center text-white"
							aria-label="Change region"
						>
							{countryInfo?.flag || '🌍'}
						</button>
						<BasketButton />
					</div>
				</div>
			</nav>

			{/* Region Modal */}
			{isRegionModalOpen && (
				<RegionSelectorModal onClose={() => setIsRegionModalOpen(false)} />
			)}
		</>
	)
}

export default Nav
