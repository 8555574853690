import { ComparisonFeature } from '../types/comparison.types';

export const defaultFeatures: ComparisonFeature[] = [
    {
        title: 'Education Build Kit',
        description: 'Learn STEM skills while building your own headphones',
        kibuHas: true,
        othersHave: false,
        icon: '/assets/icons/build.svg'
    },
    {
        title: 'Sustainable Materials',
        description: 'Made from recycled and plant-based materials',
        kibuHas: true,
        othersHave: false,
        icon: '/assets/icons/sustainable.svg'
    },
    {
        title: 'Easy to Repair',
        description: 'Modular design allows for simple part replacement',
        kibuHas: true,
        othersHave: false,
        icon: '/assets/icons/repair.svg'
    },
    {
        title: 'Universal Safe Listening',
        description: 'Volume limiting technology works with any audio device',
        kibuHas: true,
        othersHave: false,
        icon: '/assets/icons/safe.svg'
    },
    {
        title: 'Customisable Design',
        description: 'Choose your colors and styles',
        kibuHas: true,
        othersHave: false,
        icon: '/assets/icons/customize.svg'
    },
    {
        title: 'Made in Britain',
        description: 'Locally manufactured with quality control',
        kibuHas: true,
        othersHave: false,
        icon: '/assets/icons/britain.svg'
    }
]; 