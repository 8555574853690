import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import LegalPageLayout from '../components/LegalPageLayout';
import { buildRegionUrl, getRegionFromUrl } from '../utils/regionDetection';

const TermsOfService: React.FC = () => {
    const location = useLocation();
    const currentRegion = getRegionFromUrl(location.pathname) || 'gb';

    const currentDate = new Date().toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
    });

    return (
        <LegalPageLayout title="Kibu Terms of Service">
            <div className="space-y-8">
                <div className="text-gray-600 italic mb-8">
                    Last Updated: {currentDate}
                </div>

                <section>
                    <h2 className="text-2xl font-headline mb-4">Welcome to Kibu!</h2>
                    <p>
                        These Terms of Service ("Terms") govern your use of the Kibu website (the "Site") and the purchase
                        of any products or services ("Products") from Kibu. By accessing our Site or placing an order,
                        you agree to be bound by these Terms. If you do not agree, please discontinue using our Site.
                    </p>
                </section>

                <section>
                    <h2 className="text-2xl font-headline mb-4">1. About Us</h2>
                    <p>
                        Kibu is operated by Kibu (Company No. 15495561).
                    </p>
                    <ul className="list-none space-y-2 mt-4">
                        <li><strong>Registered Office:</strong> 5 Sheep Lane, Hackney Depot, London, E8 4QS</li>
                        <li><strong>Correspondence & Returns Address:</strong> 1a Dunn Street, London, E8 2DG</li>
                        <li><strong>Contact Email:</strong> contact@kibu.family</li>
                    </ul>
                    <p className="mt-4">
                        We are currently below the VAT threshold and therefore do not charge VAT at this time.
                    </p>
                </section>

                <section>
                    <h2 className="text-2xl font-headline mb-4">2. Eligibility</h2>
                    <ul className="list-disc pl-6 space-y-4">
                        <li>
                            <strong>Adults Only:</strong> You must be at least 18 years old (or have the consent and
                            supervision of a legal guardian) to purchase Products from our Site.
                        </li>
                        <li>
                            <strong>Location:</strong> We currently ship only within the United Kingdom.
                        </li>
                    </ul>
                </section>

                <section>
                    <h2 className="text-2xl font-headline mb-4">3. Products & Custom Engravings</h2>
                    <ul className="list-disc pl-6 space-y-4">
                        <li>
                            <strong>Product Descriptions:</strong> We specialize in children's headphones made-to-order
                            with custom color options and optional headband engravings. We strive for accuracy in Product
                            descriptions, but slight variations may occur.
                        </li>
                        <li>
                            <strong>Right to Reject Engraving:</strong> We reserve the right to reject any proposed
                            engraving text that is offensive, infringing, or otherwise inappropriate, at our sole discretion.
                        </li>
                        <li>
                            <strong>Custom-Made Items:</strong> Our headphones are created with 3D printing technology
                            specifically for each customer to reduce waste and personalize the final product.
                        </li>
                    </ul>
                </section>

                <section>
                    <h2 className="text-2xl font-headline mb-4">4. Orders & Checkout</h2>
                    <ul className="list-disc pl-6 space-y-4">
                        <li>
                            <strong>Guest Checkout:</strong> You can check out without creating an account. You agree
                            to provide complete and accurate information for processing your order.
                        </li>
                        <li>
                            <strong>Order Confirmation:</strong> Once you place an order, we will send an email confirming
                            receipt of your order. This email is not an acceptance of your order; acceptance occurs when
                            we dispatch the Product(s). If we cannot accept your order, we will notify you.
                        </li>
                        <li>
                            <strong>Payment:</strong>
                            <ul className="list-disc pl-6 mt-2 space-y-2">
                                <li><strong>Stripe:</strong> We use Stripe to process payments. By placing an order, you also agree to comply with
                                    Stripe's terms and conditions.</li>
                                <li><strong>Currency:</strong> All prices are in GBP.</li>
                                <li><strong>Taxes:</strong> We do not currently add VAT to our prices. If our VAT status changes, we will update these Terms and the pricing on our Site accordingly.</li>
                            </ul>
                        </li>
                    </ul>
                </section>

                <section>
                    <h2 className="text-2xl font-headline mb-4">5. Production & Shipping</h2>
                    <div className="space-y-4">
                        <div>
                            <h3 className="text-xl font-headline mb-2">5.1 Production Time</h3>
                            <p>
                                Custom Production: Once you place an order, we produce your headphones using 3D printers. Production typically
                                takes around 5 working days before dispatch.
                            </p>
                        </div>
                        <div>
                            <h3 className="text-xl font-headline mb-2">5.2 Delivery Options</h3>
                            <ul className="list-disc pl-6 space-y-2">
                                <li>Free Delivery: If your order is over £50, standard delivery is free. Otherwise, it costs £3.</li>
                                <li>Standard Delivery: Takes 3–5 business days after production.</li>
                                <li>Faster Delivery: If you choose our faster delivery option for £10, you can expect your order
                                    in 1–2 business days after production.</li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="text-xl font-headline mb-2">5.3 Disclaimers</h3>
                            <ul className="list-disc pl-6 space-y-2">
                                <li>Estimated Times: All delivery times are estimates and may vary due to factors beyond our control (e.g., courier delays, weather, strikes). We are not liable for delays once the order has been handed to the courier.</li>
                                <li>UK Only: We currently deliver only within the United Kingdom.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                <section>
                    <h2 className="text-2xl font-headline mb-4">6. Returns, Refunds & Exchanges</h2>
                    <div className="space-y-4">
                        <div>
                            <h3 className="text-xl font-headline mb-2">6.1 Return Period</h3>
                            <p>
                                You have 14 days from the date of receiving your Product(s) to request a return or refund,
                                unless your item is faulty, in which case statutory rights apply.
                            </p>
                        </div>
                        <div>
                            <h3 className="text-xl font-headline mb-2">6.2 Return Process</h3>
                            <ul className="list-disc pl-6 space-y-2">
                                <li><strong>Contact Us:</strong> Email us at contact@kibu.family to initiate a return. Please provide proof of purchase (order confirmation or receipt).</li>
                                <li><strong>Return Shipping:</strong> You are responsible for arranging and covering the cost of shipping the Product(s) back to us at 1a Dunn Street, London, E8 2DG. We recommend using a trackable shipping service.</li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="text-xl font-headline mb-2">6.3 Refunds for Custom-Made Products</h3>
                            <p className="mb-2">
                                We recognize that our headphones are custom-made with user-selected colors and/or engravings. While UK consumer law often excludes personalized goods from refund obligations (except when faulty), we choose to allow returns subject to the following fees:
                            </p>
                            <ul className="list-disc pl-6 space-y-2">
                                <li><strong>Custom Color Fee:</strong> A £6 fee will be deducted from your refund for custom-color headphones.</li>
                                <li><strong>Engraving Fee:</strong> If your order included a headband engraving, an additional £3 fee will be deducted from your refund.</li>
                            </ul>
                            <p className="mt-2">
                                These fees cover the cost of materials and production time. Once we receive and inspect the returned Product(s), your refund (minus the applicable fees) will be issued to the original payment method. Refunds may take up to 10 business days to appear, depending on your bank's processing times.
                            </p>
                        </div>
                        <div>
                            <h3 className="text-xl font-headline mb-2">6.4 Faulty or Incorrect Items</h3>
                            <p>
                                If your Product is faulty or incorrect, please contact us within 14 days of receipt. We will either offer a replacement or a full refund (including return shipping costs), at our discretion, in line with your statutory consumer rights.
                            </p>
                        </div>
                    </div>
                </section>

                <section>
                    <h2 className="text-2xl font-headline mb-4">7. Intellectual Property</h2>
                    <ul className="list-disc pl-6 space-y-4">
                        <li>
                            <strong>Site Content:</strong> The Site (including text, images, graphics, logos, and other content) is owned or licensed by Kibu. You may not reproduce, distribute, or create derivative works without our express written permission.
                        </li>
                        <li>
                            <strong>User Content:</strong> By submitting any content (e.g., reviews, feedback) to the Site, you grant us a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, and display such content for our business purposes.
                        </li>
                    </ul>
                </section>

                <section>
                    <h2 className="text-2xl font-headline mb-4">8. User Obligations</h2>
                    <p>You agree that you will:</p>
                    <ul className="list-disc pl-6 space-y-2 mt-4">
                        <li>Provide accurate and truthful information.</li>
                        <li>Not use the Site for any illegal or unauthorized purpose.</li>
                        <li>Not submit offensive or infringing content (including engraving requests or reviews).</li>
                    </ul>
                </section>

                <section>
                    <h2 className="text-2xl font-headline mb-4">9. Limitation of Liability</h2>
                    <ul className="list-disc pl-6 space-y-4">
                        <li>
                            <strong>As Is:</strong> The Site and Products are provided "as is" and "as available." We make no warranties, express or implied, regarding the Site's operation or the Products' fitness for a particular purpose.
                        </li>
                        <li>
                            <strong>Maximum Liability:</strong> Except for any legal responsibility that we cannot exclude in law (e.g., death or personal injury caused by our negligence), our liability to you for any loss or damage is limited to the price you have paid for the Products.
                        </li>
                        <li>
                            <strong>No Continuous Access Guarantee:</strong> We do not guarantee uninterrupted or secure access to the Site, and we will not be liable for any disruption or delays.
                        </li>
                    </ul>
                </section>

                <section>
                    <h2 className="text-2xl font-headline mb-4">10. Governing Law & Disputes</h2>
                    <p>
                        These Terms and any contract formed under them are governed by the laws of England and Wales. You agree that any disputes shall be subject to the exclusive jurisdiction of the courts of England and Wales.
                    </p>
                </section>

                <section>
                    <h2 className="text-2xl font-headline mb-4">11. Privacy & Cookies</h2>
                    <p>
                        Your use of the Site is also governed by our <Link to={buildRegionUrl(currentRegion, 'legal/privacy-policy')} className="text-primary hover:underline">Privacy Policy</Link>, which explains how we collect and use your personal data, and by our Cookie Policy, which outlines how we use cookies and similar technologies.
                    </p>
                </section>

                <section>
                    <h2 className="text-2xl font-headline mb-4">12. Contact Information</h2>
                    <p>For any questions regarding these Terms, please reach out to us at:</p>
                    <ul className="list-none space-y-2 mt-4">
                        <li><strong>Email:</strong> contact@kibu.family</li>
                        <li><strong>Address:</strong> 1a Dunn Street, London, E8 2DG</li>
                    </ul>
                </section>

                <section>
                    <h2 className="text-2xl font-headline mb-4">13. Changes to These Terms</h2>
                    <p>
                        We may update these Terms from time to time to reflect changes in our practices, Products, or legal
                        obligations. Any changes will be posted on the Site. Your continued use of the Site after changes
                        are posted constitutes your acceptance of the modified Terms.
                    </p>
                </section>
            </div>
        </LegalPageLayout>
    );
};

export default TermsOfService;
