import React, { useState, useEffect, useContext } from 'react'
import { StarHalf, StarIcon, ChevronDown, ChevronUp, ArrowDown } from 'lucide-react'
import { IMainProduct } from '../types'
import useProductDataStore from '@/context/ProductDataStore'
import { ScrollContainerContext } from '@/components/ScrollToTop'
import Skeleton from '@/components/Skeleton'

interface ProductInfoProps {
	_product: IMainProduct
	currentPrice: number,
	currency: string,
	className?: string,
	isLoading?: boolean
}

const ProductInfo: React.FC<ProductInfoProps> = ({ _product, currentPrice, currency, className, isLoading = false }) => {
	const { product } = useProductDataStore()
	const [textExpanded, setTextExpanded] = useState<boolean>(false)
	const [isMobile, setIsMobile] = useState<boolean>(false)
	const { contentRef, navHeight } = useContext(ScrollContainerContext);

	useEffect(() => {
		const checkMobile = () => {
			setIsMobile(window.innerWidth <= 768)
		}

		checkMobile()
		window.addEventListener('resize', checkMobile)

		return () => window.removeEventListener('resize', checkMobile)
	}, [])

	const productDescription: string = isMobile
		? (product?.productDescription?.mobile || '')
		: (product?.productDescription?.desktop || '')
	const words = productDescription.split(' ')
	const truncatedText = words.slice(0, 20).join(' ') + ' ...'

	const renderStars = () => {
		const rating = product?.averageCatalogueRating || 0;
		const stars = [];
		const totalStars = 5;

		for (let i = 1; i <= totalStars; i++) {
			if (i <= Math.floor(rating)) {
				// Full star
				stars.push(
					<div key={i} className="w-6 h-6 bg-green rounded-md border border-green/20 relative">
						<StarIcon className="w-4 h-4 text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" fill="white" stroke='white' />
					</div>
				);
			} else if (i === Math.ceil(rating) && !Number.isInteger(rating)) {
				// Half star
				stars.push(
					<div key={i} className="w-6 h-6 relative">
						<div className="absolute top-0 left-0 w-1/2 h-full bg-[#179D1A]"></div>
						<div className="absolute top-0 right-0 w-1/2 h-full bg-gray-300"></div>
						<StarIcon className="w-4 h-4 text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" fill="text-orange" />
					</div>
				);
			} else {
				// Empty star
				stars.push(
					<div key={i} className="w-6 h-6 bg-gray-300 relative">
						<StarIcon className="w-4 h-4 text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" fill="currentColor" />
					</div>
				);
			}
		}
		return stars;
	};

	const scrollToInfo = () => {
		const element = document.getElementById("testimonials")
		if (element && contentRef?.current) {
			// Get the sticky nav height (100px height + 30px padding)
			const stickyNavHeight = 100

			// Calculate total offset (main nav height + sticky nav)
			const totalOffset = navHeight + stickyNavHeight

			// Get element's position relative to the scroll container
			const containerRect = contentRef.current.getBoundingClientRect()
			const elementRect = element.getBoundingClientRect()
			const elementPosition = elementRect.top - containerRect.top + contentRef.current.scrollTop

			const offsetPosition = elementPosition - totalOffset

			contentRef.current.scrollTo({
				top: offsetPosition,
				behavior: "smooth"
			})
		}
	}

	return (
		<div className={`flex flex-col justify-start gap-4 mb-2 ${className}`}>
			{isLoading ? (
				<div className="flex flex-col gap-4">
					<Skeleton className="h-12 w-3/4" /> {/* Title */}
					<Skeleton className="h-10 w-32" /> {/* Price */}
					<div className="flex flex-row gap-1"> {/* Stars */}
						{[1, 2, 3, 4, 5].map((_, index) => (
							<Skeleton key={index} className="w-6 h-6 rounded-md" />
						))}
						<Skeleton className="h-6 w-20 ml-2" /> {/* Reviews count */}
					</div>
					<div className="flex flex-col gap-2"> {/* Description */}
						<Skeleton className="h-4 w-full" />
						<Skeleton className="h-4 w-5/6" />
						<Skeleton className="h-4 w-4/6" />
					</div>
					<Skeleton className="h-10 w-32 mt-2" /> {/* Find out more button */}
				</div>
			) : (
				<>
					<h1 className="leading-none">{product?.productTitle}</h1>
					<h2 className="text-orange leading-none">{currency}{currentPrice}</h2>
					<div className="flex flex-row items-center gap-2">
						<div className="flex flex-row gap-1">
							{renderStars()}
						</div>
						<div className="text-sm">{product?.totalCatalogueReviews} Reviews</div>
					</div>
					{productDescription}
					<button
						className="flex items-center p-2 w-max justify-center gap-1 w-min-content rounded-md font-headline text-blue leading-none
							transition-all ease-kibu duration-200 items-center cursor-pointer border border-2 border-blue
							hover:bg-blue hover:rounded-lg hover:brightness-105 hover:border-black/10 hover:scale-[1.02] hover:text-white"
						onClick={() => scrollToInfo()}>
						<ArrowDown className='h-4 w-4' />
						Find out more
					</button>
				</>
			)}
		</div>
	)
}

export default ProductInfo
