import React, { useState } from 'react';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import { buildRegionUrl, SUPPORTED_REGIONS } from '@/utils/regionDetection';
import useRegionStore from '@/context/RegionStore';
import { ChevronDown, Globe } from 'lucide-react';

interface RegionInfo {
    title: string;
    description: string;
    flag: string;
    name: string;
    currency: string;
}

const REGION_INFO: Record<string, RegionInfo> = {
    gb: {
        title: 'Visit the United Kingdom Store',
        description: "Shop in the UK with local shipping and support.",
        flag: '🇬🇧',
        name: 'United Kingdom',
        currency: '£'
    },
    us: {
        title: 'Visit the United States Store',
        description: "Shop in the US with local shipping and support.",
        flag: '🇺🇸',
        name: 'United States',
        currency: '$'
    }
};

const ComingSoon: React.FC = () => {
    const [isRegionSelectorOpen, setIsRegionSelectorOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { chosenRegion, setChosenRegion } = useRegionStore();

    // If someone tries to access a supported region's coming soon page, redirect to shop
    if (SUPPORTED_REGIONS.includes(chosenRegion as string)) {
        return <Navigate to={chosenRegion === 'gb' ? '/shop' : `/${chosenRegion}/shop`} replace />;
    }

    const handleRegionChange = (newRegion: string) => {
        setChosenRegion(newRegion);
        if (newRegion === 'gb') {
            navigate('/shop');
        } else {
            navigate(`/${newRegion}/shop`);
        }
        setIsRegionSelectorOpen(false);
    };

    return (
        <div className="min-h-[80vh] flex items-center justify-center">
            <div className="max-w-2xl mx-auto px-6 py-16 text-center">
                <h1 className="text-4xl md:text-5xl font-headline mb-6">
                    Coming Soon to {chosenRegion?.toUpperCase()}! 🌍
                </h1>
                <p className="text-xl text-gray-600 mb-12">
                    We're working hard to bring Kibu's headphones to your region.
                    In the meantime, you can visit one of our active stores:
                </p>

                <div className="flex flex-col gap-6 max-w-md mx-auto">
                    {/* Region Selector */}
                    <div className="relative">
                        <button
                            onClick={() => setIsRegionSelectorOpen(!isRegionSelectorOpen)}
                            className="flex items-center gap-2 w-full bg-orange text-white px-6 py-4 rounded-lg hover:bg-orange/90 transition-colors"
                        >
                            <Globe className="w-5 h-5" />
                            <span className="flex-1">Choose Available Store</span>
                            <ChevronDown className={`w-5 h-5 transform transition-transform ${isRegionSelectorOpen ? 'rotate-180' : ''}`} />
                        </button>

                        {isRegionSelectorOpen && (
                            <div className="absolute top-full mt-2 left-0 w-full bg-white text-gray-900 rounded-lg shadow-lg overflow-hidden">
                                {Object.entries(REGION_INFO).map(([code, region]) => (
                                    <button
                                        key={code}
                                        onClick={() => handleRegionChange(code)}
                                        className="w-full px-6 py-4 text-left hover:bg-gray-50 transition-colors border-b border-gray-100 last:border-0"
                                    >
                                        <div className="flex items-center gap-3">
                                            <span className="text-xl">{region.flag}</span>
                                            <div className="flex-1">
                                                <div className="font-semibold">{region.title}</div>
                                                <div className="text-sm text-gray-500">{region.description}</div>
                                            </div>
                                        </div>
                                    </button>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ComingSoon; 