import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { CheckCircle, XCircle, Loader, PartyPopper } from 'lucide-react'
import { Link } from 'react-router-dom'
import useBasketStore from '@/context/BasketStore'
import useOrderSummaryStore from '@/context/OrderSummaryStore'
import PaymentMethods from '@/components/PaymentMethods'
import { getStripeCheckoutLink, StripeCheckoutData, StripeCheckoutItem } from '@/services/api'
import Confetti from 'react-confetti'
import DynamicHeadphones from '@/components/customColours/DynamicHeadphones'
import AnimatedCheckCircle from './AnimatedCheckCircle'
import { trackPaymentFailed } from '@/utils/tracking'
import useRegionStore from '@/context/RegionStore'
import { initializeAnalytics } from '@/utils/cookieConsent'
import useCookieConsentStore from '@/context/CookieConsentStore'

const CheckoutPage: React.FC = () => {
    const location = useLocation()
    const { basketItems, removeItemFromBasket, itemCount } = useBasketStore()
    const { currentOrder, setCurrentOrder } = useOrderSummaryStore()
    const { chosenRegion } = useRegionStore()
    const { consent } = useCookieConsentStore()
    const [checkoutPrice, setCheckoutPrice] = useState<number>(0)
    const [deliveryCost, setDeliveryCost] = useState<number>(3)
    const [isProcessing, setIsProcessing] = useState<boolean>(false)
    const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight })
    const [confettiCount, setConfettiCount] = useState(1)
    const [confettiLayers, setConfettiLayers] = useState<number[]>([])
    const [isPartying, setIsPartying] = useState(false)
    const [hasTrackedError, setHasTrackedError] = useState(false)

    const isSuccess = location.pathname.includes('success')
    const isError = location.pathname.includes('error')
    const isCheckout = !isSuccess && !isError

    // Track payment failure when landing on error page
    useEffect(() => {
        if (isError && currentOrder && !hasTrackedError) {
            console.log('[Meta Pixel] Payment failed, ensuring pixel is initialized')

            // Initialize analytics if needed
            if (!window.fbq && consent.marketing) {
                console.log('[Meta Pixel] Reinitializing Meta Pixel')
                initializeAnalytics(consent)
            }

            // Wait for fbq to be available
            const trackErrorEvent = () => {
                if (window.fbq) {
                    console.log('[Meta Pixel] Tracking payment failed event')
                    trackPaymentFailed(currentOrder.items, currentOrder.subtotal + currentOrder.delivery, chosenRegion)
                    setHasTrackedError(true)
                    return true
                }
                return false
            }

            // Try immediately
            if (!trackErrorEvent()) {
                // If not successful, retry a few times
                let attempts = 0
                const maxAttempts = 5
                const interval = setInterval(() => {
                    attempts++
                    if (trackErrorEvent() || attempts >= maxAttempts) {
                        clearInterval(interval)
                        if (attempts >= maxAttempts) {
                            console.warn('[Meta Pixel] Failed to track payment error after', maxAttempts, 'attempts')
                        }
                    }
                }, 1000) // Try every second
            }
        }
    }, [isError, currentOrder, consent, hasTrackedError])

    // Update window size for confetti
    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            })
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    // Store order summary before clearing basket
    useEffect(() => {
        if (isSuccess && basketItems.length > 0) {
            basketItems.forEach(item => removeItemFromBasket(item.id))
        }
    }, [isSuccess])

    // Calculate totals
    useEffect(() => {
        let price: number = 0
        basketItems.forEach(item => {
            price += item.price
        })
        setCheckoutPrice(price)

        // Calculate delivery cost
        if (price >= 50) {
            setDeliveryCost(0)
        } else {
            setDeliveryCost(3)
        }
    }, [basketItems])

    const handleStripeCheckout = async () => {
        setIsProcessing(true)
        try {
            // Calculate totals
            const subtotal = basketItems.reduce((total, item) => total + item.price, 0)
            const delivery = subtotal >= 50 ? 0 : 3
            const total = subtotal + delivery

            // Store the order summary before proceeding to Stripe
            setCurrentOrder(basketItems, subtotal, delivery)

            // Get the base URL (protocol + host)
            const baseUrl = `${window.location.protocol}//${window.location.host}`
            // Get the country code from the URL path segments
            const pathSegments = window.location.pathname.split('/')
            const countryCode = pathSegments[1]?.toUpperCase() || 'GB'

            const checkoutItems: StripeCheckoutItem[] = basketItems.map(item => ({
                productItemId: item.colourWay._id,
                quantity: 1,
                parentProductItemId: null,
                ...(item.colourWay.components?.length && {
                    components: item.colourWay.components.map(component => ({
                        partId: component.componentVariantId,
                        side: component.position || undefined,
                        chosenMaterial: component.chosenMaterial
                    }))
                })
            }))

            const checkoutData: StripeCheckoutData = {
                countryCode,
                items: checkoutItems,
                successUrl: `${baseUrl}/${countryCode.toLowerCase()}/shop/checkout/success`,
                errorUrl: `${baseUrl}/${countryCode.toLowerCase()}/shop/checkout/error`
            }

            const checkoutLink = await getStripeCheckoutLink(checkoutData)
            if (checkoutLink) {
                window.location.assign(checkoutLink.checkoutUrl)
            }
        } catch (error) {
            console.error('Error initiating checkout:', error)
        } finally {
            setIsProcessing(false)
        }
    }

    const handlePartyClick = () => {
        if (!isPartying) {
            setIsPartying(true)
            setConfettiCount(10)
            setConfettiLayers([1, 2, 3, 4, 5, 6, 7, 8, 9, 10])

            // Reset after party duration
            setTimeout(() => {
                document.querySelector('.success-container')?.classList.add('party-fade-out')
                setTimeout(() => {
                    document.querySelector('.success-container')?.classList.remove('party-fade-out')
                    setTimeout(() => {
                        setIsPartying(false)
                        setConfettiCount(1)
                        setConfettiLayers([])
                    }, 50)
                }, 1000)
            }, 5000)
        }
    }

    if (isSuccess) {
        return (
            <div className="min-h-screen bg-gray-50 py-12 relative overflow-hidden">
                {/* Base confetti */}
                <Confetti
                    width={windowSize.width}
                    height={windowSize.height}
                    recycle={true}
                    numberOfPieces={100}
                    tweenDuration={10000}
                />

                {/* Accumulated confetti layers */}
                {confettiLayers.map((layer, index) => (
                    <Confetti
                        key={index}
                        width={windowSize.width}
                        height={windowSize.height}
                        recycle={true}
                        numberOfPieces={50 * layer}
                        tweenDuration={8000}
                        gravity={0.2}
                        initialVelocityY={15}
                        colors={['#FF4C29', '#FFD93D', '#6BCB77', '#4D96FF']}
                    />
                ))}

                {/* Disco Ball */}
                <div className={`absolute left-1/2 -translate-x-1/2 transition-all duration-1000 z-50 origin-top
                    ${isPartying ? 'top-0 animate-[swing_3s_ease-in-out_infinite]' : '-top-48'}`}>
                    <img
                        src="/assets/images/mirror-ball.webp"
                        alt="Disco Ball"
                        className={`h-48 ${isPartying ? 'animate-spin-slow' : ''}`}
                    />
                </div>

                {/* Disco Lights */}
                {isPartying && (
                    <>
                        <div className="disco-light light-1 z-40"></div>
                        <div className="disco-light light-2 z-40"></div>
                        <div className="disco-light light-3 z-40"></div>
                        <div className="disco-light light-4 z-40"></div>
                    </>
                )}

                <div className={`success-container relative z-30 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 
                    transform transition-transform duration-1000 ease-in-out
                    ${isPartying ? 'translate-y-48' : 'translate-y-0'}`}>
                    <style>
                        {`
                        @keyframes swing {
                            0% { transform: translateX(-50%) rotate(0deg); }
                            25% { transform: translateX(-50%) rotate(15deg); }
                            75% { transform: translateX(-50%) rotate(-15deg); }
                            100% { transform: translateX(-50%) rotate(0deg); }
                        }
                        .party-fade-out {
                            opacity: 0.5;
                            transform: scale(0.95);
                            transition: all 1s ease-in-out;
                        }
                        @keyframes spin-slow {
                            from { transform: rotate(0deg); }
                            to { transform: rotate(360deg); }
                        }
                        .disco-light {
                            position: absolute;
                            width: 100px;
                            height: 100px;
                            border-radius: 50%;
                            filter: blur(40px);
                            animation: disco-pulse 2s infinite;
                            opacity: 0.8;
                            mix-blend-mode: screen;
                        }
                        .light-1 {
                            top: 20%;
                            left: 20%;
                            background: #ff0000;
                            box-shadow: 0 0 100px 50px #ff0000;
                            animation-delay: 0s;
                        }
                        .light-2 {
                            top: 20%;
                            right: 20%;
                            background: #00ff00;
                            box-shadow: 0 0 100px 50px #00ff00;
                            animation-delay: 0.5s;
                        }
                        .light-3 {
                            bottom: 20%;
                            left: 20%;
                            background: #0000ff;
                            box-shadow: 0 0 100px 50px #0000ff;
                            animation-delay: 1s;
                        }
                        .light-4 {
                            bottom: 20%;
                            right: 20%;
                            background: #ffff00;
                            box-shadow: 0 0 100px 50px #ffff00;
                            animation-delay: 1.5s;
                        }
                        @keyframes disco-pulse {
                            0% { transform: scale(1); opacity: 0.8; }
                            50% { transform: scale(1.2); opacity: 1; }
                            100% { transform: scale(1); opacity: 0.8; }
                        }
                        `}
                    </style>
                    <div className="flex flex-col items-center justify-center text-center mb-12">
                        <AnimatedCheckCircle />
                        <h2 className="mt-6 text-3xl font-bold text-gray-900">Payment Successful!</h2>
                        <p className="mt-2 text-lg text-gray-600 leading-tight">
                            Thank you for your purchase.<br /> We'll send you an email with your order details shortly.
                        </p>
                        <button
                            onClick={handlePartyClick}
                            disabled={isPartying}
                            className={`flex items-center mt-9 gap-2 p-2 md:pl-4 md:pr-4 bg-orange justify-center w-min-content rounded-md font-headline md:text-lg text-white leading-none
                                transition-all ease-kibu duration-200 items-center cursor-pointer border border-2 border-transparent
                                hover:rounded-lg hover:brightness-105 hover:border-black/10 hover:scale-[1.02]
                                ${isPartying ? 'animate-bounce opacity-50 cursor-not-allowed' : ''}`}
                        >
                            <PartyPopper className="w-4 h-4" />
                            {isPartying ? "Party Time! 🎉" : "Start Party"}
                        </button>
                    </div>

                    {currentOrder && (
                        <div className="max-w-2xl mx-auto">
                            <div className="bg-white rounded-xl shadow-sm p-6 mb-8">
                                <h3 className="text-xl font-bold mb-4">Order Summary</h3>
                                <div className="space-y-4">
                                    {currentOrder.items.map((item) => (
                                        <div key={item.id} className="flex items-center gap-4 border border-black/15 p-4 rounded-lg">
                                            <div className="rounded-md overflow-hidden relative">
                                                {item.colourWay._id === "674b4a1f197065b8a54a3224" && item.colourWay.components ? (
                                                    <div className="relative w-[100px] h-[100px] overflow-hidden">
                                                        <div className="absolute inset-0">
                                                            <img
                                                                src={'/assets/images/product_images/' + item.colourWay._id + '_background.webp'}
                                                                className="w-full h-full object-cover"
                                                                alt="Background"
                                                            />
                                                        </div>
                                                        <div className="absolute inset-0 flex items-center justify-center scale-[0.8] -left-3">
                                                            <div className="w-full h-full relative">
                                                                <DynamicHeadphones
                                                                    viewName="front_view"
                                                                    engraving=""
                                                                    customColourProduct={item.colourWay}
                                                                    customComponents={item.colourWay.components}
                                                                    toggleColourPicker={() => { }}
                                                                    interactive={false}
                                                                    miniature={true}
                                                                    key={JSON.stringify(item.colourWay.components)}
                                                                    className="transform-none"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <img
                                                        src={'/assets/images/product_thumbnails/' + item.colourWay._id + '.webp'}
                                                        width={80}
                                                        className="aspect-square object-cover w-[100px] h-[100px]"
                                                        alt={item.name}
                                                    />
                                                )}
                                            </div>
                                            <div className="flex-1">
                                                <div className="font-bold">{item.name}</div>
                                                <div className="text-gray-600">{item.colourWay.optionTitle}</div>
                                                {item.extras.map((extra) => (
                                                    <div key={extra._id} className="text-sm text-gray-500">+ {extra.productTitle}</div>
                                                ))}
                                                <div className="text-blue font-headline">£{item.price}</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <div className="mt-6 border-t border-black/10">
                                    <div>
                                        <div className='py-4 flex flex-col gap-1'>
                                            <div className="flex justify-between">
                                                <span className="text-gray-600">Subtotal</span>
                                                <span>£{currentOrder.subtotal.toFixed(2)}</span>
                                            </div>
                                            <div className="flex justify-between">
                                                <span className="text-gray-600">Delivery</span>
                                                <span>{currentOrder.delivery === 0 ? 'FREE' : `£${currentOrder.delivery.toFixed(2)}`}</span>
                                            </div>
                                        </div>
                                        <div className="flex justify-between font-bold pt-4 border-t border-black/10">
                                            <span>Total</span>
                                            <span>£{currentOrder.total.toFixed(2)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex items-center gap-4">
                                <Link
                                    to="/"
                                    className={`flex items-center grow p-2 md:pl-4 md:pr-4 bg-orange justify-center w-min-content rounded-md font-headline md:text-lg text-white leading-none
                                        transition-all ease-kibu duration-200 items-center cursor-pointer border border-2 border-transparent
                                        hover:rounded-lg hover:brightness-105 hover:border-black/10 hover:scale-[1.02]`}
                                >
                                    Return to Home
                                </Link>
                                <Link
                                    to="/shop"
                                    className={`flex items-center p-2 md:pl-4 md:pr-4 bg-transparent justify-center w-min-content rounded-md font-headline md:text-lg text-orange leading-none
                                        transition-all ease-kibu duration-200 items-center cursor-pointer border border-2 border-orange
                                        hover:rounded-lg hover:brightness-105 hover:border-orange hover:bg-orange/5 hover:text-orange hover:scale-[1.02]`}
                                >
                                    Continue Shopping
                                </Link>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    return (
        <div className="min-h-screen bg-gray-50 py-12">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                {isError && (
                    <div className="md:hidden mb-8">
                        <div className="text-center p-6 rounded-xl border-2 border-red-200 bg-red-50">
                            <XCircle className="mx-auto h-16 w-16 text-red-500" />
                            <h2 className="mt-6 text-2xl font-bold text-gray-900">Payment Failed</h2>
                            <p className="mt-4 text-gray-600">
                                We couldn't process your payment. Please try again or contact support if the problem persists.
                            </p>
                            <div className="mt-6">
                                <a
                                    href="mailto:support@kibu.com"
                                    className="text-orange hover:text-orange/80 transition-colors"
                                >
                                    Contact Support
                                </a>
                            </div>
                        </div>
                    </div>
                )}

                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    {/* Left Column - Order Summary */}
                    <div className="p-6">
                        <h2 className="text-2xl font-bold mb-6">Order Summary</h2>
                        <div className="space-y-4">
                            {basketItems.map((item) => (
                                <div key={item.id} className="flex items-center gap-4 border border-black/15 p-4 rounded-lg bg-white shadow-md">
                                    <div className="rounded-md overflow-hidden relative">
                                        {item.colourWay._id === "674b4a1f197065b8a54a3224" && item.colourWay.components ? (
                                            <div className="relative w-[100px] h-[100px] overflow-hidden">
                                                <div className="absolute inset-0">
                                                    <img
                                                        src={'/assets/images/product_images/' + item.colourWay._id + '_background.webp'}
                                                        className="w-full h-full object-cover"
                                                        alt="Background"
                                                    />
                                                </div>
                                                <div className="absolute inset-0 flex items-center justify-center scale-[0.8]">
                                                    <div className="w-full h-full relative">
                                                        <DynamicHeadphones
                                                            viewName="front_view"
                                                            engraving=""
                                                            customColourProduct={item.colourWay}
                                                            customComponents={item.colourWay.components}
                                                            toggleColourPicker={() => { }}
                                                            interactive={false}
                                                            miniature={true}
                                                            key={JSON.stringify(item.colourWay.components)}
                                                            className="transform-none"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <img
                                                src={'/assets/images/product_thumbnails/' + item.colourWay._id + '.webp'}
                                                width={80}
                                                className="aspect-square object-cover w-[100px] h-[100px]"
                                                alt={item.name}
                                            />
                                        )}
                                    </div>
                                    <div className="flex-1">
                                        <div className="font-bold">{item.name}</div>
                                        <div className="text-gray-600">{item.colourWay.optionTitle}</div>
                                        {item.extras.map((extra) => (
                                            <div key={extra._id} className="text-sm text-gray-500">+ {extra.productTitle}</div>
                                        ))}
                                        <div className="text-blue font-headline">£{item.price}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Right Column - Checkout/Status */}
                    <div className="p-6">
                        <div className="space-y-6">
                            {isError && (
                                <div className="hidden md:block text-center p-6 rounded-xl mb-6 border-2 border-red-200 bg-red-50">
                                    <XCircle className="mx-auto h-16 w-16 text-red-500" />
                                    <h2 className="mt-6 text-2xl font-bold text-gray-900">Payment Failed</h2>
                                    <p className="mt-4 text-gray-600">
                                        We couldn't process your payment. Please try again or contact support if the problem persists.
                                    </p>
                                    <div className="mt-6">
                                        <a
                                            href="mailto:support@kibu.com"
                                            className="text-orange hover:text-orange/80 transition-colors"
                                        >
                                            Contact Support
                                        </a>
                                    </div>
                                </div>
                            )}

                            <div>
                                <h2 className="text-2xl font-bold mb-6">Checkout</h2>
                                {/* Cost Summary */}
                                <div className="space-y-2 mb-8">
                                    <div className="flex justify-between">
                                        <span className="text-gray-600">Subtotal</span>
                                        <span>£{checkoutPrice.toFixed(2)}</span>
                                    </div>
                                    <div className="flex justify-between">
                                        <span className="text-gray-600">Delivery</span>
                                        <span>{deliveryCost === 0 ? 'FREE' : `£${deliveryCost.toFixed(2)}`}</span>
                                    </div>
                                    <div className="flex justify-between font-bold pt-2 border-t border-black/10">
                                        <span>Total</span>
                                        <span>£{(checkoutPrice + deliveryCost).toFixed(2)}</span>
                                    </div>
                                </div>

                                <button
                                    className={`w-full px-4 py-3 font-medium text-white bg-orange rounded-xl hover:bg-orange/90 transition-colors mb-4 flex items-center justify-center gap-2
                                        ${isProcessing && 'opacity-70 cursor-not-allowed'}`}
                                    onClick={handleStripeCheckout}
                                    disabled={isProcessing}
                                >
                                    {isProcessing ? (
                                        <>
                                            <Loader className="h-5 w-5 animate-spin" />
                                            Processing...
                                        </>
                                    ) : (
                                        'Proceed to Payment'
                                    )}
                                </button>

                                <div className="flex justify-center">
                                    <PaymentMethods />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CheckoutPage 